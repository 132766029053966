import React, { useState,useEffect } from "react";
import {useDropzone} from 'react-dropzone';
import imageCompression from "browser-image-compression";
import CircularProgress from '@mui/material/CircularProgress';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import UploadFileIcon from '@mui/icons-material/Image';
import ImagePager from './ImagePager';
import {useStyles} from './useStyles';
import {ImageData} from './Inc';

interface ImageUploadProps {
	title: string,
	message?: string,
	error?:boolean,
	images: ImageData[];
	maxFiles: number,
	maxSize?: number,
	maxWidth?: number,
	cancel(): void,
	setImages: (results:ImageData[]) => void;
	componentRef?: (instance: HTMLInputElement | null) => void;
}

const ImageSelect: React.FC<ImageUploadProps> =  (props)=> {
	const classes = useStyles();
	const defaultStates = {
		isLoading: false,
		isYesNo: false,
		isTest: false,
		deleted: false,
		error: "",
		currentIndex: 0,
	};
	const [states,setStates] = useState(defaultStates);

	const file2string = async (file:File,func:any)=>{
		//console.log(file);

		const reader = new FileReader()
		let maxSize = 3;
		let maxWidth = 800;

		if( props.maxSize ){
			maxSize = props.maxSize;
		}
		if( props.maxWidth ){
			maxWidth = props.maxWidth;
		}

		let size = 0;
		let res = {} as ImageData;
		reader.onabort = () => {
			console.log('file reading was aborted');
			res.result = false;
			return res;
		}
		reader.onerror = () => {
			console.log('file reading has failed');
			res.result = false;
			return res;
		}
		reader.onload = () => {
			//console.log("d");
			const base64Str = reader.result
			if( base64Str ){
				//setStates({...states,isFileTypeError:false,isLoading:false});
				//props.setImage(base64Str.toString(),size);				
				//console.log("e");
				res.name = "";
				res.source = base64Str.toString();
				res.size = size;
				res.result = true;
				func(res);
			}
		}
		const compressOptions = {
			maxSizeMB: maxSize,
			maxWidthOrHeight: maxWidth,
			fileType: "image/jpeg",
		};
		//console.log("b");
		const compressedFile = await imageCompression(file, compressOptions);
		//console.log("c1");
		size = compressedFile.size;
		reader.readAsDataURL(compressedFile);
		//reader.readAsDataURL(file);
	}

	const removeImage = (index:number)=>{
		//resetErrors();
		setStates({...states,error:'',isYesNo:false,deleted:true});

		let images = [...props.images];
		images.splice(index,1);
		//images[index] = {
		//	name: images[index].name,
		//	source:"deleted",
		//	size:0,
		//	result:true,
		//};
		//console.log(images);
		props.setImages(images);
	}

	const orderImage = (fromIndex:number,toIndex:number) => {
		let images = [...props.images];
		const image = images.splice(fromIndex,1);
		images.splice(toIndex,0,image[0]);

		props.setImages(images);
	}

	const accepted = async ()=>{
		console.log("accepted");
	}
	const rejected = ()=>{
		console.log("rejected");
	}
    const onDrop = async (acceptedFiles:File[]) => {
		//console.log("onDrop");
        //console.log('acceptedFiles:', acceptedFiles);
		if(acceptedFiles.length===0){
			console.log("error0");
			setStates({...states,isLoading:false,error:'nofile'});
			return;
		}
		
		setStates({...states,isLoading:true,error:''});
		let error = "";
		let images = [...props.images];

		//let numImages = 0;
		//for( let i=0; i<images.length; i++ ){
		//	if( images[i].source!=="deleted" ){
		//		numImages++;
		//	}
		//}
		let numImages = images.length;

		for( let i=0; i<acceptedFiles.length; i++ ){
			if( numImages>=props.maxFiles ){
				error = "maxfiles";
			} else if( acceptedFiles[i] ){
				if (
					[
						"image/gif",
						"image/jpeg",
						"image/png",
					].includes(acceptedFiles[i].type)
				) {
					numImages++;
					await file2string(acceptedFiles[i],(result:ImageData)=>{
						images.push(result);
						props.setImages(images);
					});
				} else {
					error = "wrongtype";
				}
			}
		}
		if( error!=='' ){
			console.log("error:"+error);
		}
		setStates({...states,isLoading:false,error:error});
    };

	useEffect(()=>{
		setStates(defaultStates);
	},[])



	let cl = classes.formDropzone;
	if( props.error ){
		cl = cl + " " + classes.formDropzoneError;
	}

	//let numImages = 0;
	//for( let i=0; i<props.images.length; i++ ){
	//	if( props.images[i].source!=="deleted" ){
	//		numImages ++;
	//	}
	//}
	let multiple = true;
	if( props.maxFiles===1 ){
		multiple = false;
	}
	const { getRootProps, getInputProps, open } = useDropzone({noClick:true,onDrop:onDrop,maxFiles:props.maxFiles,multiple:multiple,accept:["image/png","image/jpeg","image/gif"],onDropAccepted:accepted,onDropRejected:rejected});

	if( states.isLoading ){
		return <Box className={cl} style={{padding:"4em 0",textAlign:"center"}}><CircularProgress /></Box>
	}

	const errorMessage:{[key:string]:string;} = {
		'nofile':'アップロードできるファイルがありません',
		'wrongtype':'PNG / JPEG / GIF 以外のファイル形式は利用できません',
		'maxfiles':'ファイルは'+props.maxFiles+'個までです',
	};

  	return (
		<React.Fragment>
			<input {...getInputProps()}/>
			{props.images.length>0 ? (
				<div {...getRootProps()} style={{position:'relative'}}>
					<ImagePager images={props.images} remove={removeImage} order={orderImage} disablePager={props.maxFiles===1}/>
					{props.images.length<props.maxFiles &&
					<div style={{position:'absolute',bottom:'0.5em',padding:'0'}}>
						<IconButton onClick={open}><UploadFileIcon style={{fontSize:'120%',color:'#000000',backgroundColor:'#FFFFFF',opacity:0.8}}/></IconButton>
					</div>
					}
				</div>
			):(
			<div {...getRootProps()} className={cl}>
				<FormLabel className={classes.formLabel+' '+classes.formInner}>{props.title}</FormLabel>
				<p style={{textAlign:'center',padding:'1em 0 0 0'}}>
					<IconButton onClick={open}><UploadFileIcon style={{fontSize:'280%',color:"#999999"}}/></IconButton>
				</p>
				{props.message && props.message!=="" &&
				<p style={{textAlign:'center',fontSize:'80%',padding:'0em 1em 1.5em 1em',color:'#666666'}}>
					{props.message}
				</p>
				}
			</div>

			)}
			{states.error!=='' && (
				<p style={{color:"#FF0000"}}>※{errorMessage[states.error]}</p>
			)}
		</React.Fragment>
	);
};

export default ImageSelect;
