import React,{useState, useEffect} from 'react';
import {Navigate} from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import WarningIcon from '@mui/icons-material/Warning';
import {UserContainer} from './UserContainer';

const MailNew = ()=> {
    const [isSuccess,setIsSuccess] = useState(false);
    const [isFailure,setIsFailure] = useState(false);
    const userState = UserContainer.useContainer();

    useEffect( ()=>{
		if(!userState.ready){
			userState.signIn("email",()=>{
				setIsSuccess(true);
			},()=>{
				setIsFailure(true);
			});
		}
    },[userState]);

    if( !isSuccess && !isFailure ){
        return (
            <div style={{textAlign:'center',marginTop:'4em'}}>
				<p><CircularProgress/></p>
				<p>
					ログイン中<br/>
					このままお待ちください
				</p>
			</div>
        )
    }

	if( isFailure ){
        return (
            <div style={{textAlign:'center',marginTop:'4em'}}>
				<p><WarningIcon style={{fontSize:'200%'}} color="warning"/></p>
				<p>
					ログインできませんでした
				</p>
			</div>
        )
    }

    return (
        <Navigate to="/"/>
    )
}

export default MailNew;
