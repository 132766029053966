import React,{useState,useEffect} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import {UserContainer} from './UserContainer';
import Ajax from './Ajax';
import {useStyles} from './useStyles';

interface ProductReleaseProps {
	productId:string,
	close(available:boolean):void,
	isOpen:boolean,
	isFirst:boolean,
	available:boolean,
}

const ProductRelease:React.FC<ProductReleaseProps> = (props)=> {
	const userState = UserContainer.useContainer();
	const [status,setStatus] = useState(0);
	const classes = useStyles();
	const close = ()=>{
		if( status===2 ){
			props.close(!props.available);
		} else {
			props.close(props.available);
		}
	}
	const release = async ()=>{
		if(!userState.user){
			return;
		}
		//console.log(props.productId);
		setStatus(1);

		const ajax = new Ajax();
		const res = await ajax.releaseProduct(userState.user.uid,props.productId,!props.available);
		if( res.err ){
			setStatus(9);
			return;
		}
		if( res.result!=="OK" ){
			setStatus(9);
			return;
		}
		setStatus(2);
	}
	useEffect(()=>{
		if(props.isOpen){
			setStatus(0);
		}
	},[props.isOpen]);

	if( !props.isOpen ){
		return null;
	}
	
	let mesQuestion = "今すぐ販売しますか？";
	let mesResult = "販売開始しました";
	let btnYes = "すぐ販売する";
	let btnNo = "まだ販売しない";
	if(!props.isFirst){
		if(props.available){
			mesQuestion = "準備中にしてよろしいですか？";
			mesResult = "準備中にしました";
			btnYes = "準備中にする";
			btnNo = "販売を続ける";
		} else {
			mesQuestion = "販売を開始しますか？";
			mesResult = "販売開始しました";
			btnYes = "販売開始する";
			btnNo = "準備中のままでいい";
		}
	}


	return (
		<Dialog
			open={props.isOpen}
			onClose={(event, reason) => {
				if (reason !== 'backdropClick') {
					close();
				}
			}}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			disableEscapeKeyDown={true}
		>
			<DialogTitle id="alert-dialog-title">販売状況</DialogTitle>
			<DialogContent>
				{status!==2 ? (
				<DialogContentText id="alert-dialog-description">
					{mesQuestion}
				</DialogContentText>
				):(
				<DialogContentText id="alert-dialog-description">
					{mesResult}
				</DialogContentText>
				)}
			</DialogContent>
			{status===0 &&
			<DialogActions>
				<Button onClick={close} color="primary" variant="text">
				{btnNo}
				</Button>
				<Button onClick={release} color="primary" variant="contained">
				{btnYes}
				</Button>
			</DialogActions>
			}
			{status===1 &&
			<Box className={classes.dialogActionsCenter}>
				<CircularProgress/>
			</Box>
			}
			{status===2 &&
			<DialogActions className={classes.dialogActions}>
				<Button onClick={close} color="success" variant="outlined">閉じる</Button>
			</DialogActions>
			}
			{status===9 &&
			<DialogActions className={classes.dialogActions}>
				<div style={{fontSize:"80%",color:"#666666",marginRight:"1em"}}>失敗しました</div>
				<Button onClick={close} color="primary" variant="outlined">やめる</Button>
				<Button onClick={release} color="warning" variant="contained">リトライ</Button>
			</DialogActions>
			}
		</Dialog>
	);
}

export default ProductRelease;