import React,{useEffect,useState} from 'react';
import Box from '@mui/material/Box';
//import {Link} from 'react-router-dom'
//import {DataGrid, GridRowsProp, GridColDef, GridLocaleText} from '@mui/x-data-grid';
//import {useTheme} from '@mui/material/styles';
import Grid from '@mui/material/Grid';
//import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
//import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
//import useMediaQuery from '@mui/material/useMediaQuery';
import CircularProgress from '@mui/material/CircularProgress';
//import IconButton from '@mui/material/IconButton';
//import ForwardIcon from '@mui/icons-material/Forward';
//import ClearIcon from '@mui/icons-material/Clear';
//import SearchIcon from '@mui/icons-material/Search';
//import {GRID_DATA_TEXT} from './GridDataText';
import YesNo from './YesNo';
import PageTitle from './PageTitle';
import Ajax from './Ajax';
//import {Profile,Payment} from './Inc';
//import Tools from './Tools';
import {UserContainer} from './UserContainer';

interface ProfileRemoveProps {
    
}

const ProfileRemove:React.FC<ProfileRemoveProps> = (props)=> {
	const userState = UserContainer.useContainer();
	const defaultStates = {
		//profile: {} as Profile,
		//payment: {} as Payment,
		items: 0,
		deposit: 0,
		charge: 0,
		isLoading: true,
		isConfirm: false,
		isEnd: false,
		checked: false,
	};
	const [states,setStates] = useState(defaultStates);

	const load = async ()=>{
		if(!userState.user){
			return;
		}

		const ajax = new Ajax();
		const res = await ajax.getFullProfile(userState.user.uid);
		//console.log(res);
		if( !res.err ){
			//setStates({...states,profile:res.profile,payment:res.payment,items:res.items,deposit:res.deposit,charge:res.charge,isLoading:false});
			setStates({...states,items:res.items,deposit:res.deposit,charge:res.charge,isLoading:false});
		} else {
			setStates({...states,isLoading:false});
		}
	}
	const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setStates({...states,checked:event.target.checked});
	};
	const openConfirm = ()=>{
		setStates({...states,isConfirm:true});
	}
	const closeConfirm = ()=>{
		setStates({...states,isConfirm:false});
	}
	const bye = async ()=>{
		if(!userState.user){
			return;
		}
		setStates({...states,isConfirm:false,isLoading:true});
		const ajax = new Ajax();
		const res = await ajax.removeProfile(userState.user.uid);
		if( !res.err ){
			setStates({...states,isConfirm:false,isLoading:false,isEnd:true});
			userState.signOut();
		}
	}

	useEffect(()=>{
		if( !states.isEnd ){
			if( userState.user ){
				setStates(defaultStates);
				load();
			} else {
				setStates({...states,isLoading:false});
			}
		}
	},[userState.user]);

	return (
		<React.Fragment>
			<PageTitle title="削除と終了" icon="delete"/>
			{states.isEnd ? (
			<Box style={{width: '100%'}}>
				ご利用ありがとうございました！<br/>
				削除・終了処理が完了しました。<br/>
			</Box>
			):(
			<Box style={{width: '100%'}}>
				{states.isLoading ? (
				<CircularProgress/>
				):(
				<Box style={{marginBottom:'1em'}}>
					登録内容を削除してご利用を終わりにしたい場合は注意事項をご確認の上、「退会する」をタップして下さい。<br/>
					<Box style={{paddingBottom:'1em',marginTop:'2em'}}>
						<Box style={{border:'1px solid silver',padding:'1em',paddingRight:'3em'}}>
						<h4 style={{margin:'0',textAlign:'center'}}>「削除と終了」手続きの注意事項</h4>
						<ul style={{marginBottom:'1em'}}>
							<li style={{marginBottom:'1em'}}><b>入金日未定の売上</b>および<b>今月入金予定の売上</b>が残っている状態でこの手続きを行うと、売上の受取を放棄したものと見なされます。差し支えなければすべての売上の入金が完了するまでお待ちください。</li>
							<li>過去14日以内に商品を購入したお客様がいらっしゃる場合、お客様のダウンロード有効期間（14日）が過ぎるまで商品ページおよびファイルのデータは削除されません。（販売は休止状態となります）</li>
						</ul>
						</Box>
						<Grid container spacing={1}>
							<Grid item xs={12} sm={6} md={4}>
								<Box style={{padding:'1em',textAlign:'center',paddingBottom:'3em'}}>
									<h5>入金日未定の売上</h5>
									{states.charge>0 ? (
									<div>
										あり<br/>
										<Box style={{padding:'1em',marginTop:'1em',fontSize:'90%',textAlign:'left',backgroundColor:'#FFEEEE'}}>
											入金前に退会した場合、この売上の受取は放棄したものとみなされます
										</Box>
									</div>
									):(
									<div>
										なし<br/>
									</div>
									)}
								</Box>
							</Grid>
							<Grid item xs={12} sm={6} md={4}>
								<Box style={{padding:'1em',textAlign:'center',paddingBottom:'3em'}}>
									<h5>今月入金予定の売上</h5>
									{states.deposit>0 ? (
									<div>
										あり<br/>
										<Box style={{padding:'1em',marginTop:'1em',fontSize:'90%',textAlign:'left',backgroundColor:'#FFEEEE'}}>
											入金前に退会した場合、この売上の受取は放棄したものとみなされます
										</Box>
									</div>
									):(
									<div>
										なし<br/>
									</div>
									)}
								</Box>
							</Grid>
							<Grid item xs={12} sm={6} md={4}>
									<Box style={{padding:'1em',textAlign:'center',paddingBottom:'3em'}}>
										<h5>販売中の商品</h5>
										{states.deposit>0 ? (
										<div>
											あり<br/>
											<Box style={{padding:'1em',marginTop:'1em',fontSize:'90%',textAlign:'left',backgroundColor:'#FFEEEE'}}>
												退会と同時に商品の販売は終了します
											</Box>
										</div>
										):(
										<div>
											なし<br/>
										</div>
										)}
									</Box>
							</Grid>
						</Grid>
					</Box>
					<Divider/>
					{userState.hasProfile() ? (
					<React.Fragment>
					<Box style={{marginTop:'2em',textAlign:'center'}}>
						<FormControlLabel control={<Checkbox checked={states.checked} onChange={onChange}/>} label="注意事項に同意します" />
					</Box>
					<Box style={{marginTop:'1em',textAlign:'center',marginBottom:'6em'}}>
						<Button variant="outlined" color="warning" disabled={!states.checked} onClick={openConfirm}>退会する</Button>
					</Box>
					</React.Fragment>
					):(
					<Box style={{marginTop:'1em',textAlign:'center',marginBottom:'6em'}}>
						削除できる登録内容がありません
					</Box>
					)}
				</Box>
				)}
			</Box>
			)}
			<YesNo onYes={bye} onNo={closeConfirm} title="退会します" text="本当に退会してよろしければ「はい」をタップして下さい。" isOpen={states.isConfirm}/>
		</React.Fragment>
	)
}

export default ProfileRemove;