import React,{useState} from 'react';
import {BrowserRouter} from 'react-router-dom'//yarn add @types/react-router-dom
import {ThemeProvider, createTheme} from '@mui/material/styles';
import {blueGrey, brown, grey, teal, red, deepOrange} from '@mui/material/colors';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import {UserContainer} from './UserContainer';
import Header from './Header';
import Footer from './Footer';
import DrawerHeader from './DrawerHeader';
import Router from './Router';
import {useStyles} from './useStyles';

const App: React.FC = () => {
	const basename = process.env.REACT_APP_BASENAME;
	const classes = useStyles();
	const [mode, setMode] = useState<'dark'|'light'>('light');
	const theme = createTheme({
		palette: {
			mode,
			...(mode === 'light'
			? {
				primary: teal,
				secondary: blueGrey,
				error: red,
				warning: deepOrange,
				info: blueGrey,
				success: teal,
				//divider: grey[200],
				//border: grey[900],//?
				background: {
					//default: grey[50],
					//paper: grey[50],
					default: '#fff',
					paper: '#fff',
				},
				text: {
					primary: grey[900],
					secondary: grey[800],
				},
			}
			: {
				primary: brown,
				secondary: blueGrey,
				error: blueGrey,
				warning: blueGrey,
				info: blueGrey,
				success: blueGrey,
				divider: brown[700],
				background: {
					default: brown[900],
					paper: brown[900],
				},
				text: {
					primary: '#fff',
					secondary: brown[500],
				},
			}),
		},
	})
	
	return (
		<BrowserRouter basename={basename}>
			<UserContainer.Provider>
				<ThemeProvider theme={theme}>
				<CssBaseline/>
				<Header/>		
				<Container maxWidth="md">
					<Box component="main" className={classes.main}>
						<DrawerHeader/>
						<Router/>
					</Box>
				</Container>
				<Footer/>
				</ThemeProvider>
			</UserContainer.Provider>
		</BrowserRouter>
	);
}

export default App;
