import { useEffect,useState } from 'react';
import { useNavigate,useLocation } from 'react-router-dom';
import useLocationChange from './useLocationChange';

declare global {
  interface Window {
    gtag?: (key: string, measurementId: string, config: { page_path: string }) => void;
  }
}

export const useTracking = () => {
	//const navigate = useNavigate();
	//let location = useLocation();
	const [init,setInit] = useState(false);

	const initializeGA = (measurementId: string)=> {
		// load gtag.js:  https://developers.google.com/analytics/devguides/collection/gtagjs
		let script1 = document.createElement('script');
		script1.src = 'https://www.googletagmanager.com/gtag/js?id=' + measurementId;
		script1.async = true;
		document.body.appendChild(script1);
	
		let script2 = document.createElement('script');
		// プレーンテキスト、グローバルな window.gtag 関数を定義するため
		script2.text = `window.dataLayer = window.dataLayer || [];
		function gtag(){dataLayer.push(arguments);}
		gtag('js', new Date());
		gtag('config', '`+measurementId+`');`;
		//console.log(':'+measurementId);
		document.body.appendChild(script2);
	}

	useLocationChange((location) => {
		if(!process.env.REACT_APP_GA_MEASUREMENT_ID){
			console.log("NO GA_MEASUREMENT_ID");
			return;
		}
		const measurementId = process.env.REACT_APP_GA_MEASUREMENT_ID;
		if(!init){
			initializeGA(measurementId);
			setInit(true);
			return;
		}
		if (!window.gtag) return;
		window.gtag('config', measurementId, { page_path: location.pathname });		
	})
};