import React,{useState,useEffect} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import TwitterIcon from '@mui/icons-material/Twitter';
import GoogleIcon from '@mui/icons-material/Google';
import MailIcon from '@mui/icons-material/Mail';
import CancelIcon from '@mui/icons-material/Cancel';
//import AccountCircleIcon from '@mui/icons-material/AccountCircle';
//import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import KeyIcon from '@mui/icons-material/Key';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import PasswordIcon from '@mui/icons-material/Password';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import Loading from './Loading';
import Toast from './Toast';
import {useStyles} from './useStyles';
import {UserContainer} from './UserContainer';

interface PasswordUpdateProps {
	isOpen:boolean,
	close():void,
	done():void,
}

const PasswordUpdate = (props:PasswordUpdateProps)=> {
    const [states,setStates] = useState({
        email:"",
		oldPassword:"",
		newPassword:"",
        isSend:false,
        isLoading:false,
		created:false,
		withPassword:false,
    });
	const classes = useStyles();
	const [showNewPassword, setShowNewPassword] = useState(false);
	const [showOldPassword, setShowOldPassword] = useState(false);
	
    const [toast,setToast] = useState("");
    const userState = UserContainer.useContainer();

    const updateUser = ()=>{
		if(!userState.user){
			return;
		}
        setStates({...states,isLoading:true});
        userState.updatePassword( states.email,states.oldPassword,states.newPassword,()=>{
			setStates({...states,isLoading:false,created:true});
        },()=>{
			setStates({...states,isLoading:false});
            setToast("変更できませんでした");
		});		
    }


    const validatePassword = ()=>{
        let error = "";
		if( states.withPassword ){
			if( states.newPassword==="" ){
				error = "新しいパスワードを入力してください";
			}
		}
		if( states.oldPassword==="" ){
			error = "現在のパスワードを入力してください";
		}
		if( states.email==="" ){
			error = "メールアドレスを入力してください";
        } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(states.email)){
            error = "メールアドレスが正しくありません";
		}
		if( error!=="" ){
            setToast(error);
        } else {
            updateUser();
        }
    }

    const changeNewPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStates({...states,"newPassword":event.target.value});
    }
    const changeOldPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStates({...states,"oldPassword":event.target.value});
    }

    const changeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStates({...states,"email":event.target.value});
    }
    const closeToast = ()=>{
        setToast("");
    }
	const close = ()=>{
		closeToast();
		props.close();
	}
	function toggleShowNewPassword() {
		setShowNewPassword(!(showNewPassword))
	}
	function toggleShowOldPassword() {
		setShowOldPassword(!(showOldPassword))
	}
	function toggleWithPassword() {
		setStates({...states,withPassword:!states.withPassword});
	}
  	function handleMouseDownPassword(event: React.MouseEvent<HTMLButtonElement>) {
		event.preventDefault();
	};		

	useEffect( ()=>{
		if(!userState.ready){
			return;
		}
		if( !userState.user || !userState.user.uid || !userState.user.email ){
			props.close()
			return;
		}
		if( props.isOpen ){
			setStates({email:userState.user.email,newPassword:"",oldPassword:"",isSend:false,isLoading:false,created:false,withPassword:false});
		}
	},[props.isOpen,userState.ready]);

	if( !userState.ready ){
        return (null);
    }

    return (
        <Dialog
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={props.isOpen}
            onClose={close}
        >
            {!states.isSend ? (
				<React.Fragment>
                <DialogTitle>メールとパスワードの変更</DialogTitle>
                <DialogContent>
				{states.created ? (
					<>
					<DialogContentText style={{marginBottom:'1em'}}>
						メールとパスワードを変更しました。<br/>
						次回からは新しいメールとパスワードでログインしてください。<br/>
					</DialogContentText>
					<DialogActions>
						<Button color="primary" onClick={close}>閉じる</Button>
					</DialogActions>
					</>
					):(
					<>
					{states.withPassword ? (
					<DialogContentText style={{marginBottom:'1em'}}>
						新しいメールアドレスと現在のパスワードと新しいパスワードを入力してください
					</DialogContentText>
					):(
					<DialogContentText style={{marginBottom:'1em'}}>
						新しいメールアドレスと現在のパスワードを入力してください
					</DialogContentText>
					)}
					<DialogContentText style={{padding:'1em',marginBottom:'1em',fontSize:'90%',backgroundColor:'#F5F5F5'}}>
						・変更内容に関わらず現在のパスワードの入力が必要です<br/>
						・メールアドレスを変更する必要がない場合は、メールアドレス欄はそのまま（現在のメールアドレスのまま）で大丈夫です<br/>
					</DialogContentText>
					<FormControl fullWidth={true}>
						<TextField
							id="uniqueid"
							value={states.email}
							onChange={changeEmail}
							margin="dense"
							size="small"
							placeholder="メールアドレス"
							label="メールアドレス"
							//variant="outlined"
							autoComplete="false"
							style={{marginTop:'0',marginBottom:'0.7em'}}
						/>
					</FormControl>
					<FormControl fullWidth={true}>
						<TextField
							id="oldPassword"
							value={states.oldPassword}
							onChange={changeOldPassword}
							margin="dense"
							size="small"
							placeholder="現在のパスワード（6文字以上）"
							label="現在のパスワード"
							style={{marginTop:'0',marginBottom:'0.25em'}}
							type={showOldPassword ? 'text' : 'password'}
							autoComplete="false"
							InputProps={{
								endAdornment:
								<InputAdornment position="end">
									<IconButton
									aria-label="toggle password visibility"
									onClick={toggleShowOldPassword}
									onMouseDown={handleMouseDownPassword}
									edge="end"
									>
									{showOldPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							}}
						/>
					</FormControl>

					<FormControl>
					{states.withPassword ? (
					<Button variant="text" color="primary" startIcon={<PasswordIcon/>} onClick={toggleWithPassword}>パスワードを変更しない</Button>
					):(
					<Button variant="text" color="warning" startIcon={<PasswordIcon/>} onClick={toggleWithPassword}>パスワードを変更する</Button>
					)}
					</FormControl>

					{states.withPassword &&
					<FormControl fullWidth={true}>
						<TextField
							id="newPassword"
							value={states.newPassword}
							onChange={changeNewPassword}
							margin="dense"
							size="small"
							placeholder="新しいパスワード（6文字以上）"
							label="新しいパスワード"
							style={{marginTop:'0'}}
							type={showNewPassword ? 'text' : 'password'}
							autoComplete="false"
							InputProps={{
								endAdornment:
								<InputAdornment position="end">
									<IconButton
									aria-label="toggle password visibility"
									onClick={toggleShowNewPassword}
									onMouseDown={handleMouseDownPassword}
									edge="end"
									>
									{showNewPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							}}
						/>
					</FormControl>
					}
					<FormControl fullWidth={true} style={{marginTop:'1em',marginBottom:"0.5em"}}>
						<Button variant="contained" onClick={validatePassword}>
							<KeyIcon className={classes.pageTitleIcon}/> この内容に変更
						</Button>
					</FormControl>
					</>
					)}
					<DialogActions style={{position:"absolute",top:"0",right:"0"}}>
						<Tooltip title="閉じる"><IconButton size="small" color="default" onClick={props.close}><CancelIcon/></IconButton></Tooltip>
					</DialogActions>
				</DialogContent>
				</React.Fragment>
                
            ):(
				<React.Fragment>
					<DialogContent>
						<DialogContentText>
							{states.email}にメールを送信いたしましたのでご確認ください。<br/>
							メールに書かれたURLは1回限り有効です。<br/>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button variant="outlined" onClick={close}>閉じる</Button>
					</DialogActions>
				</React.Fragment>
            )}

            <Toast mes={toast} close={closeToast}/>
            <Loading isOpen={states.isLoading}/>
        </Dialog>
    );
}

export default PasswordUpdate;
