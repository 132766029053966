import { Stream } from "stream";

export default class Tools {
	static FileSize = (byte:number)=>{
		if( byte<1024 ){
			return String(byte) + "byte";
		} else if( byte < 1024*1024 ){
			return String(Math.floor(byte/1024*10)/10) + "KB";
		} else if( byte < 1024*1024*1024 ){
			return String(Math.floor(byte/1024/1024*10)/10) + "MB";
		} else {
			return String(Math.floor(byte/1024/1024/1024*10)/10) + "GB";
		}
	}
	static toDate = (str:string)=>{
		if( !str || str==='' ){
			return '　';
		}
		var regex = new RegExp( '^[0-9]{8}$' );
		if( str.match( regex ) ) {
			return str.substring(0,4)+"/"+str.substring(4,6)+"/"+str.substring(6,8);
		} else {
			const dt = new Date(str);
			return dt.toLocaleString();
		}
	}
	static toMonth = (str:string)=>{
		if( !str || str==='' ){
			return '　';
		}
		return str.substring(0,4)+"/"+str.substring(4,6);
	}
	static toByte = (str:string)=>{
		return (encodeURIComponent(str).replace(/%../g,"x").length);
	}
	static zeroPadding = (num:string,length:number)=>{
		return ('0000000000' + num).slice(-length);
	}
	static comma = (num:number) =>{
		return String(num).replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
	}
	static validateMail(mail:string) {
		var mail_regex1 = new RegExp( '(?:[-!#-\'*+/-9=?A-Z^-~]+\.?(?:\.[-!#-\'*+/-9=?A-Z^-~]+)*|"(?:[!#-\[\]-~]|\\\\[\x09 -~])*")@[-!#-\'*+/-9=?A-Z^-~]+(?:\.[-!#-\'*+/-9=?A-Z^-~]+)*' );
		var mail_regex2 = new RegExp( '^[^\@]+\@[^\@]+$' );
		if( mail.match( mail_regex1 ) && mail.match( mail_regex2 ) ) {
			// 全角チェック
			if( mail.match( /[^a-zA-Z0-9\!\"\#\$\%\&\'\(\)\=\~\|\-\^\\\@\[\;\:\]\,\.\/\\\<\>\?\_\`\{\+\*\} ]/ ) ) { return false; }
			// 末尾TLDチェック（〜.co,jpなどの末尾ミスチェック用）
			if( !mail.match( /\.[a-z]+$/ ) ) { return false; }
			return true;
		} else {
			return false;
		}
	}
	static validateUrl(url:string) {
		var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
			'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
			'((\\d{1,3}\\.){3}\\d{1,3}))'+ // ip (v4) address
			'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ //port
			'(\\?[;&amp;a-z\\d%_.~+=-]*)?'+ // query string
			'(\\#[-a-z\\d_]*)?$','i');
		return pattern.test(url);
	}
	static validateTel(tel:string) {
		var regex = new RegExp( '^0[0-9]+\-[0-9]+\-[0-9]+$' );
		if( tel.match( regex ) ) {
			return true;
		} else {
			return false;
		}
	}
	static validateZip(zip:string) {
		var regex = new RegExp( '^[0-9]{3}\-[0-9]{4}$' );
		if( zip.match( regex ) ) {
			return true;
		} else {
			return false;
		}
	}
	static validateTwitterId = (myId:string)=> {
		var pattern = new RegExp('^[0-9A-Za-z_]{4,15}$','i');
		return pattern.test(myId);
	}
	static validateCode = (myId:string,min:number,max:number)=> {
		var pattern = new RegExp('^[0-9A-Za-z_]{'+min.toString()+','+max.toString()+'}$','i');
		return pattern.test(myId);
	}	
	static validateNumber(num:string) {
		var regex = new RegExp( '^[0-9]+$' );
		if( num.match( regex ) ) {
			return true;
		} else {
			return false;
		}
	}
	static validateDate(value:string) {
		const sp = value.split(/[^0-9]/);
		if( sp.length!==3 ){
			return false;
		}
		const y = parseInt(sp[0]);
		const m = parseInt(sp[1])-1;
		const d = parseInt(sp[2]);
		if( isNaN(y) || isNaN(m) || isNaN(d) || y<1 || m<0 || d<1 ){
			return false;
		}
		const date = new Date(y,m,d);
		if(date.getFullYear() != y || date.getMonth() != m || date.getDate() != d){
			return false;
		}
		return true;
	}
	static readonly toHan = (input:string)=>{
		return input.replace(/[！-～]/g,
			function(input){
				return String.fromCharCode(input.charCodeAt(0)-0xFEE0);
			}
		);
	};
	static readonly hasZen = (input:string)=>{
		if( input.match(/[^\x01-\x7E\xA1-\xDF]/) ) {
			return true;
		}
		return false;
	}
	static readonly hasHankana = (input:string)=>{
		var reg = new RegExp(/[ｦ-ﾟ]/);
		return reg.test(input);
	}
	static readonly getExt = (name:string)=>{
		const sp = name.split('.');
		let ext = sp[sp.length-1];
		ext = ext.toLowerCase();
		if( ext==="jpeg" ){
			ext = "jpg";
		}
		return ext;
	}
	static download = (blob:any,name:string)=>{
		const url = window.URL.createObjectURL(new Blob([blob]));
		const link = document.createElement('a');

		link.href = url;
		link.setAttribute('download', name);

		document.body.appendChild(link);

		link.click();

		if( link.parentNode ){
			link.parentNode.removeChild(link);
		}
		setTimeout(() => {
			window.URL.revokeObjectURL( url );
		},10000);
	}	
}