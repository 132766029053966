import 'firebase/auth';
import firebase from 'firebase/app'
  
//import 'firebase/firestore';

const config = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: "quaqoostore-d1bff.firebaseapp.com",
    projectId: "quaqoostore-d1bff",
    storageBucket: "quaqoostore-d1bff.appspot.com",
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
}
//console.log("firebase init");

firebase.initializeApp(config)
export const googleAuth = new firebase.auth.GoogleAuthProvider();
export const twitterAuth = new firebase.auth.TwitterAuthProvider();
export const auth = firebase.auth();
export const getCredential = (email:string,password:string)=>{
	return firebase.auth.EmailAuthProvider.credential(email,password);
}
//export const db = firebase.firestore();
