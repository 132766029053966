import React,{useState,useEffect} from 'react';
import {Link,Navigate} from 'react-router-dom'//yarn add @types/react-router-dom
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
//import IconButton from '@mui/material/IconButton';
//import Tooltip from '@mui/material/Tooltip';
//import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
//import TwitterIcon from '@mui/icons-material/Twitter';
//import GoogleIcon from '@mui/icons-material/Google';
import MailIcon from '@mui/icons-material/Mail';
//import CancelIcon from '@mui/icons-material/Cancel';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
//import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import LogoutIcon from '@mui/icons-material/Logout';
import CircularProgress from '@mui/material/CircularProgress';
import Loading from './Loading';
import Toast from './Toast';
import LoginUpdate from './LoginUpdate';
import PasswordUpdate from './PasswordUpdate';
import PageTitle from './PageTitle';
import {useStyles} from './useStyles';
import {UserContainer} from './UserContainer';
import Ajax from './Ajax';

interface LogoutProps {

}

const Logout = (props:LogoutProps)=> {
    const [states,setStates] = useState({
        email:'',
        isOut:false,
        isLoading:false,
		openLogin:false,
		openPassword:false,
		oldUserId:'',
		newUserId:'',
		result:'',
		locked: false,
    });
	const classes = useStyles();
    const [toast,setToast] = useState("");
    const userState = UserContainer.useContainer();
    const logout = ()=>{
        userState.signOut();
		setStates({...states,isOut:true});
    }	
    const closeToast = ()=>{
        setToast("");
    }
	const change = ()=>{

	}
	const openLogin = ()=>{
		if( !userState.user || !userState.user.uid ){
			return;
		}
		//console.log("oldUserId:"+userState.user.uid);

		setStates({...states,oldUserId:userState.user.uid,openLogin:true});
	}
	const closeLogin = ()=>{
		setStates({...states,openLogin:false});
	}
	const openPassword = ()=>{
		setStates({...states,openPassword:true});
	}
	const closePassword = ()=>{
		setStates({...states,openPassword:false});
	}
	const updatePassword = ()=>{
		
	}
	const updateAccount = async (newUserId:string,oldUserId:string)=>{
		setStates({...states,openLogin:false,isLoading:true});
		const ajax = new Ajax();
		const res = await ajax.changeUserId(newUserId,oldUserId);
		if( res.result=="OK" ){
			userState.setProfile(res.profile);
			userState.setPayment(res.payment);
			setStates({...states,openLogin:false,result:'success'});
		} else {
			userState.signOut();
			setStates({...states,openLogin:false,result:res.reason});
		}
	}
	const lock = ()=>{
		setStates({...states,locked:true});
	}

	let provider = "メール";
	if( userState.user ){
		//console.log(userState.user);
		if( userState.user.providerData[0] ){
			if( userState.user.providerData[0].providerId==="twitter.com" ){
				provider = "twitter";
			}
			if( userState.user.providerData[0].providerId==="google.com" ){
				provider = "google";
			}
			if( userState.user.providerData[0].providerId==="password" ){
				if( userState.user.emailVerified ){
					provider = "メール";
				} else {
					provider = "パスワード";
				}
			}
		}
	}
	
	useEffect( ()=>{
		if( !userState.ready ){
			return;
		}
		if( !userState.user || !userState.user.uid ){
			return;
		}
		if( states.oldUserId!=='' && states.oldUserId!==userState.user.uid && !states.locked ){
			//console.log("changed:"+states.userId+"->"+userState.user.uid);
			updateAccount(userState.user.uid,states.oldUserId);
			//setStates({...states,newUserId:userState.user.uid});
		} else {
			setStates({oldUserId:'',newUserId:'',email:"",isOut:false,isLoading:false,openLogin:false,openPassword:false,result:'',locked:false});
		}
	},[userState.ready,userState.user?.uid]);

	if( !userState.ready ){
        return <Box style={{textAlign:'center',paddingTop:'4em'}}><CircularProgress/></Box>
    }

	if( !userState.user && !states.isOut ){
		return <Navigate to='/'/>
	}

	if( states.result!=='' ){
		const to = "/account/changed/"+states.result;
		return <Navigate to={to}/>
	}

    return (
		<React.Fragment>
			<PageTitle title="アカウント" icon='account'/>
			<Paper style={{width:'100%',maxWidth:'360px',margin:'0 auto'}}>
				<div style={{padding:'1em',paddingBottom:'2em'}}>
            	{!states.isOut ? (
					<React.Fragment>
						<Box className="auth_data">
							<h3 style={{lineHeight:'1.2em'}}><AccountCircleIcon className={classes.pageTitleIcon}/>ログイン中のアカウント</h3>
							<ul className={classes.accountData}>
							{userState.user?.displayName &&
								<li>
									{userState.user?.displayName}
								</li>
							}
								<li>{provider} でログイン</li>
							</ul>
							<Box style={{textAlign:'right',marginBottom:'0.5em'}}>
								<FormControl>
									<Button variant="text" color="primary" onClick={openLogin} startIcon={<ArrowRightIcon/>}>ログイン方法の変更</Button>
								</FormControl>
							</Box>
							{userState.user?.email &&
							<React.Fragment>
								<h3 style={{lineHeight:'1.2em'}}><MailIcon className={classes.pageTitleIcon}/>メールアドレス</h3>
								<ul className={classes.accountData}>
									<li>
										{userState.user?.email}
									</li>
								</ul>
							</React.Fragment>
							}
							{provider==='パスワード' &&									
							<Box style={{textAlign:'right'}}>
								<FormControl>
									<Button variant="text" color="primary" onClick={openPassword} startIcon={<ArrowRightIcon/>}>メールとパスワードの変更</Button>
								</FormControl>
							</Box>
							}
						</Box>
						<FormControl fullWidth={true} style={{marginTop:'2.5em'}}>
							<Button variant="contained" color="primary" onClick={logout}>ログアウト</Button>
						</FormControl>
					</React.Fragment>
            	):(
					<Box style={{textAlign:'center',paddingTop:'2em',paddingBottom:'1em'}}>
						<LogoutIcon style={{fontSize:'200%'}}/><br/>
						ログアウトしました
						<FormControl fullWidth={true} style={{marginTop:'2em'}}>
							<Link to='/' className={classes.link}><Button variant="contained" color="primary">トップページ</Button></Link>
						</FormControl>
					</Box>
	            )}
				</div>
			</Paper>
			<LoginUpdate isOpen={states.openLogin} close={closeLogin} lock={lock}/>
			<PasswordUpdate isOpen={states.openPassword} close={closePassword} done={updatePassword}/>
            <Toast mes={toast} close={closeToast}/>
            <Loading isOpen={states.isLoading}/>
		</React.Fragment>
    );
}

export default Logout;
