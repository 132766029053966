import {Product,Profile,Payment,Inquiry} from './Inc';

export interface AjaxResult {
	err: boolean,
	reason: string,
}

export default class Ajax {
	abortController:AbortController;
	token:string;
	url:string;
	appSideId:number;
	constructor(){
		if( process.env.REACT_APP_SYSTEM_URL ){
			this.url = process.env.REACT_APP_SYSTEM_URL;
		} else {
			this.url = "";
		}
		this.appSideId = 1;
		this.token = "";
		this.abortController = new AbortController();
	}
	setToken = (tk:string)=>{
		this.token = tk;
	}
	send = async (path:string,data:any)=>{
		//console.log("send",Ajax.url+path,data);
		try {
			return await fetch(this.url+path, {
				method : 'POST',
				body : JSON.stringify({...data,AppSideId:this.appSideId},function(key,value){
					if( key==="createdAt" || key==="updatedAt" ){
						return null;
					}
					return value;
				}),
				credentials: "include",
				signal: this.abortController.signal,
			}).then(response => response.json())
			.catch(err => {
				if (err.name === 'AbortError') {
					return {err:true,reason:'abort'} as AjaxResult;
				} else {
					return {err:true,reason:'network'} as AjaxResult;
				}
			});
		} catch(err) {
			return {err:true,reason:'unknown'} as AjaxResult;
		}
	}

	hello = async ()=>{
		return await this.send("hello",{});
	}

	sendAccountMail = async (userId:string)=>{
		return await this.send("payment/mail", {userId:userId});
	}

	activatePayment = async (checkKey:string)=>{
		return await this.send("payment/check", {checkKey:checkKey});
	}

	getProfile = async (userId:string,methodId:string)=>{
		return await this.send("profile/get", {userId:userId,methodId:methodId});
	}

	updateProfile = async (userId:string,profile:Profile)=>{
		return await this.send("profile/update", {userId:userId,profile: profile});
	}

	addProfileImage = async (userId:string,image:string)=>{
		return await this.send("profile/image/upload", {userId:userId,image:image});
	}

	checkUniqueProfileName = async (userId:string,name:string)=>{
		return await this.send("profile/unique", {userId:userId,key:name});
	}

	releaseProfile = async (userId:string,publicKey:string,flag:boolean)=>{
		return await this.send("profile/release", {userId:userId,key:publicKey,flag:flag});
	}

	removeProfile = async (userId:string)=>{
		return await this.send("profile/remove", {userId:userId});
	}

	getPayment = async (userId:string)=>{
		return await this.send("payment/get", {userId:userId});
	}

	updatePayment = async (userId:string,payment:Payment)=>{
		return await this.send("payment/update", {userId:userId,payment: payment});
	}

	getFullProfile = async (userId:string)=>{
		return await this.send("profile/full", {userId:userId});
	}

	updateProduct = async (userId:string,product:Product)=>{
		return await this.send("product/update", {userId:userId,product: product});
	}

	addProductFile = async (userId:string,productId:string,file:File,compressed:boolean,sample:boolean)=>{
		const formData = new FormData();
		formData.append('userId', userId);
		formData.append('file', file);
		formData.append('productId', productId);
		formData.append('appSideId',this.appSideId.toString());

		if( compressed ){
			formData.append('compressed', 'true');
		} else {
			formData.append('compressed', 'false');
		}

		if( sample ){
			formData.append('sample', 'true');
		} else {
			formData.append('sample', 'false');
		}

		try {
			return await fetch(this.url+"product/file/upload", {
				method : 'POST',
				body: formData,
				credentials: "include",
				signal: this.abortController.signal,
			})
			.then(response => response.json())
			.catch(err=>{
				if (err.name === 'AbortError') {
					return {err:true,reason:'abort'} as AjaxResult;
				} else {
					return {err:true,reason:'network'} as AjaxResult;
				}
			});
		} catch(err) {
			return {err:true,reason:'unknown'} as AjaxResult;
		}
	}

	addProductImage = async (userId:string,productId:string,image:string)=>{
		return await this.send("product/image/upload", {userId:userId,productId:productId,image:image});
	}

	getProducts = async (userId:string,search:any)=>{
		return await this.send("product/list", {...search,userId:userId});
	}

	getProduct = async (userId:string,productId:string)=>{
		return await this.send("product/get", {productId:productId,userId:userId,suggestedTags:true});
	}

	releaseProduct = async (userId:string,productId:string,flag:boolean)=>{
		return await this.send("product/release", {productId:productId,flag:flag,userId:userId});
	}

	deleteProduct = async (userId:string,productId:string)=>{
		return await this.send("product/delete", {productId:productId,userId:userId});
	}

	getCharges = async (userId:string,search:any)=>{
		return await this.send("charge/list", {...search,userId:userId});
	}

	getTransfers = async (userId:string,search:any)=>{
		return await this.send("transfer/list", {...search,userId:userId});
	}

	getTransfer = async (userId:string,uniqueKey:string)=>{
		return await this.send("transfer/get", {userId:userId,uniqueKey:uniqueKey});
	}

	getBalance = async (userId:string)=>{
		return await this.send("payment/balance", {userId:userId});
	}

	downloadTransferCSV = async (userId:string,uniqueKey:string,fileName:string)=>{
		this.reset();
		return await fetch(this.url+"transfer/csv", {
			method : 'POST',
			body : JSON.stringify({
				userId:userId,
				uniqueKey:uniqueKey,
				appSideId:this.appSideId,
			}),
			credentials: "include",
			signal: this.abortController.signal,
		}).then((response) => {
			if( response.status!==200 ){
				const error = new Error();
				error.message = "notFound";
				throw error;
			}
			return response.blob()
		}).then((blob) => {
			//const dt = new Date();
			//const today = dt.getFullYear() + ('00' + (dt.getMonth()+1)).slice(-2) + ('00' + dt.getDate()).slice(-2);
			const name = fileName+".csv";
			const url = window.URL.createObjectURL(new Blob([blob]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', name);

			document.body.appendChild(link);

			link.click();

			if( link.parentNode ){
				link.parentNode.removeChild(link);
			}

			return true;
		})
	}

	downloadTransferPdf = async (userId:string,uniqueKey:string,fileName:string)=>{
		this.reset();
		return await fetch(this.url+"transfer/pdf", {
			method : 'POST',
			body : JSON.stringify({
				userId:userId,
				uniqueKey:uniqueKey,
				appSideId:this.appSideId,
			}),
			credentials: "include",
			signal: this.abortController.signal,
		}).then((response) => {
			if( response.status!==200 ){
				const error = new Error();
				error.message = "notFound";
				throw error;
			}
			return response.blob()
		}).then((blob) => {
			const name = fileName+".pdf";
			const url = window.URL.createObjectURL(new Blob([blob]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', name);

			document.body.appendChild(link);

			link.click();

			if( link.parentNode ){
				link.parentNode.removeChild(link);
			}

			return true;
		})
	}

	changeUserId = async (newUserId:string,oldUserId:string)=>{
		return await this.send("profile/change", {UserId:newUserId,oldUserId:oldUserId});
	}

	updateAccount = async (userId:string,password:string,methodId:string)=>{
		return await this.send("account/update", {userId:userId,password:password,methodId:methodId});
	}


	sendInquiry = async (inquiry:Inquiry)=>{
		return await this.send("inquiry/send", {inquiry:inquiry});
	}

	reset = ()=>{
		this.abortController = new AbortController();
	}

	abort = ()=>{
		this.abortController.abort();
	}

	isAborted = ()=>{
		return this.abortController.signal.aborted
	}	
}