import React, {useState} from "react";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Looks1Icon from '@mui/icons-material/LooksOne';
import Looks2Icon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import Looks4Icon from '@mui/icons-material/Looks4';
import Looks5Icon from '@mui/icons-material/Looks5';
import Looks6Icon from '@mui/icons-material/Looks6';
import SettingsIcon from '@mui/icons-material/Settings';
import CancelIcon from '@mui/icons-material/Cancel';

import ForwardIcon from '@mui/icons-material/Forward';
import FastForwardIcon from '@mui/icons-material/FastForward';
import FastRewindIcon from '@mui/icons-material/FastRewind';

import Menu from '@mui/material/Menu';
//import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';

import LoadImage from './LoadImage';
import YesNo from './YesNo';
import {useStyles} from './useStyles';
import {Inc,ImageData} from './Inc';

interface ImagePagerProps {
	images: ImageData[],
	disablePager?: boolean,
	remove?(index:number):void,
	order?(fromIndex:number,toIndex:number):void,
}

const ImagePager: React.FC<ImagePagerProps> =  (props)=> {
	const classes = useStyles();
	const [currentIndex,setCurrentIndex] = useState(0);
	const [isCancel,setIsCancel] = useState(false);
	const [anchorMenu, setAnchorMenu] = React.useState<null | HTMLElement>(null);
	const isMenu = Boolean(anchorMenu);

	const changeIndex = (index:number)=>{
		setCurrentIndex(index);
	}
	const openCancel = ()=>{
		closeMenu();
		setIsCancel(true);
	}
	const closeCancel = ()=>{
		setIsCancel(false);
	}
	const remove = ()=>{
		if( props.remove ){
			props.remove(currentIndex);
		}
		setIsCancel(false);
	}
	const order = (value:string)=>{
		//console.log(value);
		if( props.order ){
			let toIndex = currentIndex;
			if( value==='top' ){
				toIndex = 0;
			}
			if( value==='up' ){
				toIndex--;
				if( toIndex<0 ){
					toIndex = 0;
				}
			}
			if( value==='down' ){
				toIndex++;
				if( toIndex>=props.images.length ){
					toIndex=props.images.length-1;
				}
			}
			if( value==='last' ){
				toIndex = props.images.length - 1;
			}
			//setCurrentIndex(toIndex);
			props.order(currentIndex,toIndex);
		}
		setAnchorMenu(null);
	}
	const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorMenu(event.currentTarget);
	};
	const closeMenu =  () => {
		setAnchorMenu(null);
	};


	let imageSrcs:string[] = [];
	let imageIndexs:number[] = [];
	if( props.images ){
		for( let i=0; i<props.images.length; i++ ){
			//if( props.images[i].source!=="deleted" ){
				if( props.images[i].source==="" ){
					imageSrcs.push(Inc.getUploadImageURL(props.images[i].name));
				} else {
					imageSrcs.push(props.images[i].source);
				}
				imageIndexs.push(i);
				//if( i<currentIndex || prevIndex===-1 ){
				//	prevIndex = i;
				//}
			//}
		}
	}

	const prevIndex = currentIndex - 1;

	if( prevIndex>=0 ){
		//if(!props.images[currentIndex] || props.images[currentIndex].source==="deleted"){
		if(!props.images[currentIndex]){
			setCurrentIndex(prevIndex);
		}
	}

	let imageSrc = "";
	if( props.images[currentIndex] ){
		//if( props.images[currentIndex].source!=="deleted" ){
			if( props.images[currentIndex].source==="" ){
				imageSrc = Inc.getUploadImageURL(props.images[currentIndex].name);
			} else {
				imageSrc = props.images[currentIndex].source;
			}
		//}
	}

	const selected = classes.formImageButton+" "+classes.formImageButtonSelected;
	const normal = classes.formImageButton;

	const icons = [
		<Looks1Icon className={imageIndexs.length>0 && imageIndexs[0]===currentIndex ? selected : normal}/>,
		<Looks2Icon className={imageIndexs.length>1 && imageIndexs[1]===currentIndex ? selected : normal}/>,
		<Looks3Icon className={imageIndexs.length>2 && imageIndexs[2]===currentIndex ? selected : normal}/>,
		<Looks4Icon className={imageIndexs.length>3 && imageIndexs[3]===currentIndex ? selected : normal}/>,
		<Looks5Icon className={imageIndexs.length>4 && imageIndexs[4]===currentIndex ? selected : normal}/>,
		<Looks6Icon className={imageIndexs.length>5 && imageIndexs[5]===currentIndex ? selected : normal}/>,
	]

	//console.log("image:"+imageSrc);
	//let menus:any[] = [];
	//menus.push(<MenuItem key="menu1" onClick={()=>order('top')} disabled={currentIndex===0}><ListItemIcon><FastRewindIcon/></ListItemIcon> 先頭に移動</MenuItem>);
	//menus.push(<MenuItem key="menu2" onClick={()=>order('up')} disabled={currentIndex===0}><ListItemIcon><ForwardIcon style={{transform:`rotate(180deg)`}}/></ListItemIcon> 一つ前に移動</MenuItem>);
	//menus.push(<MenuItem key="menu3" onClick={()=>order('down')} disabled={currentIndex===props.images.length-1}><ListItemIcon><ForwardIcon/></ListItemIcon> 一つ後に移動</MenuItem>);
	//menus.push(<MenuItem key="menu4" onClick={()=>order('last')} disabled={currentIndex===props.images.length-1}><ListItemIcon><FastForwardIcon/></ListItemIcon> 最後に移動</MenuItem>);
	//menus.push(<Divider key="menu9"/>);

	return (
		<Box
			className={classes.formImageBox}
			style={{position:'relative'}}
		>
			<LoadImage
				src={imageSrc}
			/>
			<Box className={classes.formImagePager} style={{position:'absolute',bottom:'1em'}}>
			{imageSrcs.map((src:string,index:number)=>{
				if( src==="" || props.disablePager ){
					return;
				}
				const key = "src" + index;
				return (
					<IconButton key={key} size="small" onClick={()=>changeIndex(imageIndexs[index])}>{icons[index]}</IconButton>
				)
			})}
			</Box>
			{props.remove && (props.disablePager===null || props.disablePager===false) &&
			<IconButton onClick={openMenu} style={{position:'absolute',top:'0',right:'0'}}><SettingsIcon className={classes.formImageCancelButton}/></IconButton>
			}
			{props.remove && props.disablePager &&
			<IconButton onClick={openCancel} style={{position:'absolute',top:'0',right:'0'}}><CancelIcon className={classes.formImageCancelButton}/></IconButton>
			}
			<YesNo onYes={remove} onNo={closeCancel} title="画像の削除" text="この画像を消してよろしいですか？" isOpen={isCancel}/>
			<Menu
				id="product-menu"
				anchorEl={anchorMenu}
				open={isMenu}
				onClose={closeMenu}
			>
				<Box style={{textAlign:'center',fontSize:'90%',paddingBottom:'0.5em'}}>この画像を…</Box>
				<Divider/>
				<MenuItem key="menu1" onClick={()=>order('top')} disabled={currentIndex===0}><ListItemIcon><FastRewindIcon/></ListItemIcon> 先頭に移動</MenuItem>
				<MenuItem key="menu2" onClick={()=>order('up')} disabled={currentIndex===0}><ListItemIcon><ForwardIcon style={{transform:`rotate(180deg)`}}/></ListItemIcon> 一つ前に移動</MenuItem>
				<MenuItem key="menu3" onClick={()=>order('down')} disabled={currentIndex===props.images.length-1}><ListItemIcon><ForwardIcon/></ListItemIcon> 一つ後に移動</MenuItem>
				<MenuItem key="menu4" onClick={()=>order('last')} disabled={currentIndex===props.images.length-1}><ListItemIcon><FastForwardIcon/></ListItemIcon> 最後に移動</MenuItem>
				<Divider key="menu9"/>
				<MenuItem onClick={openCancel}><ListItemIcon><CancelIcon/></ListItemIcon> 削除</MenuItem>
			</Menu>			
		</Box>
	);
};

export default ImagePager;
