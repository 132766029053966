import React from 'react';
import {Link} from 'react-router-dom'
import Grid from '@mui/material/Grid';
//import Paper from '@mui/material/Paper';
//import Container from '@mui/material/Container';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';

import PersonIcon from '@mui/icons-material/Person';
import PaidIcon from '@mui/icons-material/Paid';
import FaceIcon from '@mui/icons-material/Face';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PublicIcon from '@mui/icons-material/Public';

import RuleIcon from '@mui/icons-material/Rule';
import SecurityIcon from '@mui/icons-material/Security';

import PageTitle from './PageTitle';
import {useStyles} from './useStyles';

interface GuideProps {
    
}

const GuideIndex:React.FC<GuideProps> = (props)=> {
	const classes = useStyles();
	return (
		<React.Fragment>
			<PageTitle title="ご利用ガイド" icon="help"/>
			<Grid container spacing={1}>
				<Grid item xs={12} md={6}>
					<List>
						<Link to="/help/account/1" className={classes.link}>
							<ListItem button>
								<ListItemIcon><Avatar><PersonIcon/></Avatar></ListItemIcon>
								<ListItemText primary="アカウント" secondary="ログインの方法から削除と終了（退会）まで" style={{color:'teal'}}/>
							</ListItem>
						</Link>
						<Link to="/help/profile/1" className={classes.link}>
							<ListItem button>
								<ListItemIcon><Avatar><FaceIcon/></Avatar></ListItemIcon>
								<ListItemText primary="プロフィール" secondary="販売者・クリエイターのプロフィール登録と支払情報など" style={{color:'teal'}}/>
							</ListItem>
						</Link>
						<Link to="/help/product/1" className={classes.link}>
							<ListItem button>
								<ListItemIcon><Avatar><UploadFileIcon/></Avatar></ListItemIcon>
								<ListItemText primary="商品登録" secondary="アップロードできるファイルの種類や商品登録の注意事項" style={{color:'teal'}}/>
							</ListItem>
						</Link>
						<Link to="/help/sale/1" className={classes.link}>
							<ListItem button>
								<ListItemIcon><Avatar><CreditScoreIcon/></Avatar></ListItemIcon>
								<ListItemText primary="販売" secondary="販売の開始と休止、売上の確認など" style={{color:'teal'}}/>
							</ListItem>
						</Link>
					</List>
				</Grid>
				<Grid item xs={12} md={6}>
					<List>
						<Link to="/help/transfer/1" className={classes.link}>
							<ListItem button>
								<ListItemIcon><Avatar><ReceiptIcon/></Avatar></ListItemIcon>
								<ListItemText primary="売上の入金" secondary="売上の入金についての大事なルールと最低振り込み金額" style={{color:'teal'}}/>
							</ListItem>
						</Link>
						<Link to="/help/promotion/1" className={classes.link}>
							<ListItem button>
								<ListItemIcon><Avatar><PublicIcon/></Avatar></ListItemIcon>
								<ListItemText primary="商品の宣伝" secondary="頑張って宣伝してください！という宣伝についての基本方針" style={{color:'teal'}}/>
							</ListItem>
						</Link>
						<Link to="/help/fee/1" className={classes.link}>
							<ListItem button>
								<ListItemIcon><Avatar><PaidIcon/></Avatar></ListItemIcon>
								<ListItemText primary="利用料金" secondary="商品が売れた時にだけ手数料が発生するシステム" style={{color:'teal'}}/>
							</ListItem>
						</Link>
					</List>
				</Grid>
			</Grid>
			<Divider style={{marginTop:'1em',marginBottom:'1em'}}/>
			<List>
					<Link to="/term" className={classes.link}>
						<ListItem>
							<ListItemIcon><RuleIcon/></ListItemIcon>
							<ListItemText primary="販売利用規約"/>
						</ListItem>
					</Link>
					<Link to="/privacy" className={classes.link}>
						<ListItem>
							<ListItemIcon><SecurityIcon/></ListItemIcon>
							<ListItemText primary="個人情報保護方針"/>
						</ListItem>
					</Link>
			</List>
		</React.Fragment>
	)
}

export default GuideIndex;