import React,{useEffect,useState} from 'react';
import Box from '@mui/material/Box';
import {Link,Navigate,useParams} from 'react-router-dom';
//import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
//import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
//import useMediaQuery from '@mui/material/useMediaQuery';
import CircularProgress from '@mui/material/CircularProgress';
//import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
//import ClearIcon from '@mui/icons-material/Clear';
//import SearchIcon from '@mui/icons-material/Search';
//import {GRID_DATA_TEXT} from './GridDataText';
import PageTitle from './PageTitle';
import TransferCsvDownload from './TransferCsvDownload';
import TransferPdfDownload from './TransferPdfDownload';
import Ajax from './Ajax';
import {Transfer,PaymentDeposits} from './Inc';
import Tools from './Tools';
import {UserContainer} from './UserContainer';
import {useStyles} from './useStyles';

interface TransferDetailProps {
    
}

const TransferDetail:React.FC<TransferDetailProps> = (props)=> {
	const userState = UserContainer.useContainer();
	const defaultStates = {
		transfer: {} as Transfer,
		isLoading: true,
		isCsvDownload: false,
		isPdfDownload: false,
		notFound: false,
	}
	const [states,setStates] = useState(defaultStates);
	const {uniqueKey} = useParams<{uniqueKey:string}>();
	const classes = useStyles();

	const load = async ()=>{
		if(!userState.user || !uniqueKey){
			return;
		}
		const ajax = new Ajax();
		const res = await ajax.getTransfer(userState.user.uid,uniqueKey);
		if( !res.err ){
			//console.log(res.transfer);
			setStates({...states,transfer:res.transfer,isLoading:false});
		} else {
			setStates({...states,notFound:true});
		}
	}

	const openCsvDownload = ()=>{
		setStates({...states,isCsvDownload:true});
	}
	const closeCsvDownload = ()=>{
		setStates({...states,isCsvDownload:false});
	}
	const openPdfDownload = ()=>{
		setStates({...states,isPdfDownload:true});
	}
	const closePdfDownload = ()=>{
		setStates({...states,isPdfDownload:false});
	}

	useEffect(()=>{
		setStates(defaultStates);
		load();
	},[userState.user,uniqueKey]);

	if( states.notFound ){
		return <Navigate to="/"/>
	}

	return (
		<React.Fragment>
			<PageTitle title="入金詳細" icon="transfer"/>
			<Box style={{marginBottom:"0.5em",display:`flex`}}>
				<Link to="/transfer/list" className={classes.link}>
					<Button variant="text" sx={{ display: { xs: 'none',md: 'flex' } }}><ArrowBackIcon className={classes.buttonIcon}/> 入金一覧にもどる</Button>
				</Link>
				<Box style={{flex:`1`}}/>
			</Box>			
			<Box style={{width: '100%'}}>
				{states.isLoading ? (
				<CircularProgress/>
				):(
				<div>
					<TableContainer style={{marginBottom:'1em'}}>
						<Table>
							<TableBody>
								<TableRow>
									<TableCell className="transferDetailTableTh" style={{width:'24%'}}>
										入金ID
									</TableCell>
									<TableCell>
										{states.transfer.uniqueKey}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell className="transferDetailTableTh">
										入金予定日
									</TableCell>
									<TableCell>
										{states.transfer.transferDate.substring(0,4)}/{states.transfer.transferDate.substring(4,6)}/{states.transfer.transferDate.substring(6,8)}
									</TableCell>
								</TableRow>
								{states.transfer.method==='0' ? (
								<TableRow>
									<TableCell className="transferDetailTableTh">
										振込口座
									</TableCell>
									<TableCell>
										{states.transfer.bank}{states.transfer.branch}<br/>
										{PaymentDeposits[states.transfer.deposit]}{states.transfer.accountNumber}<br/>
										{states.transfer.accountHolder}
									</TableCell>
								</TableRow>
								):(
								<TableRow>
									<TableCell className="transferDetailTableTh">
										入金方法
									</TableCell>
									<TableCell>
										Amazonギフト券（Eメールタイプ）
									</TableCell>
								</TableRow>
								)}
								<TableRow>
									<TableCell className="transferDetailTableTh">
										状態
									</TableCell>
									<TableCell>
										{states.transfer.status==='2' &&
											<span className="transferDone">入金済</span>
										}
										{states.transfer.status==='1' &&
											<span className="transferAfter">確定</span>
										}
										{states.transfer.status==='0' &&
											<span className="transferBefore">未入金</span>
										}
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>					
					<TableContainer>
						<Table>
							<caption style={{textAlign:'right'}}>
								※1 販売手数料3.0% + 決済手数料3.6%<br/>
								※2 振込手数料¥{states.transfer.transferFee}が発生します<br/>
							</caption>
							<TableHead>
								<TableRow>
									<TableCell className="transferDetailTableTh"></TableCell>
									<TableCell align="center" className="transferDetailTableTh">数量</TableCell>
									<TableCell align="center" className="transferDetailTableTh">金額</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								<TableRow>
									<TableCell className="transferDetailTableTh" style={{width:'24%'}}>売上</TableCell>
									<TableCell align="center" style={{width:'38%'}}>{Tools.comma(states.transfer.charges)}</TableCell>
									<TableCell align="center" style={{width:'38%'}}>{Tools.comma(states.transfer.chargeAmount)}円</TableCell>
								</TableRow>
								<TableRow>
									<TableCell className="transferDetailTableTh">返金</TableCell>
									<TableCell align="center">{Tools.comma(states.transfer.refunds)}</TableCell>
									<TableCell align="center">{Tools.comma(states.transfer.refundAmount)}円</TableCell>
								</TableRow>
								<TableRow>
									<TableCell className="transferDetailTableTh">手数料</TableCell>
									<TableCell align="center"></TableCell>
									<TableCell align="center">
										{Tools.comma(states.transfer.fee)}円<br/>
										※1
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell className="transferDetailTableTh">入金額</TableCell>
									<TableCell align="center"></TableCell>
									<TableCell align="center">
										<span className={classes.transferDeposit}>{Tools.comma(states.transfer.depositAmount)}円</span><br/>
										※2
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
					<Box style={{margin:"1em 0"}}>
						<Button variant="outlined" color="primary" onClick={openCsvDownload} style={{marginRight:'0.25em'}}>入金内訳CSV</Button>
						<Button variant="outlined" color="primary" onClick={openPdfDownload}>振込明細書PDF</Button>
					</Box>
				</div>
				)}
			</Box>
			<TransferCsvDownload close={closeCsvDownload} isOpen={states.isCsvDownload} userId={userState?.user?.uid} uniqueKey={states.transfer.uniqueKey} fileName={"入金内訳"+states.transfer.transferDate}/>
			<TransferPdfDownload close={closePdfDownload} isOpen={states.isPdfDownload} userId={userState?.user?.uid} uniqueKey={states.transfer.uniqueKey} fileName={"振込明細書"+states.transfer.transferDate}/>
		</React.Fragment>
	)
}

export default TransferDetail;