import React,{useState,useEffect,useCallback,useMemo,useRef} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
//import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import CircularProgress from '@mui/material/CircularProgress';
//import FormControl from '@mui/material/FormControl';
import DoneIcon from '@mui/icons-material/Done';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import CloseIcon from '@mui/icons-material/Close';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Ajax from './Ajax';
import {Product,ImageData,CompressedProductFileSize,AvailableFileTypes} from './Inc';
import Tools from './Tools';
import {useStyles} from './useStyles';

interface ProductUploaderProps {
    isOpen: boolean,
	close():void,
	finish(newId:string,permission:boolean):void,
	files: File[],
	serviceFiles: File[],
	sampleFiles: File[],
	isServiceExchange: boolean,
	isSampleExchange: boolean,
	userId: string,
	product: Product,
	images: ImageData[],
	thumbnails: ImageData[],
}

const ProductUploader:React.FC<ProductUploaderProps> = (props)=> {
	const classes = useStyles();
	const defaultStates = {
		fileResults:[] as number[],
		serviceFileResults:[] as number[],
		sampleFileResults:[] as number[],
		dataBytes: 0,
		imageResults: [] as number[],
		thumbnailResults: [] as number[],
		dataResult: 0,
		progress: 0,//0:処理前　1:処理中　2:完了　9:失敗
		isError: false,
		step: 0,
		newProductId: "",
		permission: false,//販売許可（メールチェック済）
	};
	const ajax = useRef(new Ajax);
	const [states, setStates] = useState(defaultStates);

	const close = ()=>{
		props.close();
	}
	const finish = ()=>{
		props.finish(states.newProductId,states.permission);
	}
	const resultIcon = useCallback((result)=>{
		if( result===9 ){
			return <CloseIcon style={{color:"#FF0000"}}/>;
		} else if( result===1 ){
			return <HourglassFullIcon className={classes.rotate} style={{color:"#a0d8ef"}}/>;
		} else if( result===2 ){
			return <DoneIcon style={{color:"#1565C0"}}/>;
		} else if( result===3 ){
			return <ArrowDownwardIcon style={{color:"#CCCCCC"}}/>;
		} else {
			return <FileUploadIcon style={{color:"#999999"}}/>;
		}
	},[]);

	const newFileResults = useMemo(()=>{
		return props.files.map((file,index) => {
			let result = 0;
			if( states.fileResults[index] ){
				result = states.fileResults[index];
			}
			return ( 
				<ListItem key={file.name}>
					<ListItemText primary={file.name} secondary={Tools.FileSize(file.size)}/>
					<ListItemSecondaryAction>
						{resultIcon(result)}
					</ListItemSecondaryAction>
				</ListItem>
			);
		});
	},[props.files,states.fileResults,states.step]);

	const currentFileResults = useMemo(()=>{
		return props.product.fileNames.split("\t").map((name:string,index:number) => {
			const key = "file"+index;
			return ( 
				<ListItem key={key}>
					<ListItemText primary={name} secondary="更新なし"/>
					<ListItemSecondaryAction>
						{resultIcon(3)}
					</ListItemSecondaryAction>
				</ListItem>
			);
		});
	},[props.product.fileNames]);

	const newServiceFileResults = useMemo(()=>{
		return props.serviceFiles.map((file,index) => {
			let result = 0;
			if( states.serviceFileResults[index] ){
				result = states.serviceFileResults[index];
			}
			return ( 
				<ListItem key={file.name}>
					<ListItemText primary={file.name} secondary={Tools.FileSize(file.size)}/>
					<ListItemSecondaryAction>
						{resultIcon(result)}
					</ListItemSecondaryAction>
				</ListItem>
			);
		});
	},[props.serviceFiles,states.serviceFileResults,states.step]);

	const currentServiceFileResults = useMemo(()=>{
		return props.product.serviceFileNames.split("\t").map((name:string,index:number) => {
			if( name==="" || props.isServiceExchange ){
				return;
			}
			const key = "file"+index;
			return ( 
				<ListItem key={key}>
					<ListItemText primary={name} secondary="更新なし"/>
					<ListItemSecondaryAction>
						{resultIcon(3)}
					</ListItemSecondaryAction>
				</ListItem>
			);
		});
	},[props.product.serviceFileNames,props.isServiceExchange]);

	const newSampleFileResults = useMemo(()=>{
		return props.sampleFiles.map((file,index) => {
			let result = 0;
			if( states.sampleFileResults[index] ){
				result = states.sampleFileResults[index];
			}
			return ( 
				<ListItem key={file.name}>
					<ListItemText primary={file.name} secondary={Tools.FileSize(file.size)}/>
					<ListItemSecondaryAction>
						{resultIcon(result)}
					</ListItemSecondaryAction>
				</ListItem>
			);
		});
	},[props.sampleFiles,states.sampleFileResults,states.step]);

	const currentSampleFileResults = useMemo(()=>{
		return props.product.sampleFileNames.split("\t").map((name:string,index:number) => {
			if( name==="" || props.isSampleExchange ){
				return;
			}
			const key = "file"+index;
			return ( 
				<ListItem key={key}>
					<ListItemText primary={name} secondary="更新なし"/>
					<ListItemSecondaryAction>
						{resultIcon(3)}
					</ListItemSecondaryAction>
				</ListItem>
			);
		});
	},[props.product.sampleFileNames,props.isSampleExchange]);

	const imageResults = useMemo(()=>{
		let results = states.imageResults;
		if(props.images.length===0){
			return;
		}
		let num = 1;
		return props.images.map((image:ImageData,index:number)=>{
			let imageSecondary = "";
			if( image.size===0 ){
				if(image.name!==""){
					//if(image.source==="deleted"){
						//imageSecondary = "削除";
					//} else {
						imageSecondary = "更新なし";
						results[index] = 3;
					//}
				}
			} else {
				imageSecondary = Tools.FileSize(image.size);
			}
			if( imageSecondary==="" ){
				return;
			}
			const name = "イメージ画像" + String(num);
			num++;
			return (
				<ListItem>
					<ListItemText primary={name} secondary={imageSecondary}/>
					<ListItemSecondaryAction>
						{resultIcon(results[index])}
					</ListItemSecondaryAction>
				</ListItem>
			)	
		});
	},[props.images.length,states.imageResults,states.step]);

	const thumbnailResults = useMemo(()=>{
		let results = states.thumbnailResults;
		if(props.thumbnails.length===0){
			return;
		}
		return props.thumbnails.map((image:ImageData,index:number)=>{
			let imageSecondary = "";
			if( image.size===0 ){
				if(image.name!==""){
					//if(image.source==="deleted"){
						//imageSecondary = "削除";
					//} else {
						imageSecondary = "更新なし";
						results[index] = 3;
					//}
				}
			} else {
				imageSecondary = Tools.FileSize(image.size);
			}
			if( imageSecondary==="" ){
				return;
			}
			//const name = "サムネイル画像" + String(num);
			const name = "サムネイル画像";
			return (
				<ListItem>
					<ListItemText primary={name} secondary={imageSecondary}/>
					<ListItemSecondaryAction>
						{resultIcon(results[index])}
					</ListItemSecondaryAction>
				</ListItem>
			)	
		});
	},[props.thumbnails.length,states.thumbnailResults,states.step]);

	const dataResults = useMemo(()=>{
		return (
			<ListItem>
				<ListItemText primary="商品名・価格・説明など" secondary={Tools.FileSize(states.dataBytes)}/>
				<ListItemSecondaryAction>
					{resultIcon(states.dataResult)}
				</ListItemSecondaryAction>
			</ListItem>
		)
	},[states.dataResult,states.dataBytes])

	const retry = ()=>{
		ajax.current.reset();
		setStates(defaultStates);
		upload();
	}

	const abort = ()=>{
		ajax.current.abort();
	}

	const upload = async ()=>{
		let fileIds:string[] = [];
		let fileNames:string[] = [];
		let fileSizes:string[] = [];

		let serviceFileIds:string[] = [];
		let serviceFileNames:string[] = [];
		let serviceFileSizes:string[] = [];

		let sampleFileIds:string[] = [];
		let sampleFileNames:string[] = [];
		let sampleFileSizes:string[] = [];

		let productId = "";
		let error = false;

		if( props.product.id!=="" ){
			productId = props.product.id;
		}

		let progressStates = defaultStates;
		progressStates.dataBytes = states.dataBytes;

		//販売ファイルのアップロード
		let fileResults = [] as number[];
		for( let i=0; i<props.files.length; i++){
			fileResults.push(1);
			progressStates.fileResults = fileResults;
			progressStates.progress = 1;
			progressStates.step++;
			//setStates({...states,progress:1,fileResults:fileResults,dataResult:0,imageResults:[],step:i*3});
			setStates({...progressStates});
			let compressed = false;
			const ext = Tools.getExt(props.files[i].name)
			if( props.files[i].size>=CompressedProductFileSize && AvailableFileTypes[ext] ){
				compressed = true;
			}
			const res = await ajax.current.addProductFile(props.userId,productId,props.files[i],compressed,false);
			if( res.err ){
				error = true;
				fileResults[i] = 9;
				//setStates({...states,progress:1,fileResults:fileResults,dataResult:0,imageResults:[],step:i*3+1});
				progressStates.step++;
				progressStates.fileResults = fileResults;
				setStates({...progressStates});
				//console.log("err");
				break;
			}
			if( productId==="" && res.productId && res.productId!=="" ){
				productId = res.productId;	
			}
			fileIds.push( res.fileId );
			fileNames.push(props.files[i].name);
			fileSizes.push(props.files[i].size.toString());

			fileResults[i] = 2;
			//setStates({...states,progress:1,fileResults:fileResults,dataResult:0,imageResults:[],step:i*3+2});
			progressStates.step++;
			progressStates.fileResults = fileResults;
			setStates({...progressStates});
		}

		//追加ファイルのアップロード
		let serviceFileResults = [] as number[];
		for( let i=0; i<props.serviceFiles.length; i++){
			serviceFileResults.push(1);

			progressStates.step++;
			progressStates.serviceFileResults = serviceFileResults;
			setStates({...progressStates});

			//setStates({...states,progress:1,fileResults:fileResults,serviceFileResults:serviceFileResults,dataResult:0,imageResults:[],step:i*3+100});
			let compressed = false;
			const ext = Tools.getExt(props.serviceFiles[i].name)
			if( props.serviceFiles[i].size>=CompressedProductFileSize && AvailableFileTypes[ext] ){
				compressed = true;
			}
			const res = await ajax.current.addProductFile(props.userId,productId,props.serviceFiles[i],compressed,false);
			if( res.err ){
				error = true;
				serviceFileResults[i] = 9;
				//setStates({...states,progress:1,fileResults:fileResults,serviceFileResults:serviceFileResults,dataResult:0,imageResults:[],step:i*3+101});

				progressStates.step++;
				progressStates.serviceFileResults = serviceFileResults;
				setStates({...progressStates});
	
				//console.log("err");
				break;
			}
			serviceFileIds.push( res.fileId );
			serviceFileNames.push(props.serviceFiles[i].name);
			serviceFileSizes.push(props.serviceFiles[i].size.toString());

			serviceFileResults[i] = 2;
			//setStates({...states,progress:1,fileResults:fileResults,serviceFileResults:serviceFileResults,dataResult:0,imageResults:[],step:i*3+102});
			progressStates.step++;
			progressStates.serviceFileResults = serviceFileResults;
			setStates({...progressStates});
		}

		//サンプルファイルのアップロード
		let sampleFileResults = [] as number[];
		for( let i=0; i<props.sampleFiles.length; i++){
			sampleFileResults.push(1);
			//setStates({...states,progress:1,fileResults:fileResults,serviceFileResults:serviceFileResults,sampleFileResults:sampleFileResults,dataResult:0,imageResults:[],step:i*3+200});

			progressStates.step++;
			progressStates.sampleFileResults = sampleFileResults;
			setStates({...progressStates});

			let compressed = false;
			const ext = Tools.getExt(props.sampleFiles[i].name)
			if( props.sampleFiles[i].size>=CompressedProductFileSize && AvailableFileTypes[ext] ){
				compressed = true;
			}
			const res = await ajax.current.addProductFile(props.userId,productId,props.sampleFiles[i],compressed,true);
			if( res.err ){
				error = true;
				sampleFileResults[i] = 9;
				//setStates({...states,progress:1,fileResults:fileResults,serviceFileResults:serviceFileResults,sampleFileResults:sampleFileResults,dataResult:0,imageResults:[],step:i*3+201});
				progressStates.step++;
				progressStates.sampleFileResults = sampleFileResults;
				setStates({...progressStates});
	
				//console.log("err");
				break;
			}
			sampleFileIds.push( res.fileId );
			sampleFileNames.push(props.sampleFiles[i].name);
			sampleFileSizes.push(props.sampleFiles[i].size.toString());

			sampleFileResults[i] = 2;
			//setStates({...states,progress:1,fileResults:fileResults,sampleFileResults:sampleFileResults,dataResult:0,imageResults:[],step:i*3+202});
			progressStates.step++;
			progressStates.sampleFileResults = sampleFileResults;
			setStates({...progressStates});
		}


		//イメージ画像のアップロード
		let imageKeys = [];
		let imageResults = [] as number[];
		if( !error && props.images.length>0 ){
			for( let i=0; i<props.images.length; i++){
				imageResults.push(1);
				//setStates({...states,progress:1,fileResults:fileResults,serviceFileResults:serviceFileResults,sampleFileResults:sampleFileResults,imageResults:imageResults,dataResult:0,step:i*3+300});

				progressStates.step++;
				progressStates.imageResults = imageResults;
				setStates({...progressStates});
	
				//if( props.images[i].source==="deleted") {
				//	imageResults[i] = 2;
				//} else
				if( props.images[i].source==="") {
					imageResults[i] = 2;
					imageKeys.push( props.images[i].name );
				} else {
					const resImage = await ajax.current.addProductImage(props.userId,productId,props.images[i].source);
					if( resImage.result==="OK" ){
						imageResults[i] = 2;
						imageKeys.push(resImage.imageKey);
						//setStates({...states,progress:1,fileResults:fileResults,serviceFileResults:serviceFileResults,sampleFileResults:sampleFileResults,imageResults:imageResults,dataResult:0,step:i*3+301});

						progressStates.step++;
						progressStates.imageResults = imageResults;
						setStates({...progressStates});		
					} else {
						imageResults[i] = 9;
						error = true;
					}
				}
			}
		}

		//サムネイル画像のアップロード
		let thumbnailKeys = [];
		let thumbnailResults = [] as number[];
		if( !error && props.thumbnails.length>0 ){
			for( let i=0; i<props.thumbnails.length; i++){
				thumbnailResults.push(1);
				//setStates({...states,progress:1,fileResults:fileResults,serviceFileResults:serviceFileResults,sampleFileResults:sampleFileResults,imageResults:imageResults,thumbnailResults:thumbnailResults,dataResult:0,step:i*3+400});

				progressStates.step++;
				progressStates.thumbnailResults = thumbnailResults;
				setStates({...progressStates});

				//if( props.images[i].source==="deleted") {
				//	imageResults[i] = 2;
				//} else
				if( props.thumbnails[i].source==="") {
					thumbnailResults[i] = 2;
					thumbnailKeys.push( props.thumbnails[i].name );
				} else {
					const resThumbnail = await ajax.current.addProductImage(props.userId,productId,props.thumbnails[i].source);
					if( resThumbnail.result==="OK" ){
						thumbnailResults[i] = 2;
						thumbnailKeys.push(resThumbnail.imageKey);
						//setStates({...states,progress:1,fileResults:fileResults,serviceFileResults:serviceFileResults,sampleFileResults:sampleFileResults,imageResults:imageResults,thumbnailResults:thumbnailResults,dataResult:0,step:i*3+401});
						progressStates.step++;
						progressStates.thumbnailResults = thumbnailResults;
						setStates({...progressStates});	
					} else {
						thumbnailResults[i] = 9;
						error = true;
					}
				}
			}
		}

		//データのアップロード
		//let dataResult = 0;
		let svIds = serviceFileIds.join("\t");
		if( props.isServiceExchange && serviceFileIds.length===0 ){
			svIds = "deleted";
		}
		let smIds = sampleFileIds.join("\t");
		if( props.isSampleExchange && sampleFileIds.length===0 ){
			smIds = "deleted";
		}
		if( !error ){
			//setStates({...states,progress:1,fileResults:fileResults,serviceFileResults:serviceFileResults,sampleFileResults:sampleFileResults,imageResults:imageResults,thumbnailResults:thumbnailResults,dataResult:1});
			progressStates.step++;
			progressStates.dataResult=1;
			setStates({...progressStates});

			const resData = await ajax.current.updateProduct(props.userId,{...props.product,
				id:productId,
				imageKeys:imageKeys.join("\t"),
				thumbnailKey: thumbnailKeys.join("\t"),
				fileIds:fileIds.join("\t"),
				fileNames:fileNames.join("\t"),
				fileSizes:fileSizes.join("\t"),
				serviceFileIds:svIds,
				serviceFileNames:serviceFileNames.join("\t"),
				serviceFileSizes:serviceFileSizes.join("\t"),
				sampleFileIds:smIds,
				sampleFileNames:sampleFileNames.join("\t"),
				sampleFileSizes:sampleFileSizes.join("\t"),
			});
			//console.log(resData);
			if( resData.result==="OK" ){
				//dataResult = 2;
				//setStates({...states,progress:2,isError:false,newProductId:productId,fileResults:fileResults,serviceFileResults:serviceFileResults,sampleFileResults:sampleFileResults,imageResults:imageResults,thumbnailResults:thumbnailResults,dataResult:dataResult});
				progressStates.step++;
				progressStates.newProductId=productId;
				progressStates.isError=false;
				progressStates.progress=2;
				progressStates.dataResult=2;
				progressStates.permission=resData.permission;
				setStates({...progressStates});
			} else {
				progressStates.step++;
				progressStates.dataResult=9;
				error = true;
			}
		}

		//
		if( error ){
			progressStates.step++;
			progressStates.progress=9;
			progressStates.isError=true;
			setStates({...progressStates});
			//setStates({...states,progress:9,isError:true,fileResults:fileResults,serviceFileResults:serviceFileResults,imageResults:imageResults,thumbnailResults:thumbnailResults,dataResult:dataResult});
			return;
		}
	}

	useEffect(()=>{
		if( props.isOpen ){
			ajax.current.reset();

			let sum = 20;//ID
			sum += Tools.toByte(props.product.name);
			sum += Tools.toByte(props.product.price.toString());
			sum += Tools.toByte(props.product.introduction);
			sum += Tools.toByte(props.product.url);
			setStates({...defaultStates,dataBytes:sum});
		}
	},[props.isOpen]);

	if(!props.isOpen){
		return null;
	}

	return (
		<Dialog
			open={props.isOpen}
			onClose={(event, reason) => {
				if (reason !== 'backdropClick') {
					close();
				}
			}}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			disableEscapeKeyDown={true}
		>
			<DialogTitle id="alert-dialog-title">アップローダー</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					商品データをアップロードしてよろしいですか？<br/>
					よろしければ「スタート」を押してください。<br/>
					<div style={{margin:"1em 0",fontSize:"90%"}}>
					・ファイルのサイズが大きいと長時間かかる場合があります。<br/>
					・回線状態によっては途中で失敗する場合もあります。<br/>
					</div>
				</DialogContentText>
				<List dense={true} style={{backgroundColor:"#EFEFEF",marginTop:"2em"}}>
					{props.files.length>0 ? (
					<React.Fragment>{newFileResults}</React.Fragment>
					):(
					<React.Fragment>{currentFileResults}</React.Fragment>
					)}

					{props.serviceFiles.length>0 ? (
					<React.Fragment>{newServiceFileResults}</React.Fragment>
					):(
					<React.Fragment>{currentServiceFileResults}</React.Fragment>
					)}

					{props.sampleFiles.length>0 ? (
					<React.Fragment>{newSampleFileResults}</React.Fragment>
					):(
					<React.Fragment>{currentSampleFileResults}</React.Fragment>
					)}

					{imageResults}
					{thumbnailResults}
					{dataResults}
				</List>
			</DialogContent>
			{states.progress === 0 &&
			<DialogActions className={classes.dialogActions}>
				<Button onClick={close} variant="text">やめる</Button>
				<Button onClick={upload} color="primary" variant="contained">スタート</Button>
			</DialogActions>
			}
			{states.progress === 1 &&
			<Box className={classes.dialogActionsCenter}>
				<CircularProgress/>
				<Button color="error" variant="outlined" style={{position:"absolute",top:"0.5em",left:"2em"}} onClick={abort}>中断</Button>
			</Box>
			}
			{states.progress === 2 &&
			<DialogActions className={classes.dialogActions}>
				<div style={{fontSize:"80%",marginRight:"1em"}}>登録完了しました</div>
				<Button onClick={finish} color="success" variant="outlined">閉じる</Button>
			</DialogActions>
			}
			{states.progress === 9 &&
			<DialogActions className={classes.dialogActions}>
				<div style={{fontSize:"80%",color:"#666666",marginRight:"1em"}}>アップロードが失敗しました</div>
				<Button onClick={close} color="primary" variant="text">やめる</Button>
				<Button onClick={retry} color="warning" variant="contained">リトライ</Button>
			</DialogActions>
			}
		</Dialog>
	)
}

export default ProductUploader;