import React,{useState,useEffect} from 'react';
import {Link,Navigate,useParams} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import SettingsIcon from '@mui/icons-material/Settings';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import WebIcon from '@mui/icons-material/Web';
import EditIcon from '@mui/icons-material/Edit';
import StorageIcon from '@mui/icons-material/Storage';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
//import HistoryIcon from '@mui/icons-material/History';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import HelpIcon from '@mui/icons-material/Help';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import TwitterIcon from '@mui/icons-material/Twitter';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import CheckIcon from '@mui/icons-material/Check';
import BlockIcon from '@mui/icons-material/Block';
import TagIcon from '@mui/icons-material/Tag';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CopyToClipboard from 'react-copy-to-clipboard';
import LoadImage from './LoadImage';
import ProductCell from './ProductCell';
import ProductInput from './ProductInput';
import ProductRelease from './ProductRelease';
import PageTitle from './PageTitle';
import {UserContainer} from './UserContainer';
import {Inc,ImageData,ProductPublisheds} from './Inc';
import Loading from './Loading';
import ImagePager from './ImagePager';
import Ajax from './Ajax';
import YesNo from './YesNo';
import Toast from './Toast';
import Tools from './Tools';
import {useStyles} from './useStyles';

interface ProductDetailProps {
    
}

const ProductDetail:React.FC<ProductDetailProps> = ()=>{
	const classes = useStyles();
	const userState = UserContainer.useContainer();
	const defaultStates = {
		product:Inc.defaultProduct(),
		images: [] as ImageData[],
		permissionAvailable: false,
		permissionDelete: false,
		total: 0,
		isLoading: false,
		isInput:false,
		isRelease: false,
		isDelete: false,
		isSales: false,
	};
	const [states, setStates] = useState(defaultStates);
	const [getAway,setGetAway] = useState(false);
	const [anchorMenu, setAnchorMenu] = React.useState<null | HTMLElement>(null);
	const isMenu = Boolean(anchorMenu);
	const {productId} = useParams<{productId:string}>();
	const [toast,setToast] = useState('');

	const openInput = ()=>{
		setAnchorMenu(null);
		setStates({...states,isInput:true});
	}
	const closeInput = (updated:boolean)=>{
		setStates({...states,isInput:false});
		if( updated ){
			load();
		}
	}
	const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorMenu(event.currentTarget);
	};
	const closeMenu =  () => {
		setAnchorMenu(null);
	};

	const openRelease = ()=>{
		setAnchorMenu(null);
		setStates({...states,isRelease:true});
	}
	const closeRelease = (available:boolean)=>{
		//let del = states.permissionDelete;
		//if( available ){
		//	del = false;
		//}
		//setStates({...states,isRelease:false,permissionDelete:del,product:{...states.product,available:available}});
		if( states.product.available!==available ){
			load();
		} else {
			setStates({...states,isRelease:false});
		}
	}

	const openDelete = ()=>{
		setAnchorMenu(null);
		setStates({...states,isDelete:true});
	}
	const closeDelete = ()=>{
		setStates({...states,isDelete:false});
	}
	//const openSales = ()=>{
	//	setStates({...states,isSales:true});
	//}
	//const closeSales = ()=>{
	//	setStates({...states,isSales:false});
	//}

	const deleteProduct = async ()=>{
		if( !productId || !userState.user ){
			return;
		}
		const ajax = new Ajax();
		const res = await ajax.deleteProduct(userState.user.uid,productId);
		if( res.Result==="OK" ){
			setStates({...defaultStates,isDelete:false});
		}
		setGetAway(true);
	}


	const load = async ()=>{
		if( !productId || !userState.user ){
			return;
		}
		const ajax = new Ajax();
		const res = await ajax.getProduct(userState.user.uid,productId);
		if( res.result==="OK" ){
			setStates({...defaultStates,product:res.product,permissionAvailable:res.permissionAvailable,permissionDelete:res.permissionDelete,images:Inc.getProductImages(res.product.imageKeys),total:Inc.getFileSizeTotal(res.product.fileSizes)});
		} else {
			setGetAway(true);
		}
	}
	const closeToast = ()=>{
		setToast('');
	}
	const copy = ()=>{
		setToast("公開ページのURLをクリップボードにコピーしました");
		//closeMenu();
	}

	useEffect(()=>{
		if( !userState.user ){
			setGetAway(false);
			return;
		}
		load();
		window.scrollTo(0,0);
	},[userState.user]);

	if( getAway || !productId ){
		return <Navigate to='/product/list'/>
	}
	
	let labelRelease = <span>準備中</span>
	if( states.product.available ){
		labelRelease = <span>販売中</span>
	}

	const infoURL = process.env.REACT_APP_PRODUCT_URL + states.product.id;
	const tweetURL = "https://twitter.com/intent/tweet?url="+encodeURIComponent(states.product.name+"\n"+infoURL);
	const fileSizes = states.product.fileSizes.split("\t");
	const serviceFileSizes = states.product.serviceFileSizes.split("\t");
	const sampleFileSizes = states.product.sampleFileSizes.split("\t");
	const toSales = "/product/charges/" + productId;

	return (
		<div>
			<PageTitle title="商品詳細" icon="product"/>
			<Box style={{marginBottom:"0.5em",display:`flex`}}>
				<Link to="/product/list" className={classes.link}>
					<Button variant="text" sx={{ display: { xs: 'none',md: 'flex' } }}><ArrowBackIcon className={classes.buttonIcon}/> 商品リストにもどる</Button>
					<Button variant="text" sx={{ display: { md: 'none',xs: 'flex' } }}><ArrowBackIcon className={classes.buttonIcon}/> 商品リスト</Button>
				</Link>
				<Box style={{flex:`1`}}/>
				<Button variant="outlined" onClick={openMenu}><SettingsIcon className={classes.buttonIcon}/> 商品設定</Button>
			</Box>
			{states.product.locked && 
			<Box className={classes.lockedReason}><ReportProblemIcon className={classes.pageTitleIcon}/>{states.product.reason}</Box>
			}
			<Grid container spacing={0}>
				<Grid item xs={12}>
					<Box style={{marginBottom:"1em"}}><ImagePager images={states.images}/></Box>
				</Grid>
				<Grid item xs={12} sm={9} style={{paddingTop:'1em',paddingRight:'1em'}}>
					<ProductCell title="商品名" body={states.product.name} size="large"/>
					<ProductCell title="販売ファイル" body={
						<List dense={true} style={{paddingTop:"0"}}>
							{states.product.fileNames.split("\t").map((file:string,index:number)=>{
								const key = "file" + index;
								return (
							<ListItem key={key} style={{paddingLeft:"0"}}>
								<ListItemText primary={file} secondary={Tools.FileSize(parseInt(fileSizes[index]))}/>
							</ListItem>
								);
							})}
							<ListItem key="last" style={{paddingLeft:"0"}}>
								<div className={classes.fileSizeTotal}>合計　{Tools.FileSize(states.total)}</div>
							</ListItem>
						</List>}
					/>
					<ProductCell title="商品の説明" body={states.product.introduction} multiline={true}/>
					<ProductCell title="サンプルファイル" body={
						<React.Fragment>
						{states.product.sampleFileNames.length>0 ? (
						<List dense={true} style={{paddingTop:"0"}}>
							{states.product.sampleFileNames.split("\t").map((file:string,index:number)=>{
								const key = "file" + index;
								return (
							<ListItem key={key} style={{paddingLeft:"0"}}>
								<ListItemText primary={file} secondary={Tools.FileSize(parseInt(sampleFileSizes[index]))}/>
							</ListItem>
								);
							})}
						</List>
						):(
						<Box>なし</Box>
						)}
						</React.Fragment>
						}
					/>

					<ProductCell title="リンク" empty="なし" body={states.product.url}/>
					<ProductCell title="追加ファイル" body={
						<React.Fragment>
						{states.product.serviceFileNames.length>0 ? (
						<List dense={true} style={{paddingTop:"0"}}>
							{states.product.serviceFileNames.split("\t").map((file:string,index:number)=>{
								const key = "file" + index;
								return (
							<ListItem key={key} style={{paddingLeft:"0"}}>
								<ListItemText primary={file} secondary={Tools.FileSize(parseInt(serviceFileSizes[index]))}/>
							</ListItem>
								);
							})}
						</List>
						):(
						<Box>なし</Box>
						)}
						</React.Fragment>
						}
					/>
					<ProductCell title="追加ファイルの説明" empty="なし" body={states.product.aboutServiceFile} multiline={true}/>
					<ProductCell title="サムネイル画像" empty="なし" body={<LoadImage src={Inc.getUploadImageURL(states.product.thumbnailKey)} width={300}/>}/>
					<ProductCell title="検索タグ" body={<>{states.product.tags.map((tag:string,index)=>{
						const key = "tag"+index;
						if( tag==="" ){
							return (null);
						}
						return <Chip key={key} icon={<TagIcon/>} variant="outlined" label={tag}/>
					})}</>} align="left" size="medium"/>
					<ProductCell title="著作権の扱い" body={
						<React.Fragment>
							<List dense style={{paddingTop:'0'}}>
								<ListItem style={{paddingLeft:'0'}}>
									<ListItemText primary="改変" secondary="商品の一部を改変して利用（画像に文字を追加する・トリミングするなど）"/>
									{states.product.copyright1 ? (
										<span className={classes.copyrightCheck}><CheckIcon style={{verticalAlign:'bottom'}}/> 許可</span>
									):(
										<span className={classes.copyrightBlock}><BlockIcon style={{verticalAlign:'bottom',color:'red'}}/> 禁止</span>
									)}
								</ListItem>
								<ListItem style={{paddingLeft:'0'}}>
									<ListItemText primary="商用利用" secondary="利益を得る目的で利用（商用作品を作る際の素材として利用するなど）"/>
									{states.product.copyright2 ? (
										<span className={classes.copyrightCheck}><CheckIcon style={{verticalAlign:'bottom'}}/> 許可</span>
									):(
										<span className={classes.copyrightBlock}><BlockIcon style={{verticalAlign:'bottom',color:'red'}}/> 禁止</span>
									)}
								</ListItem>
								<ListItem style={{paddingLeft:'0'}}>
									<ListItemText primary="クレジット表記の省略" secondary="作者名などを表示しないで利用"/>
									{states.product.copyright3 ? (
										<span className={classes.copyrightCheck}><CheckIcon style={{verticalAlign:'bottom'}}/> 許可</span>
									):(
										<span className={classes.copyrightBlock}><BlockIcon style={{verticalAlign:'bottom',color:'red'}}/> 禁止</span>
									)}
								</ListItem>
							</List>
							<Box style={{padding:'1em',backgroundColor:'#F5F0F0',marginTop:'3em',fontSize:'90%',lineHeight:'1.5em',color:'#333333'}}>
								<h4 style={{margin:'0',padding:'0',marginBottom:'1em'}}><BlockIcon style={{verticalAlign:'middle',color:'red'}}/> 二次利用・再配布は原則として禁止です</h4>
								(例) 購入したイラストをそのまま、または一部改変したものを販売・配布する<br/>
								(例) 購入したイラストをそのまま、または一部改変したものをプリントしたＴシャツを販売する<br/>
							</Box>

						</React.Fragment>
						}
					/>
					
				</Grid>
				<Grid item xs={12} sm={3} style={{backgroundColor:'#F5F5F5',padding:'1em'}}>
					<ProductCell title="価格" body={Tools.comma(states.product.price)+"円"} align="right" size="large"/>
					<ProductCell title="グランドメニュー" body={states.product.published ? ProductPublisheds['1'] : ProductPublisheds['0']} align="right" size="medium"/>
					<ProductCell title="販売状況" body={labelRelease} align="right" size="medium"/>
					<ProductCell title="販売開始日" body={Tools.toDate(states.product.start)} align="right" size="medium"/>
					<ProductCell title="最終購入日" body={Tools.toDate(states.product.lastSale)} align="right" size="medium"/>
				</Grid>
			</Grid>
			<ProductInput isOpen={states.isInput} close={closeInput} productId={productId}/>
			<ProductRelease productId={productId} isFirst={false} close={closeRelease} isOpen={states.isRelease} available={states.product.available}/>
			<YesNo onYes={deleteProduct} onNo={closeDelete} title="商品の削除" text="この商品を削除してよろしいですか？" isOpen={states.isDelete}/>
			<Loading isOpen={states.isLoading}/>
			<Toast mes={toast} close={closeToast}/>
			<Menu
				id="product-menu"
				anchorEl={anchorMenu}
				open={isMenu}
				onClose={closeMenu}
			>
				<MenuItem onClick={openInput}><ListItemIcon><EditIcon/></ListItemIcon>編集する</MenuItem>
				<Link to={toSales} className={classes.link}><MenuItem><ListItemIcon><CreditScoreIcon/></ListItemIcon>売上明細</MenuItem></Link>
				<Divider style={{margin:'0.5em 0'}}/>
				<MenuItem disabled={!states.permissionDelete} onClick={openDelete}><ListItemIcon><DeleteIcon/></ListItemIcon>削除</MenuItem>
				<Divider/>
				<a href={infoURL} rel="noopener noreferrer" target="_blank" className={classes.link}><MenuItem onClick={closeMenu}><ListItemIcon><WebIcon/></ListItemIcon>販売ページを開く</MenuItem></a>
				<CopyToClipboard text={infoURL} onCopy={copy}>
				<MenuItem onClick={closeMenu}><ListItemIcon><FileCopyIcon/></ListItemIcon>ＵＲＬをコピー</MenuItem>
				</CopyToClipboard>
				<a href={tweetURL} rel="noopener noreferrer" target="_blank" className={classes.link}><MenuItem onClick={closeMenu}><ListItemIcon><TwitterIcon/></ListItemIcon>ＵＲＬをツイート</MenuItem></a>
				{states.product.available ? (				
				<MenuItem onClick={openRelease} disabled={states.product.locked || !states.permissionAvailable}><ListItemIcon><StorageIcon/></ListItemIcon>準備中にする</MenuItem>
				):(
				<MenuItem onClick={openRelease} disabled={states.product.locked || !states.permissionAvailable}><ListItemIcon><ShoppingCartIcon/></ListItemIcon>販売中にする</MenuItem>
				)}
				<Divider/>
				<Link to="/help/product/1" className={classes.link}><MenuItem onClick={closeMenu}><ListItemIcon><HelpIcon/></ListItemIcon>ヘルプ</MenuItem></Link>
			</Menu>
		</div>
		)
	}

export default ProductDetail;