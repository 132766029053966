import React,{useRef,useEffect,useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
//import CircularProgressWithLabel from './CircularProgressWithLabel';
import CircularProgress from '@mui/material/CircularProgress';
//import Tools from './Tools';
import Ajax from './Ajax';

interface TransferDownloadProps {
	userId:string|undefined,
	uniqueKey:string,
	fileName:string,
	close():void,
	isOpen:boolean,
}

const TransferCsvDownload:React.FC<TransferDownloadProps> = (props)=> {
	const ajax = useRef(new Ajax());
	const [error,setError] = useState("");
	const pause = useRef(false);

	const startDownload = async ()=>{
		if(!props.userId){
			return;
		}
		await ajax.current.downloadTransferCSV(props.userId,props.uniqueKey,props.fileName).then((res)=>{
			//console.log(res);
			close();
        }).catch((error)=>{
            setError("ダウンロードできませんでした");
        });
	}

	const close = ()=>{
		if( !ajax.current.isAborted() ){
			pause.current = true;
		}
		props.close();
	}

	useEffect(()=>{
		if( props.isOpen ){
			pause.current = false;
			setError("");
			startDownload();
		}
	},[props.isOpen])	

	return (
		<Dialog
		open={props.isOpen}
		onClose={props.close}
		aria-labelledby="yesno-dialog-title"
		aria-describedby="yesno-dialog-description"
		>
		<DialogTitle id="yesno-dialog-title">ＣＳＶダウンロード</DialogTitle>
		{error === '' ? (
		<DialogContent>
			<div style={{textAlign:'center',marginBottom:'1em'}}>
				<CircularProgress/>
			</div>
			<DialogContentText id="downloading-dialog-description">
				しばらくお待ちください
			</DialogContentText>
		</DialogContent>
		):(
		<DialogContent>
			<DialogContentText id="downloading-dialog-description">
				{error}
			</DialogContentText>
		</DialogContent>
		)}
		<DialogActions>
			<Button onClick={close} variant="contained" color="primary">やめる</Button>
		</DialogActions>
		</Dialog>
	);
}

export default TransferCsvDownload;