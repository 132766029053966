import React from 'react';
import PageTitle from './PageTitle';
interface GuideProps {
    
}

const Privacy:React.FC<GuideProps> = (props)=> {
	return (
		<React.Fragment>
			<PageTitle title="個人情報保護方針" icon="privacy"/>

			<div className="termLead">
				有限会社レトロインクは、お客様からお預かりした個人情報の重要性を認識し、その保護の徹底をはかり、お客様の信頼を得るために、個人情報に関する関係法令および規範を遵守するとともに、個人情報保護方針を定め確実な履行に努めることを宣言いたします。<br/>
            </div>
			<dl className="document">
				<dt>個人情報保護方針の適用範囲</dt>
				<dd>当社の個人情報保護方針は、当社の運営するウェブサービスをお客様が利用する際に適用されます。</dd>
				<dt>個人情報の利用目的</dt>
				<dd>
					以下に定める利用目的の達成に必要な範囲内でのみ個人情報を利用します。
					<ol>
						<li>ご本人様およびご契約内容の確認、ご利用料金の請求、当社のサービスの運用に必要な通知、 その他当社サービス提供に係わること</li>
						<li>お客様からのご要望・ご意見および、その応対の品質向上のための対応履歴を確認すること</li>
						<li>当社のサービスの改善・開発のため、取得した閲覧履歴や購買履歴を分析すること</li>
					</ol>
				</dd>
				<dt>個人情報の管理</dt>
				<dd>個人情報の管理は当社だけが行い、当社の関連会社に対しても情報を供給することはいたしません。</dd>
				<dt>個人情報の委託</dt>
				<dd>弊社の業務を外部に委託する際、委託先に個人情報を提供することはありません。</dd>
				<dt>個人情報の収集</dt>
				<dd>個人情報を収集する場合は、あらかじめその目的・利用内容をお知らせし、お客さまの意志で同意・登録をいただくものとします。</dd>
				<dt>個人情報の第三者提供</dt>
				<dd>
					ご提供いただいた個人情報は、以下のいずれかに該当する場合を除き、第三者に対して開示いたしません。
					<ul>
						<li>ご本人の同意がある場合</li>
						<li>人の生命、身体または財産の保護のために必要な場合であって、ご本人のご同意をいただくことが困難な場合</li>
						<li>法令に基づき開示することが必要である場合および裁判所、警察等公的機関から開示を求められた場合</li>
					</ul>
				</dd>
				<dt>個人情報の取扱いについて</dt>
				<dd>当社は、お客様の個人情報を管理する際は管理責任者をおき、適切な管理を行うとともに、お客様の個人情報を正確かつ最新の状態に保ち、個人情報への不正アクセス、紛失、破損、改ざん、漏洩などを防止するため、セキュリティシステムの維持、社内管理体制、システムの整備、社員教育の徹底等必要な措置を講じ、安全対策を実施し個人情報の厳重な管理を行います。<br />また、個人情報に係るデータベース等へのアクセスについてはアクセス権を有する者を限定し、社内においても不正な利用がおこらないように厳重に管理します。</dd>
				<dt>個人情報の残存期間</dt>
				<dd>サービスの利用契約が終了し、当社所定の保存期間が経過した時点で、利用者の個人情報を消去するものとします。ただし、法令の規定および個人を特定できない情報として保有する統計データ等は除きます。</dd>
				<dt>個人情報の開示等の請求</dt>
				<dd>
					ご本人様または代理人は利用目的の通知・開示、内容の訂正・追加・削除、利用の停止・消去及び第三者への提供の停止（以下「開示等」といいます）を求めることができます。
					<ul>
						<li>開示等の請求を受けた場合は、当社の規定に従ってすみやかに対応します。</li>
						<li>開示等の手続きにあたり、ご本人であることまたは代理人であることの確認をします。</li>
						<li>請求方法はお問い合わせフォームよりお問い合わせください。</li>
					</ul>
					<br/>
					以下のいずれかに該当する場合、開示は差し控え、理由を付記して通知します。<br/>
					<ol>
						<li>本人または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合</li>
						<li>業務の適正な実施に著しい支障を及ぼすおそれがある場合</li>
						<li>法令に違反することとなる場合</li>
					</ol>
				</dd>
				<dt>個人関連情報の取扱い</dt>
				<dd>当社は、サービスの改善、お客様の特定、広告の効果測定およびその他付随する目的のために、第三者が運営するトラッキングシステム等（Cookie等）にて収集されたお客様の行動履歴、ログ情報等の個人関連情報を取得しています。</dd>
				<dt>お客様の責任について</dt>
				<dd>当社のコンテンツのご利用は、お客さまの責任において行われるものとし、日本国の法律の条例に拘束されることに同意するものとします。また当社は、当サイト上で、お客さまの環境において当サイトのコンテンツが適切であるか否かについては、如何なる表明または保証も行いません。</dd>
				<dt>個人情報保護管理者</dt>
				<dd>有限会社レトロインク 代表取締役 石村寛之</dd>
				<dt>この個人情報保護方針の改定について</dt>
				<dd>今後、当社は個人情報保護方針の全部または一部を改定することがあります。重要な変更がある場合には、当ホームページ上において、分かりやすい方法でお知らせいたします。</dd>
			</dl>
			<div>
				2005.7.26<br />
				2022.3.30 改定<br/>
				403adamski/有限会社レトロインク
			</div>
		</React.Fragment>
	)
}

export default Privacy;