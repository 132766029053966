import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
//import IconButton from '@mui/material/IconButton';
//import Tooltip from '@mui/material/Tooltip';
//import CancelIcon from '@mui/icons-material/Cancel';

interface YesNoProps {
	onYes():void,
	onNo():void,
	title?:String,
	text:String,
	isOpen:boolean,
}

const YesNo:React.FC<YesNoProps> = (props)=> {
  return (
	<Dialog
	open={props.isOpen}
	onClose={props.onNo}
	aria-labelledby="yesno-dialog-title"
	aria-describedby="yesno-dialog-description"
	>
	<DialogTitle id="yesno-dialog-title">{props.title}</DialogTitle>
	<DialogContent>
		<DialogContentText id="yesno-dialog-description">
			{props.text}
		</DialogContentText>
	</DialogContent>
	<DialogActions>
		<Button onClick={props.onNo} color="primary">
		いいえ
		</Button>
		<Button onClick={props.onYes} color="primary" variant="contained" autoFocus>
		はい
		</Button>
	</DialogActions>
	</Dialog>
  );
}

export default YesNo;