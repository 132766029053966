import React,{useState,useEffect} from 'react';
import {Link,useParams} from 'react-router-dom';
import Button from '@mui/material/Button';
//import Dialog from '@mui/material/Dialog';
//import DialogActions from '@mui/material/DialogActions';
//import DialogContent from '@mui/material/DialogContent';
//import DialogContentText from '@mui/material/DialogContentText';
//import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
//import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
//import IconButton from '@mui/material/IconButton';
//import ForwardIcon from '@mui/icons-material/Forward';
//import ClearIcon from '@mui/icons-material/Clear';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Ajax from './Ajax';
import PageTitle from './PageTitle';
import {ChargeRow} from './ChargeRow';
import {Charge} from './Inc';
import {UserContainer} from './UserContainer';
import {useStyles} from './useStyles';

interface ProductChargesProps {

}

const ProductCharges:React.FC<ProductChargesProps> = (props)=> {
	const userState = UserContainer.useContainer();
	const [states,setStates] = useState({
		list: [] as Charge[],
		isLoading: true,
		all: 0,
	});
	const [search,setSearch] = useState({
		productName: "",
		profileName: "",
		productId: "",
		status: "",
		size: 10,
		page: 0,
		order: "id",
		asc: "desc",
	});
	const {productId} = useParams<{productId:string}>();
	const classes = useStyles();

	const load = async (page:number,size:number)=>{
		if(!userState.user || !productId){
			return;
		}
		const ajax = new Ajax();
		const newSearch = {...search,page:page,size:size,productId:productId};
		const res = await ajax.getCharges(userState.user.uid,newSearch);
		if( !res.err ){
			setStates({...states,list:res.list,all:res.all,isLoading:false});
			setSearch(newSearch);
		}
	}
	const onPageChange = (event: unknown, newPage: number) => {
		load(newPage,search.size);
	}
	const onPageSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		load(search.page,parseInt(event.target.value, 10));
	}
	useEffect(()=>{
		load(0,search.size);
	},[userState.user]);

	const toDetail = "/product/detail/"+productId;

	return (
		<div>
			<PageTitle title="売上明細（商品別）" icon="charge"/>
			<Box style={{marginBottom:"0.5em",display:`flex`}}>
				<Link to={toDetail} className={classes.link}><Button variant="text" sx={{ display: { xs: 'none',md: 'flex' } }}><ArrowBackIcon className={classes.buttonIcon}/> 商品詳細にもどる</Button></Link>
				<Box style={{flex:'1'}}></Box>
			</Box>
			{states.isLoading ? (
			<Box style={{width: '100%'}}>
				<CircularProgress/>
			</Box>
			):(
			<Box style={{width: '100%'}}>
				<TableContainer>
					<Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
						<TableHead style={{borderTop:'2px solid #DDDDDD'}}>
							<TableRow>
								<TableCell align="center" style={{whiteSpace:'nowrap'}}>売上日時</TableCell>
								<TableCell align="center" style={{whiteSpace:'nowrap'}}>商品名</TableCell>
								<TableCell align="center" style={{whiteSpace:'nowrap'}}>価格</TableCell>
								<TableCell align="center" style={{whiteSpace:'nowrap'}}>状態</TableCell>
								<TableCell align="center" style={{whiteSpace:'nowrap'}}>　</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
						{states.list.map((charge:Charge,index:number)=>{
							const key = "charge" + index;
							return <ChargeRow key={key} charge={charge}/>
						})}
						</TableBody>
					</Table>
				</TableContainer>
				{states.all>0 ? (
				<TablePagination
					rowsPerPageOptions={[1, 10,25,50,100]}
					component="div"
					count={states.all}
					rowsPerPage={search.size}
					page={search.page}
					onPageChange={onPageChange}
					onRowsPerPageChange={onPageSizeChange}
				/>
				):(
				<Box className="noRecord">売上はありません</Box>
				)}
			</Box>
			)}
		</div>
	);
}

export default ProductCharges;