import React,{useState,useEffect,useCallback,useMemo,useRef} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
//import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
//import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import CircularProgress from '@mui/material/CircularProgress';
//import FormControl from '@mui/material/FormControl';
import DoneIcon from '@mui/icons-material/Done';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import CloseIcon from '@mui/icons-material/Close';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Ajax from './Ajax';
import {Profile,ImageData} from './Inc';
import Tools from './Tools';
import {useStyles} from './useStyles';

interface ProfileUploaderProps {
    isOpen: boolean,
	close():void,
	finish():void,
	userId: string,
	profile: Profile,
	images: ImageData[],
}

const ProfileUploader:React.FC<ProfileUploaderProps> = (props)=> {
	const classes = useStyles();
	const defaultStates = {
		imageResults: [] as number[],
		dataResult: 0,
		dataBytes: 0,
		progress: 0,//0:処理前　1:処理中　2:完了　9:失敗
		isError: false,
		step: 0,
	};
	const ajax = useRef(new Ajax);
	const [states, setStates] = useState(defaultStates);

	const close = ()=>{
		props.close();
	}
	const finish = ()=>{
		props.finish();
	}
	const resultIcon = useCallback((result)=>{
		if( result===9 ){
			return <CloseIcon style={{color:"#FF0000"}}/>;
		} else if( result===1 ){
			return <HourglassFullIcon className={classes.rotate} style={{color:"#a0d8ef"}}/>;
		} else if( result===2 ){
			return <DoneIcon style={{color:"#1565C0"}}/>;
		} else if( result===3 ){
			return <ArrowDownwardIcon style={{color:"#CCCCCC"}}/>;
		} else {
			return <FileUploadIcon style={{color:"#999999"}}/>;
		}
	},[]);

	const imageResults = useMemo(()=>{
		let results = states.imageResults;
		if(props.images.length===0){
			return;
		}
		return props.images.map((image:ImageData,index:number)=>{
			const key = "image"+index;
			let imageSecondary = "";
			if( image.size===0 ){
				if(image.name!==""){
					imageSecondary = "更新なし";
					results[index] = 3;
				}
			} else {
				imageSecondary = Tools.FileSize(image.size);
			}
			if( imageSecondary==="" ){
				return;
			}
			const name = "アバター画像";
			return (
				<ListItem key={key}>
					<ListItemText primary={name} secondary={imageSecondary}/>
					<ListItemSecondaryAction>
						{resultIcon(results[index])}
					</ListItemSecondaryAction>
				</ListItem>
			)	
		});
	},[props.images.length,states.imageResults,states.step]);

	const dataResults = useMemo(()=>{
		return (
			<ListItem>
				<ListItemText primary="名前・自己紹介など" secondary={Tools.FileSize(states.dataBytes)}/>
				<ListItemSecondaryAction>
					{resultIcon(states.dataResult)}
				</ListItemSecondaryAction>
			</ListItem>
		)
	},[states.dataResult,states.dataBytes])

	const retry = ()=>{
		ajax.current.reset();
		setStates(defaultStates);
		upload();
	}

	const abort = ()=>{
		ajax.current.abort();
	}

	const upload = async ()=>{
		let error = false;

		//イメージ画像のアップロード
		let imageKeys = [];
		let imageResults = [] as number[];
		if( !error && props.images.length>0 ){
			for( let i=0; i<props.images.length; i++){
				imageResults.push(1);
				setStates({...states,progress:1,imageResults:imageResults,dataResult:0,step:i*3});
				if( props.images[i].source==="") {
					imageResults[i] = 2;
					imageKeys.push( props.images[i].name );
				} else {
					const resImage = await ajax.current.addProfileImage(props.userId,props.images[i].source);
					if( resImage.result==="OK" ){
						imageResults[i] = 2;
						imageKeys.push(resImage.imageKey);
						setStates({...states,progress:1,imageResults:imageResults,dataResult:0,step:i*3+1});
					} else {
						imageResults[i] = 9;
						error = true;
					}
				}
			}
		}

		//データのアップロード
		let dataResult = 0;
		if( !error ){
			setStates({...states,progress:1,imageResults:imageResults,dataResult:1});
			const resData = await ajax.current.updateProfile(props.userId,{...props.profile,
				imageKey:imageKeys[0],
			});
			if( resData.result==="OK" ){
				dataResult = 2;
				setStates({...states,progress:2,isError:false,imageResults:imageResults,dataResult:dataResult});
			} else {
				dataResult = 9;
				error = true;
			}
		}

		//
		if( error ){
			setStates({...states,progress:9,isError:true,imageResults:imageResults,dataResult:dataResult});
			return;
		}
	}

	useEffect(()=>{
		if( props.isOpen ){
			ajax.current.reset();

			let sum = 20;//ID
			sum += Tools.toByte(props.profile.name);
			sum += Tools.toByte(props.profile.story);
			sum += Tools.toByte(props.profile.twitter);
			sum += Tools.toByte(props.profile.facebook);
			sum += Tools.toByte(props.profile.instagram);
			sum += Tools.toByte(props.profile.youtube);
			sum += Tools.toByte(props.profile.webSite);
			setStates({...defaultStates,dataBytes:sum});
		}
	},[props.isOpen]);

	return (
		<Dialog
			open={props.isOpen}
			onClose={(event, reason) => {
				if (reason !== 'backdropClick') {
					close();
				}
			}}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			disableEscapeKeyDown={true}
		>
			<DialogTitle id="alert-dialog-title">アップローダー</DialogTitle>
			<DialogContent>
				<Box>
					プロフィールデータをアップロードしてよろしいですか？<br/>
					よろしければ「スタート」を押してください。<br/>
					<Box style={{margin:"1em 0",fontSize:"90%"}}>
					・画像サイズが大きいと長時間かかる場合があります。<br/>
					・回線状態によっては途中で失敗する場合もあります。<br/>
					</Box>
				</Box>
				<List dense={true} style={{backgroundColor:"#EFEFEF",marginTop:"2em"}}>
					{imageResults}
					{dataResults}
				</List>
			</DialogContent>
			{states.progress === 0 &&
			<DialogActions className={classes.dialogActions}>
				<Button onClick={close} variant="text">やめる</Button>
				<Button onClick={upload} color="primary" variant="contained">スタート</Button>
			</DialogActions>
			}
			{states.progress === 1 &&
			<Box className={classes.dialogActionsCenter}>
				<CircularProgress/>
				<Button color="error" variant="outlined" style={{position:"absolute",top:"0.5em",left:"2em"}} onClick={abort}>中断</Button>
			</Box>
			}
			{states.progress === 2 &&
			<DialogActions className={classes.dialogActions}>
				<div style={{fontSize:"80%",marginRight:"1em"}}>登録完了しました</div>
				<Button onClick={finish} color="success" variant="outlined">閉じる</Button>
			</DialogActions>
			}
			{states.progress === 9 &&
			<DialogActions className={classes.dialogActions}>
				<div style={{fontSize:"80%",color:"#666666",marginRight:"1em"}}>アップロードが失敗しました</div>
				<Button onClick={close} color="primary" variant="text">やめる</Button>
				<Button onClick={retry} color="warning" variant="contained">リトライ</Button>
			</DialogActions>
			}
		</Dialog>
	)
}

export default ProfileUploader;