import React from 'react';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import Divider from '@mui/material/Divider';
import Multilines from './Multilines';
import {useStyles} from './useStyles';

interface ProductCellProps {
    title:string,
	body:any,
	empty?:string,
	align?:"right"|"center"|"left",
	multiline?:boolean,
	size?:"large"|"medium"|"small",
}

const ProductCell:React.FC<ProductCellProps> = (props)=>{
	const classes = useStyles();

	let v = props.body;
	if( props.empty ){
		if( v===null || v==="" ){
			v = props.empty;
		}
	}
	let align:"right"|"center"|"left" = "left";
	if( props.align ){
		align = props.align;
	}
	let size:"80%"|"100%"|"160%" = "100%";
	if( props.size ){
		if( props.size==="small" ){
			size = "80%";
		}
		if( props.size==="large" ){
			size = "160%";
		}
	}
	
	return (
		<Box style={{marginBottom:"2em"}}>
			<FormLabel>{props.title}</FormLabel>
			{props.multiline ? (
			<Box className={classes.productValue} style={{textAlign:`${align}`,fontSize:`${size}`,lineHeight:"1.8em"}}>
				<Multilines text={v}/>
			</Box>
			):(
			<Box className={classes.productValue} style={{textAlign:`${align}`,fontSize:`${size}`}}>
				{v}				
			</Box>
			)}
			<Divider/>
		</Box>
	)
}

export default ProductCell;