import React,{useState,useMemo} from 'react';
import { useDropzone } from 'react-dropzone';
//import Button from '@mui/material/Button';
import FormLabel from '@mui/material/FormLabel';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ErrorIcon from '@mui/icons-material/PriorityHigh';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Tools from './Tools';
import {useStyles} from './useStyles';

interface FileUploadProps {
	title:string,
	maxFileSize:number,
	maxFiles:number,
	maxFileNameLength?:number,
	message?:string,
	error?:boolean,
	availableTypes?:string[],
	onUpdate(files:File[],hasError:boolean):void,
}

const FileDrop:React.FC<FileUploadProps> = (props)=> {
	const classes = useStyles();
	const [ver,setVer] = useState(0);
	const [errors,setErrors] = useState([] as string[]);

	const mimes:{[key:string]:string[];} = {
		'txt':['text/plain', "text/x-Algol68"],
		'jpg':['image/jpg','image/jpeg'],
		'png':['image/png'],
		'gif':['image/gif'],
		'docx':['application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
		'xlsx':['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
		'pdf':['application/pdf'],
		'epub':['application/epub+zip','application/zip'],
		'ai':['application/pdf','application/postscript'],
		'psd':['image/vnd.adobe.photoshop'],
		'mpg':['video/mpeg'],
		'mpeg':['video/mpeg'],
		'mp4':['video/mp4'],
		'mp3':['audio/mpeg'],
		'wav': ['audio/x-wav','audio/wav'],
		'aiff': ['audio/x-aiff','audio/aiff'],
		'aac':['audio/aac','audio/vnd.dlna.adts','audio/x-hx-aac-adts'],
		'flac':['audio/x-flac','audio/flac'],
		'm4a':['audio/aac','audio/aacp','audio/3gpp','audio/3gpp2','audio/mp4','audio/MP4A-LATM','audio/mpeg4-generic','audio/x-m4a'],
		'm4r':['audio/mp4','application/mp4','audio/x-m4a','audio/x-m4r'],
		'otf':['font/otf','application/vnd.ms-opentype'],
		'ttf':['font/ttf','application/font-sfnt'],
	}


	const reset = (file:File)=>{
		acceptedFiles.splice(acceptedFiles.indexOf(file), 1)
		setVer(ver+1);
		const hasError = checkError(acceptedFiles);
		props.onUpdate(acceptedFiles,hasError);
	}
	//const resetAll = ()=>{
	//	acceptedFiles.length = 0
	//	acceptedFiles.splice(0, acceptedFiles.length);
	//	if( inputRef.current ){
	//		inputRef.current.value = '';
	//	}
	//	setVer(ver+1);
	//	props.onUpdate(acceptedFiles,false);
	//}
	const accepted = ()=>{
		console.log("accepted");
	}
	const rejected = ()=>{
		console.log("rejected");
	}
	const checkError = (files:File[])=>{
		let errors:string[] = [];
		let hasError = false;
		for( let i=0; i<files.length; i++ ){
			const file = files[i];
			errors[i] = "";
			if( props.maxFileNameLength ){
				if(Tools.toByte(file.name)>props.maxFileNameLength){
					errors[i] = "ファイル名が長すぎます";
					hasError = true;
				}
				if(Tools.hasZen(file.name)){
					errors[i] = "ファイル名に全角文字が含まれています";
					hasError = true;
				}
				if(Tools.hasHankana(file.name)){
					errors[i] = "ファイル名に半角カナが含まれています";
					hasError = true;
				}
			}
			if( props.availableTypes ){
				const ext = Tools.getExt(file.name);
				if( !props.availableTypes.includes(ext) ){
					errors[i] = "アップロードできないファイルタイプです";
					hasError = true;
				} else if( !mimes[ext] ){
					errors[i] = "アップロードできないファイルタイプです";
					hasError = true;
				} else {
					//mimetypeはファイルの拡張子で判断されるため、拡張子を改変されたファイルの判定はここではできない
					if( file.type!=='' && !mimes[ext].includes(file.type) ){
						//errors[i] = "アップロードできないファイルタイプです:["+file.type+"]"+ext;
						errors[i] = "アップロードできないファイルタイプです";
						hasError = true;	
					}
				}
			}
			if(file.size>props.maxFileSize){
				errors[i] = "ファイルサイズが大きすぎます";
				hasError = true;
			}
			if( errors[i]!=="" ){
				console.log("error:"+errors[i]);
			}
		}
		//console.log(errors);
		setErrors(errors);
		return hasError;
	}
    const onDrop = (acceptedFiles:File[]) => {
		const hasError = checkError(acceptedFiles);
		props.onUpdate(acceptedFiles,hasError);
        //console.log('acceptedFiles:', acceptedFiles);
    };

	let multiple = true;
	if(props.maxFiles===1){
		multiple = false;
	}

    const { getRootProps, getInputProps, isDragActive, open, acceptedFiles, inputRef } = useDropzone({noClick:true,onDrop:onDrop,maxFiles:props.maxFiles,multiple:multiple,onDropAccepted:accepted,onDropRejected:rejected});

    const files = useMemo(() =>
		acceptedFiles.map((file,index:number) => {
			let classPrimary = "";
			let classSecondary = "";
			return (
            <ListItem key={file.name}>
				{errors[index]!=="" &&
				<ListItemAvatar><Avatar style={{backgroundColor:'#FF3333'}}><ErrorIcon/></Avatar></ListItemAvatar>
				}
				<ListItemText primary={file.name} secondary={
					<React.Fragment>
						{Tools.FileSize(file.size)}<br/>
						<span style={{color:'#FF3333',fontSize:'90%'}}>{errors[index]}</span>
					</React.Fragment>
				} classes={{primary:`${classPrimary}`,secondary:`${classSecondary}`}}/>
				<ListItemSecondaryAction>
					<Tooltip title="このファイルを削除"><IconButton size="small" onClick={()=>reset(file)} style={{marginLeft:'0.5em'}}><DeleteIcon/></IconButton></Tooltip>
				</ListItemSecondaryAction>
            </ListItem>
			)
		}
    ), [acceptedFiles,errors,ver]);

	const total = useMemo(()=>{
		let total = 0;
		for( let i=0; i<acceptedFiles.length; i++ ){
			total += acceptedFiles[i].size;
		}
		return total;
	},[acceptedFiles,ver]);

	let cl = classes.formDropzone;
	if( props.error ){
		cl = cl + " " + classes.formDropzoneError;
	}

	return (
    	<div>
		{acceptedFiles.length===0 ? (
			<div {...getRootProps()} className={cl}>
				<FormLabel className={classes.formLabel+' '+classes.formInner}>{props.title}</FormLabel>
				<input {...getInputProps()} />
				<p style={{textAlign:'center',padding:'1em 0 0 0'}}>
					<IconButton onClick={open}><UploadFileIcon style={{fontSize:'280%',color:"#999999"}}/></IconButton>
				</p>
				{props.message && props.message!=="" &&
				<p style={{textAlign:'center',fontSize:'80%',padding:'0em 1em 1.5em 1em',color:'#666666'}}>
					{props.message}
				</p>
				}
			</div>
		):(
			<div {...getRootProps()} className={cl}>
				<FormLabel className={classes.formLabel+' '+classes.formInnerSmall}>{props.title}</FormLabel>
				<List dense={true}>
					{files}
					<ListItem key="last" style={{paddingLeft:"0"}}>
						<div className={classes.fileSizeTotal}>合計　{Tools.FileSize(total)}</div>
					</ListItem>
				</List>
			</div>
		)}
    	</div>
	);
}

export default FileDrop;