import React,{useEffect,useState} from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
//import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import {Inc,TransferFee} from './Inc';
import Tools from './Tools';
import Ajax from './Ajax';
import {UserContainer} from './UserContainer';

interface BalanceProps {
	close():void,
	isOpen:boolean,
}

const Balance:React.FC<BalanceProps> = (props)=> {
	const userState = UserContainer.useContainer();
	const [states,setStates] = useState({
		payment: Inc.defaultPayment(),
		amount: 0,
		fee: 0,
		nextDate: '未定',
		prevDate: 'なし',
		carryOvers: 0,
		isLoading: true,
	})

	const load = async ()=>{
		if(!userState.user){
			return;
		}
		setStates({...states,isLoading:true});
		const ajax = new Ajax();
		const res = await ajax.getBalance(userState.user.uid);
		//console.log(res);
		if( !res.err ){
			setStates({...states,amount:res.amount,fee:res.fee,nextDate:res.nextDate,prevDate:res.prevDate,carryOvers:res.carryOvers,isLoading:false});
		}
	}

	useEffect(()=>{
		if( !props.isOpen ){
			return;
		}
		load();
	},[props.isOpen])

	return (
		<Dialog
			open={props.isOpen}
			onClose={props.close}
			aria-labelledby="yesno-dialog-title"
			aria-describedby="yesno-dialog-description"
			fullWidth={true}
		>
			<DialogTitle id="alert-dialog-title">売上残高</DialogTitle>
			<DialogContent>
				{states.isLoading ? (
				<Box style={{textAlign:'center',padding:'2em 0'}}>
					<CircularProgress/>
				</Box>
				):(
				<Box style={{maxWidth:'600px',width:'100%'}}>
					<div className="chargeDetailRow chargeDetailRowLarge">
						<h3>手数料差引後の合計<span>（入金日未定）</span></h3>
						<div>{Tools.comma(states.amount-states.fee)}円</div>
					</div>
					<div className="chargeDetailRow">
						<h3>最低振込金額</h3>
						<div>{Tools.comma(states.payment.minAmount)}円+{TransferFee}円</div>
					</div>
					<div className="chargeDetailRow">
						<h3>次回入金日</h3>
						{(states.nextDate==='' && states.payment.minAmount>states.amount) &&
						<div>あと{Tools.comma(states.payment.minAmount+TransferFee-states.amount)}円で入金可能</div>
						}
						{(states.nextDate==='' && states.payment.minAmount<=states.amount) &&
						<div>来月入金見込み</div>
						}
						{states.nextDate!=='' &&
						<div>{Tools.toDate(states.nextDate)}</div>
						}
					</div>
					<div className="chargeDetailRow">
						<h3>前回入金日</h3>
						{states.prevDate==='' ? (
						<div>なし</div>
						):(
						<div>{Tools.toDate(states.prevDate)}</div>
						)}
					</div>
					<div className="chargeDetailRow">
						<h3>繰越回数</h3>
						<div>{states.carryOvers}回</div>
					</div>
				</Box>
				)}
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" color="primary" onClick={props.close}>
					閉じる
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default Balance;