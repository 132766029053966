import React,{useEffect,useState} from 'react';
import {Link,Navigate,useParams} from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
//import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Ajax from './Ajax';

interface PaymentActivateProps {
	
}

const PaymentActivate:React.FC<PaymentActivateProps> = (props)=> {
	const {checkKey} = useParams<{checkKey:string}>();
	const [status,setStatus] = useState(0);
	const close = ()=>{

	}
	const check = async ()=>{
		if(!checkKey){
			return;
		}
		const ajax = new Ajax();
		const res = await ajax.activatePayment(checkKey);
		if( res.result==="OK" ){
			setStatus(1);
		} else {
			setStatus(9);
		}
	}
	useEffect(()=>{
		if( !checkKey || checkKey==='' ){
			return;
		}
		check();

	},[checkKey]);

	if( !checkKey || checkKey==='' ){
		return <Navigate to='/'/>
	}

	if( status===0 ){
		return (
			<div style={{margin:'2em 0',textAlign:'center'}}>
				<div style={{marginBottom:'1em'}}>
					<CircularProgress/>
				</div>
				<div>
					確認中です
				</div>
			</div>
		)
	} else if( status===9 ){
		return (
			<Dialog
				open={true}
				onClose={close}
				aria-labelledby="yesno-dialog-title"
				aria-describedby="yesno-dialog-description"
			>
				<DialogTitle id="yesno-dialog-title">無効なURLです</DialogTitle>
				<DialogContent>
					このURLは現在ご利用になれません。<br/>
					URLが間違っているか、すでに確認が済んだURLです。<br/>
				</DialogContent>
				<DialogActions>
					<Link to='/'><Button color="primary" variant="outlined">
						閉じる
					</Button></Link>
				</DialogActions>
			</Dialog>
		)
	} else {
		return (
			<Dialog
				open={true}
				onClose={close}
				aria-labelledby="yesno-dialog-title"
				aria-describedby="yesno-dialog-description"
			>
				<DialogTitle id="yesno-dialog-title">メールの確認が完了しました</DialogTitle>
				<DialogContent>
					メールアドレスに異常はないようです。<br/>
					引き続きご利用いただけます。<br/>
				</DialogContent>
				<DialogActions>
					<Link to='/profile/detail'><Button color="primary" variant="outlined">
						ＯＫ
					</Button></Link>
				</DialogActions>
			</Dialog>
		)
	}
}

export default PaymentActivate;