import React,{useState,useEffect} from 'react';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
//import DialogActions from '@mui/material/DialogActions';
//import DialogContentText from '@mui/material/DialogContentText';
//import DialogTitle from '@mui/material/DialogTitle';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import WebIcon from '@mui/icons-material/Web';
import ImageAvatar from './ImageAvatar';
import ImageSelect from './ImageSelect';
import ProfileUploader from './ProfileUploader';
import Toast from './Toast';
import Ajax from './Ajax';
import YesNo from './YesNo';
import InputField from './InputField';
import Loading from './Loading';
import {Inc,Profile,ImageData,MaxProfileNameLength,MaxProfileStoryLength,MaxProfileImageSize,MaxProfileImageWidth,MaxProfileSNSLength,MaxProfileWebSiteLength} from './Inc';
import {UserContainer} from './UserContainer';
//import {useStyles} from './useStyles';
import Tools from './Tools';

interface ProfileInputProps {
    isOpen:boolean,
	close(updated:boolean):void,
	userId:string,
}

const ProfileInput:React.FC<ProfileInputProps> = (props)=> {
	const userState = UserContainer.useContainer();
	//const classes = useStyles();
	const defaultStates = {
		profile:Inc.defaultProfile(),
		error:Inc.defaultProfileError(),
		images: [] as ImageData[],
		isWaiting: false,
		isLoading: false,
		isUploader: false,
		isUpdated: false,
	};
	const [states, setStates] = useState(defaultStates);
	const [toast,setToast] = useState('');
	const [isExit,setIsExit] = useState(false);
	
	const onChangeValue = (name:keyof Profile) => (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!event) {
			return;
		}
		let value = event.target.value;

		if( name==='twitter' || name==='instagram' || name==='facebook' ){
			if( value==='@' ){
				value = '';
			}
		}

		setStates({ 
			...states,
			isUpdated:true,
			profile:{...states.profile,[name]:value}
		});
	};

	const setImages = (images:ImageData[])=>{
		//console.log("setImages:"+images.length);
		setStates({...states,isUpdated:true,images:images});
	}
	const cancelImage = ()=>{
		setStates({...states,images:[],isUpdated:true});
	}

	const exitYes = ()=>{
		setIsExit(false);
		props.close(false);
	}

	const exitNo = ()=>{
		setIsExit(false);
	}
	const close = ()=>{
		props.close(false);
	}
	const validate = async ()=>{
		//console.log("validate");
		if( !userState.user ){
			//console.log("no user");
			return;
		}
		setStates({...states,isWaiting:true});

		let err:boolean = false;
		let error = Inc.defaultProfileError();

		const name = states.profile.name.trim();
		if( name==="" ){
			error["name"] = "作家名を入力してください";
			err = true;
		} else if( name.length>MaxProfileNameLength ){
			error["name"] = "作家名を"+MaxProfileNameLength+"文字以内にしてください";
			err = true;
		}

		const story = states.profile.story.trim();
		if( story.length>MaxProfileStoryLength ){
			error["story"] = "自己紹介を"+MaxProfileStoryLength+"文字以内にしてください";
			err = true;
		}

		const twitter = states.profile.twitter.trim();
		if( twitter.length>MaxProfileSNSLength ){
			error["twitter"] = "ツイッターのアカウントを"+MaxProfileSNSLength+"文字以内にしてください";
			err = true;
		}

		const facebook = states.profile.facebook.trim();
		if( facebook.length>MaxProfileSNSLength ){
			error["facebook"] = "フェイスブックーのアカウントを"+MaxProfileSNSLength+"文字以内にしてください";
			err = true;
		}

		const instagram = states.profile.instagram.trim();
		if( instagram.length>MaxProfileSNSLength ){
			error["instagram"] = "インスタグラムのアカウントを"+MaxProfileSNSLength+"文字以内にしてください";
			err = true;
		}

		const youtube = states.profile.youtube.trim();
		if( youtube.length>MaxProfileSNSLength ){
			error["youtube"] = "YouTubeのURLを"+MaxProfileSNSLength+"文字以内にしてください";
			err = true;
		}

		const website = states.profile.webSite.trim();
		if( website.length>MaxProfileWebSiteLength ){
			error["webSite"] = "ウェブサイトのURLを"+MaxProfileWebSiteLength+"文字以内にしてください";
			err = true;
		}

		if( error["name"]==="" ){
			const ajax = new Ajax();
			const res = await ajax.checkUniqueProfileName(props.userId,name);
			//console.log(res);
			if( res.err || res.result!=="OK" ){
				error["name"] = "この名前はほかの作家さんが使用しています";
				err = true;
			}
		}

		if(err){
			setToast('赤字の項目を修正してください');
			setStates({...states,error:error,isWaiting:false});
			return;
		}

		setStates({...states,isUploader:true,isWaiting:false});
	}

	const closeUploader = ()=>{
		setStates({...states,isUploader:false});
	}

	const finishUploader = ()=>{
		setStates({...states,isUploader:false,isUpdated:false});
		props.close(true);
	}

	const cancel = ()=>{
		if( states.isUpdated ){
			setIsExit(true);
		} else {
			props.close(false);
		}
	}

	const closeToast = ()=>{
		setToast('');
	}

	const load = async ()=>{
		//console.log("load(ProfileInput)");
		if( !props.userId ){
			setStates({...defaultStates,isLoading:false});
			return;
		}
		setStates({...defaultStates,isLoading:true});
		const ajax = new Ajax();
		const res = await ajax.getProfile(props.userId,'');
		if( res.result==="OK" ){
			//let images = states.images;
			let images = [] as ImageData[];
			if( res.profile.imageKey!=='' ){
				images.push({
					name: res.profile.imageKey,
					source: "",
					size: 0,
					result: true,
				});
			}
			setStates({...defaultStates,profile:res.profile,images:images,isLoading:false});
		} else {
			setStates({...defaultStates,isLoading:false});
		}
	}

	useEffect(()=>{
		if(!props.isOpen){
			return;
		}
		load();
		window.scrollTo(0,0);
	},[props.isOpen,userState.user]);

	let disabled = false;
	if( !states.isUpdated || states.isWaiting || states.profile.name==="" ){
		disabled = true;
	}

	let userId = "";
	if( userState.user ){
		userId = userState.user.uid;
	}


	return (
		<Dialog
			fullScreen
			open={props.isOpen}
			onClose={(event, reason) => {
				if (reason !== 'backdropClick') {
					close();
				}
			}}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			disableEscapeKeyDown={true}
		>
			<DialogContent style={{padding:"0",margin:"0"}}>

				<AppBar position="fixed" style={{backgroundColor:`#FFFFFF`}}>
					<Toolbar variant="dense" style={{backgroundColor:`#FFFFFF`}}>
						<Box style={{flex:'1'}}/>
						<Button variant="text" color="primary" onClick={cancel} style={{marginRight:`0.5em`}}>もどる</Button>
						<Button variant="contained" color="primary" disabled={disabled} onClick={validate}>登録</Button>
					</Toolbar>
				</AppBar>

				<Container maxWidth="md" style={{paddingTop:"5em",paddingBottom:"6em"}}>

					<Grid container spacing={1}>
						<Grid item xs={12} style={{marginBottom:"2em",position:"relative"}}>
							<Box style={{position:"absolute",top:"0",left:"0.5em"}}>
								<ImageAvatar image={states.images[0]} onClick={cancelImage} clickable={true}/>
							</Box>
							<Box style={{paddingLeft:"100px",width:"100%"}}>
								<InputField label="作家名" onChange={onChangeValue("name")} value={states.profile.name} error={states.error.name} opt={{maxLength:MaxProfileNameLength,required:true}}/>
								<FormHelperText>※{MaxProfileNameLength}文字以内、必須</FormHelperText>
							</Box>
						</Grid>
						{states.images.length===0 &&
						<Grid item xs={12} style={{marginBottom:"2em"}}>
							<ImageSelect title="アバター画像" message="ドラッグ＆ドロップ、又はタップしてファイルを選択" images={states.images} maxWidth={600} maxFiles={1} setImages={setImages} cancel={cancelImage}/>
							<FormHelperText>※PNG / JPEG / GIF、{MaxProfileImageSize}MB以上か幅または高さが{Tools.comma(MaxProfileImageWidth)}pixel以上の画像は自動的に圧縮されます</FormHelperText>
						</Grid>
						}
						<Grid item xs={12} style={{marginBottom:"2em"}}>
							<InputField label="自己紹介" onChange={onChangeValue("story")} value={states.profile.story} error={states.error.story} opt={{type:'text',rows:10,maxLength:MaxProfileStoryLength}}/>
							<FormHelperText>※{MaxProfileStoryLength}文字以内</FormHelperText>
						</Grid>
						<Grid item xs={12} style={{marginBottom:"2em"}}>
							<InputField label="Twitter" onChange={onChangeValue("twitter")} value={states.profile.twitter} error={states.error.twitter}
								opt={{
									maxLength:MaxProfileSNSLength,
									startAdornment:<InputAdornment position="start"><TwitterIcon style={{marginRight:"0.5em"}}/>@</InputAdornment>,
								}}
							/>
							<InputField label="Instagram" onChange={onChangeValue("instagram")} value={states.profile.instagram} error={states.error.instagram}
								opt={{
									maxLength:MaxProfileSNSLength,
									startAdornment:<InputAdornment position="start"><InstagramIcon style={{marginRight:"0.5em"}}/> @</InputAdornment>,
								}}
							/>
							<InputField label="Facebook" onChange={onChangeValue("facebook")} value={states.profile.facebook} error={states.error.facebook}
								opt={{
									maxLength:MaxProfileSNSLength,
									startAdornment:<InputAdornment position="start"><FacebookIcon style={{marginRight:"0.5em"}}/> @</InputAdornment>,
								}}
							/>
							<InputField label="YouTube" onChange={onChangeValue("youtube")} value={states.profile.youtube} error={states.error.youtube}
								opt={{
									maxLength:MaxProfileSNSLength,
									startAdornment:<InputAdornment position="start"><YouTubeIcon style={{marginRight:"0.5em"}}/> https://www.youtube.com/</InputAdornment>,
								}}
							/>
							<FormHelperText>※それぞれ{MaxProfileSNSLength}文字以内、先頭の@は不要</FormHelperText>
						</Grid>
						<Grid item xs={12} style={{marginBottom:"2em"}}>
							<InputField label="ウェブサイト" onChange={onChangeValue("webSite")} value={states.profile.webSite} error={states.error.webSite}
								opt={{
									maxLength:MaxProfileWebSiteLength,
									startAdornment:<InputAdornment position="start"><WebIcon style={{marginRight:"0.5em"}}/></InputAdornment>,
									placeholder:"https://"
								}}
							/>
							<FormHelperText>※{MaxProfileWebSiteLength}文字以内</FormHelperText>
						</Grid>
					</Grid>
					<ProfileUploader isOpen={states.isUploader} profile={states.profile} images={states.images} userId={userId} close={closeUploader} finish={finishUploader}/>
					<Toast mes={toast} close={closeToast}/>
					<Loading isOpen={states.isLoading}/>
					<YesNo onYes={exitYes} onNo={exitNo} title="保存せずに終了" text="編集途中の内容は破棄されますがよろしいですか？" isOpen={isExit}/>
				</Container>
			</DialogContent>
		</Dialog>
	)
}

export default ProfileInput;