import React from 'react';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import FaceIcon from '@mui/icons-material/Face';
import PaymentIcon from '@mui/icons-material/Payment';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import ReceiptIcon from '@mui/icons-material/Receipt';
import InfoIcon from '@mui/icons-material/Info';
import CottageIcon from '@mui/icons-material/Cottage';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import RuleIcon from '@mui/icons-material/Rule';
import PersonIcon from '@mui/icons-material/Person';
import AssistantIcon from '@mui/icons-material/Assistant';
import SecurityIcon from '@mui/icons-material/Security';
import {useStyles} from './useStyles';

interface PageTitleProps {
	title:any,
	icon?:"home"|"product"|"profile"|"charge"|"payment"|"transfer"|"delete"|"common"|"help"|"term"|"privacy"|'account'|'contact',
}

const PageTitle = (props:PageTitleProps)=> {
	const classes = useStyles();
	return (
		<div className={classes.pageTitle}>
			{props.icon && props.icon==="home" && <CottageIcon className={classes.pageTitleIcon}/>}
			{props.icon && props.icon==="product" && <UploadFileIcon className={classes.pageTitleIcon}/>}
			{props.icon && props.icon==="profile" && <FaceIcon className={classes.pageTitleIcon}/>}
			{props.icon && props.icon==="payment" && <PaymentIcon className={classes.pageTitleIcon}/>}
			{props.icon && props.icon==="charge" && <CreditScoreIcon className={classes.pageTitleIcon}/>}
			{props.icon && props.icon==="transfer" && <ReceiptIcon className={classes.pageTitleIcon}/>}
			{props.icon && props.icon==="delete" && <DeleteSweepIcon className={classes.pageTitleIcon}/>}
			{props.icon && props.icon==="help" && <HelpOutlineIcon className={classes.pageTitleIcon}/>}
			{props.icon && props.icon==="term" && <RuleIcon className={classes.pageTitleIcon}/>}
			{props.icon && props.icon==="privacy" && <SecurityIcon className={classes.pageTitleIcon}/>}
			{props.icon && props.icon==="account" && <PersonIcon className={classes.pageTitleIcon}/>}
			{props.icon && props.icon==="common" && <InfoIcon className={classes.pageTitleIcon}/>}
			{props.icon && props.icon==="contact" && <AssistantIcon className={classes.pageTitleIcon}/>}
			{props.title}
		</div>
	);
}

export default PageTitle;
