import React,{useEffect,useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
//import IconButton from '@mui/material/IconButton';
//import Tooltip from '@mui/material/Tooltip';
//import CancelIcon from '@mui/icons-material/Cancel';
import CircularProgress from '@mui/material/CircularProgress';
import Ajax from './Ajax';
import {UserContainer} from './UserContainer';

interface PaymentCheckProps {
	close():void,
	isOpen:boolean,
}

const PaymentCheck:React.FC<PaymentCheckProps> = (props)=> {
	const [status,setStatus] = useState(0);
	const userState = UserContainer.useContainer();
	const sendAccountMail = async ()=>{
		if(!userState.user){
			return;
		}
		const ajax = new Ajax();
		const res = await ajax.sendAccountMail(userState.user.uid);
		if( res.result==="OK" ){
			setStatus(1);
		} else {
			setStatus(9);
		}
	}

	useEffect(()=>{
		if( props.isOpen ){
			setStatus(0);
			if(userState.ready){
				if(!userState.user){
					props.close();
				}
				sendAccountMail();
			}
		}
	},[props.isOpen,userState.ready]);

	let title = "確認メールを送信しました";
	let text = "メールに書かれたURLにアクセスしてください。";
	if( status===9 ){
		title = "エラー";
		text = "確認メールを送信できませんでした";
	}

	if( !userState.ready ){
        return (null);
    }

	return (
		<Dialog
			open={props.isOpen}
			onClose={props.close}
			aria-labelledby="yesno-dialog-title"
			aria-describedby="yesno-dialog-description"
		>
			{status===0 ? (
			<React.Fragment>
			<DialogTitle id="yesno-dialog-title">確認メールの送信</DialogTitle>
			<DialogContent>
				<div style={{padding:'2em 0',textAlign:'center'}}>
					<CircularProgress/>
				</div>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.close} color="primary" variant="outlined">
					やめる
				</Button>
			</DialogActions>
			</React.Fragment>
			):(
			<React.Fragment>
			<DialogTitle id="yesno-dialog-title">{title}</DialogTitle>
			<DialogContent>
				<DialogContentText id="yesno-dialog-description">
					{text}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.close} color="primary" variant="outlined">
					閉じる
				</Button>
			</DialogActions>
			</React.Fragment>
			)}
		</Dialog>
	);
}

export default PaymentCheck;