import React,{useEffect,useState} from 'react';
import {Link,Navigate} from 'react-router-dom'
//import {DataGrid, GridRowsProp, GridColDef, GridLocaleText} from '@mui/x-data-grid';
//import {useTheme} from '@mui/material/styles';
//import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ForwardIcon from '@mui/icons-material/Forward';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import AddIcon from '@mui/icons-material/Edit';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
//import Collapse from '@mui/material/Collapse';
import CircularProgress from '@mui/material/CircularProgress';
import ProductInput from './ProductInput';
import Ajax from './Ajax';
import PageTitle from './PageTitle';
import Tools from './Tools';
//import Loading from './Loading';
import {Product,MaxProducts} from './Inc';
import {UserContainer} from './UserContainer';

interface ProductListProps {
	
}

const ProductList:React.FC<ProductListProps> = (props)=> {
	const userState = UserContainer.useContainer();
	const defaultStates = {
		list: [] as Product[],
		permit: false,
		isInput: false,
		isLoading: true,
		all:0,
		getaway: false,
	};
	const [states,setStates] = useState(defaultStates);
	const [search,setSearch] = useState({
		productName: "",
		size: 10,
		page: 0,
		order: "id",
		asc: "desc",
	});	

	//const productListColumns: GridColDef[] = [
	//	{
	//		field: 'editBtn',
	//		headerName: '詳細',
	//		sortable: false,
	//		disableColumnMenu: true,
	//		width: 60,
	//		renderCell: (params) => <Link to={'/product/detail/'+params.id}><IconButton size="small"><ForwardIcon/></IconButton></Link>
	//	},
	//	{ field: 'id', headerName: 'ID',hide:true},
	//	//{ field: 'name', headerName: '商品名', width:nameWidth},
	//	{ field: 'price', headerName: '価格', align:"right"},
	//	{ field: 'start', headerName: '販売開始日', width:110, align:"center", renderCell:(params)=><span>{params.value && String(params.value).substr(0,4)+"."+String(params.value).substr(4,2)+"."+String(params.value).substr(6,2)}</span>},
	//	{ field: 'lastSale', headerName: '最終購入日', width:110, align:"center", renderCell:(params)=><span>{params.value && String(params.value).substr(0,4)+"."+String(params.value).substr(4,2)+"."+String(params.value).substr(6,2)}</span>},
	//	{ field: 'available', headerName: '状態', align:"center", renderCell:(params)=>params.value===true ? (<span style={{color:'teal'}}>販売中</span>):(<span style={{color:'#CCCCCC'}}>準備中</span>)},
	//];
	
	const load = async (page:number,size:number)=>{
		if(!userState.user){
			return;
		}
		const ajax = new Ajax();
		const newSearch = {...search,page:page,size:size};
		const res = await ajax.getProducts(userState.user.uid,newSearch);
		if( !res.err ){
			let permit = false;
			if( res.products.length<MaxProducts ){
				permit = true;
			}
			setStates({...states,list:res.products,permit:permit,all:res.all,isLoading:false,isInput:false});
			setSearch(newSearch);
		}
	}
	const updateSearch = (name:string)=>(event: React.ChangeEvent<HTMLInputElement>)=>{
		setSearch({...search,[name]:event.target.value});
	}
	const clearSearch = (name:string)=>(event: React.MouseEvent<HTMLElement>)=>{
		setSearch({...search,[name]:''});
	}
	const goSearch = ()=> {
		load(0,search.size);
	}	
	const openInput = ()=>{
		setStates({...states,isInput:true});
	}
	const closeInput = (updated:boolean)=>{
		setStates({...states,isInput:false});
		if( updated ){
			load(0,search.size);
		}
	}
	const onPageChange = (event: unknown, newPage: number) => {
		load(newPage,search.size);
	}
	const onPageSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		load(search.page,parseInt(event.target.value, 10));
	}
	const ProductRow = (props: {product: Product})=>{
		const product = props.product;
		//const [open, setOpen] = useState(false);
		return (	
		<React.Fragment>
			<TableRow>
				<TableCell><Link to={"/product/detail/"+product.id} style={{color:'teal'}} className="link">{product.name}</Link></TableCell>
				<TableCell align='right'>{Tools.comma(product.price)}</TableCell>
				<TableCell align='center'>{Tools.toDate(product.start)}</TableCell>
				<TableCell align='center'>{Tools.toDate(product.lastSale)}</TableCell>
				<TableCell align='center'>{product.available ? (<span style={{color:'teal'}}>販売中</span>):(<span style={{color:'#CCCCCC'}}>準備中</span>)}</TableCell>
				<TableCell align='right'><Link to={"/product/detail/"+product.id} className="link"><IconButton size="small"><ArrowRightIcon/></IconButton></Link></TableCell>
			</TableRow>

		</React.Fragment>
		);
	}

	useEffect(()=>{
		if( userState.ready ){
			if( userState.user ){
				load(0,search.size);
			} else {
				setStates({...defaultStates,getaway:true});
			}
		}
	},[userState.user]);

	if( states.getaway ){
		return <Navigate to='/'/>
	}

	return (
		<React.Fragment>
			<PageTitle title="商品リスト" icon="product"/>
			<Box style={{marginBottom:"0.5em",display:`flex`}}>
				<TextField
					variant="standard"
					value={search.productName}
					onChange={updateSearch('productName')}
					placeholder="商品名で検索"
					sx={{width:{xs:'110px',sm:'180px'}}}
					style={{marginRight:'0.5em'}}
					InputProps={{
					startAdornment: <EditIcon fontSize="small" style={{marginRight:"0.5em"}}/>,
					endAdornment: (
						<IconButton
						title="Clear"
						aria-label="Clear"
						size="small"
						onClick={clearSearch('productName')}
						>
						<ClearIcon fontSize="small" />
						</IconButton>
					),
					}}
				/>
				<Box style={{flex:'1'}}></Box>
				<Button variant="outlined" color="primary" size="small" onClick={goSearch} style={{marginRight:'0.25em'}}><SearchIcon fontSize="small"/>検索</Button>
				<Button variant="contained" color="primary" size="small" onClick={openInput} disabled={!states.permit}><AddIcon fontSize="small"/>新規登録</Button>
			</Box>
			<Box style={{width: '100%'}}>
				{states.isLoading ? (
				<CircularProgress/>
				):(
				<Box style={{width: '100%'}}>
					<TableContainer>
						<Table sx={{ minWidth: 640 }} size="small" aria-label="a dense table">
							<TableHead style={{borderTop:'2px solid #DDDDDD'}}>
								<TableRow>
									<TableCell align="center" style={{whiteSpace:'nowrap'}}>商品名</TableCell>
									<TableCell align="center" style={{whiteSpace:'nowrap'}}>価格</TableCell>
									<TableCell align="center" style={{whiteSpace:'nowrap'}}>販売開始日</TableCell>
									<TableCell align="center" style={{whiteSpace:'nowrap'}}>最終購入日</TableCell>
									<TableCell align="center" style={{whiteSpace:'nowrap'}}>状態</TableCell>
									<TableCell align="center" style={{whiteSpace:'nowrap'}}>　</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
							{states.list.map((product:Product,index:number)=>{
								const key = "product" + index;
								return <ProductRow key={key} product={product}/>
							})}
							</TableBody>
						</Table>
					</TableContainer>
					{states.all>0 ? (
					<TablePagination
						rowsPerPageOptions={[10, 25, 50, 100]}
						component="div"
						count={states.all}
						rowsPerPage={search.size}
						page={search.page}
						onPageChange={onPageChange}
						onRowsPerPageChange={onPageSizeChange}
						labelRowsPerPage="表示件数"
					/>
					):(
					<Box className="noRecord">商品データはありません</Box>
					)}
				</Box>
				)}
			</Box>

			<ProductInput isOpen={states.isInput} close={closeInput}/>
		</React.Fragment>
	)
}
export default ProductList;