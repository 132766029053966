import React,{useEffect,useState} from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
//import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
//import Dialog from '@mui/material/Dialog';
//import DialogActions from '@mui/material/DialogActions';
//import DialogContent from '@mui/material/DialogContent';
//import DialogContentText from '@mui/material/DialogContentText';
//import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import CalculateIcon from '@mui/icons-material/Calculate';
import Balance from './Balance';
import PageTitle from './PageTitle';
import {ChargeRow} from './ChargeRow';
import Ajax from './Ajax';
import {Charge} from './Inc';
import {UserContainer} from './UserContainer';

interface ChargeListProps {

}

const ChargeList:React.FC<ChargeListProps> = (props)=> {
	const userState = UserContainer.useContainer();
	const [states,setStates] = useState({
		list: [] as Charge[],
		isLoading: true,
		all: 0,
	});
	const [isDialog,setIsDialog] = useState(false);
	const [search,setSearch] = useState({
		productName: "",
		profileName: "",
		productId: "",
		status: "",
		size: 10,
		page: 0,
		order: "id",
		asc: "desc",
	});
	const load = async (page:number,size:number)=>{
		if(!userState.user){
			return;
		}
		const ajax = new Ajax();
		const newSearch = {...search,page:page,size:size};
		const res = await ajax.getCharges(userState.user.uid,newSearch);
		if( !res.err ){
			setStates({...states,list:res.list,all:res.all,isLoading:false});
			setSearch(newSearch);
		}
	}
	const updateSearch = (name:string)=>(event: React.ChangeEvent<HTMLInputElement>)=>{
		setSearch({...search,[name]:event.target.value});
	}
	const clearSearch = (name:string)=>(event: React.MouseEvent<HTMLElement>)=>{
		setSearch({...search,[name]:''});
	}
	const goSearch = ()=> {
		load(0,search.size);
	}
	const onPageChange = (event: unknown, newPage: number) => {
		load(newPage,search.size);
	}
	const onPageSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		load(search.page,parseInt(event.target.value, 10));
	}
	const openDialog = ()=>{
		setIsDialog(true);
	}
	const closeDialog = ()=>{
		setIsDialog(false);
	}
	useEffect(()=>{
		load(0,search.size);
	},[userState.user]);

	return (
		<React.Fragment>
			<PageTitle title="売上明細" icon="charge"/>
			<Box style={{marginBottom:"0.5em",display:`flex`}}>
				<TextField
					variant="standard"
					value={search.productName}
					onChange={updateSearch('productName')}
					placeholder="商品名で検索"
					style={{width:'180px',marginRight:'0.5em'}}
					InputProps={{
					startAdornment: <EditIcon fontSize="small" style={{marginRight:"0.5em"}}/>,
					endAdornment: (
						<IconButton
						title="Clear"
						aria-label="Clear"
						size="small"
						onClick={clearSearch('productName')}
						>
						<ClearIcon fontSize="small" />
						</IconButton>
					),
					}}
				/>
				<Box style={{flex:'1'}}></Box>
				<Button variant="outlined" color="primary" size="small" onClick={goSearch} style={{marginRight:'0.25em'}}><SearchIcon fontSize="small"/>検索</Button>
				<Button variant="contained" color="primary" size="small" onClick={openDialog}><CalculateIcon fontSize="small"/>残高確認</Button>
			</Box>
			{states.isLoading ? (
			<Box style={{width: '100%'}}>
				<CircularProgress/>
			</Box>
			):(
			<Box style={{width: '100%'}}>
				<TableContainer>
					<Table sx={{ minWidth: 640 }} size="small" aria-label="a dense table">
						<TableHead style={{borderTop:'2px solid #DDDDDD'}}>
							<TableRow>
								<TableCell align="center" style={{whiteSpace:'nowrap'}}>売上日時</TableCell>
								<TableCell align="center" style={{whiteSpace:'nowrap'}}>商品名</TableCell>
								<TableCell align="center" style={{whiteSpace:'nowrap'}}>価格</TableCell>
								<TableCell align="center" style={{whiteSpace:'nowrap'}}>状態</TableCell>
								<TableCell align="center" style={{whiteSpace:'nowrap'}}>　</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
						{states.list.map((charge:Charge,index:number)=>{
							const key = "charge" + index;
							return <ChargeRow key={key} charge={charge}/>
						})}
						</TableBody>
					</Table>
				</TableContainer>
				{states.all>0 ? (
				<TablePagination
					rowsPerPageOptions={[1, 10,25,50,100]}
					component="div"
					count={states.all}
					rowsPerPage={search.size}
					page={search.page}
					onPageChange={onPageChange}
					onRowsPerPageChange={onPageSizeChange}
					labelRowsPerPage="表示件数"
				/>
				):(
				<Box className="noRecord">売上データはありません</Box>
				)}
			</Box>
			)}
			<Balance close={closeDialog} isOpen={isDialog}/>
		</React.Fragment>
	)
}

export default ChargeList;