import React from 'react';
import {Link} from 'react-router-dom'
import PageTitle from './PageTitle';
//import {useStyles} from './useStyles';

interface GuideProps {
    
}

const Term:React.FC<GuideProps> = (props)=> {
	//const classes = useStyles();
	return (
		<React.Fragment>
			<PageTitle title="販売利用規約" icon="term"/>

			<div className="termLead">有限会社レトロインク（以下、当社）は、当社が運営するサービス「403adamski」（以下、本サービス）の利用と提供に関し、以下の通り、販売利用規約（以下、本規約）を定めます。</div>

			<div className="termBox">
				<h3>[1] 定義</h3>
				<ol>
					<li>作家とは当社所定の手続きに従い本規約の定めに従い本サービスでデジタルデータを販売する方をいいます。</li>
					<li>作品とは、作家が創作したデジタルデータをいいます。</li>
					<li>購入者とは、本サービスを利用して、作家から作品を購入した方をいいます。</li>
					<li>利用者とは、作家と購入者をいいます。</li>
					<li>登録情報とは、作家が本サービスに登録した作品情報および作家情報をいいます。</li>
				</ol>
			</div>


			<div className="termBox">
				<h3>[2] 本サービスの利用</h3>
				<ol>
					<li>作家はその商品の著作権を有するか、もしくは正当な著作権者から著作権の取り扱いの委任を受けている必要があります。</li>
					<li>作家は商品の販売を開始した時点で本規約に同意したものといたします。</li>
				</ol>
			</div>

			<div className="termBox">
				<h3>[3] 本サービスの試用</h3>
				<ol>
					<li>本サービスは試用が認められています。商品登録を行っても商品の販売を開始しない場合は試用とみなします。</li>
					<li>本サービスの試用においては、本規約への同意は必要ありません。</li>
				</ol>
			</div>

			<div className="termBox">
				<h3 >[4] 販売形態</h3>
				<ol>
					<li>本サービスは、デジタルデータを販売するプラットフォームです。作家は本サービスを利用して購入者に作品を販売します。</li>
					<li>作品は、本サービスのダウンロードページにおいて購入者自身がダウンロードの手続を行うことで提供されます。</li>
				</ol>
			</div>

			<div className="termBox">
				<h3 >[5] 手数料</h3>
				<ol>
					<li>作家は、購入者が当社に販売代金の支払いを行った際に、その販売代金に当社が定める料率を乗じた金額を本サービスの手数料として当社に支払うものとします。</li>
					<li>手数料の料率は、利用手数料3.0％および決済手数料3.6％とします。ただし、当社と作家の間で別途取り決めのある場合は、その限りではありません。</li>
				</ol>
			</div>

			<div className="termBox">
				<h3 >[6] 支払</h3>
				<ol>
					<li>売上の支払いは、作家が指定する金融機関への振り込みによって行われます。その際、作家は当社に送金手数料を支払います。</li>
					<li>作家は、最低振り込み金額を設定することができます。</li>
					<li>月末締めで、作家の売上の残高が最低振り込み金額以上であった場合、当社は翌月末に売上残高の支払いを行います。</li>
					<li>作家の売上の残高が最低振り込み金額に満たない場合、翌月の支払は行われず、残高は繰り越されます。</li>
					<li>上記の繰り越しが6回連続が行われた場合、当社は翌月末に、当社が最適と判断する方法で作家に支払を行うものとします。</li>
				</ol>
			</div>


			<div className="termBox">
				<h3 >[7] 取り扱いできない商品</h3>
				<ol>
					<li>以下のような作品は取り扱いできません。<br/>
						<ol type="A">
							<li>成人コンテンツ</li>
							<li>情報商材</li>
							<li>二次創作など第三者の著作権を侵害する恐れのあるもの</li>
							<li>金券、チケット</li>
							<li>寄付やクラウドファンディングを主目的としたもの</li>
							<li>スパム、ウイルス、マルウェアなどダウンロードにより損害を被る恐れのあるもの</li>
							<li>法令若しくは公序良俗に違反するなど利用規約の禁止事項に触れる内容が含まれるもの</li>
						</ol>
					</li>
				</ol>
			</div>

			<div className="termBox">
				<h3 >[8] 作品の著作権等</h3>
				<ol>
					<li>当社は、商品の著作権についての事前審査はおこないません。販売開始後に著作権上の問題がある又はその可能性があると判明した場合には、当社の判断で、販売を停止し、登録情報を削除する場合があります。</li>
					<li>作家が商品を本サービス以外のサイトや媒体で公開および販売することに関しては、当社は一切関与しません。</li>
					<li>販売開始後に、作家と第三者の間で著作権に関する譲渡や委任などが行われた場合は、商品登録を削除し、必要に応じて再度登録を行うものとします。</li>
					<li>作家は当社に対して、登録情報を本サービスの円滑な提供、本サービスのシステムの構築、改良、メンテナンスに必要な範囲内で使用することを許諾するものとします。</li>
					<li>当社は、登録情報を本サービスの円滑な提供、本サービスのシステムの構築、改良、メンテナンス以外の目的に利用することはありません。<br/>
					また、広告宣伝のためなど、正当な事情で登録情報の一部を本サービス以外のサイトや媒体で利用する場合でも、作家に事前に通知し、了解を得るものとします。</li>
				</ol>
			</div>

			<div className="termBox">
				<h3 >[9] 登録情報の管理</h3>
				<ol>
					<li>登録情報は作家が自己の判断と責任により登録するものとし、その内容によって作家や購入者や第三者に不利益が生じても当社は一切責任を負わないものとします。</li>
					<li>作家は当社に対して、本サービスの利用にあたり第三者の権利を侵害しないことを保証するものとし、第三者との間で何らかの紛争が発生した場合には、当社を除く両者の費用と責任において問題を解決するものとします。</li>
				</ol>
			</div>

			<div className="termBox">
				<h3 >[10] 登録情報以外の著作権等</h3>
				<ol>
					<li>本サービスに含まれている作家情報および商品情報以外の情報、商標、画像、広告、デザイン等（以下、コンテンツ）に関する著作権、商標権その他の財産権は、当社もしくは正当な権利を有する第三者（以下、権利者）に帰属するものとします。</li>
					<li>作家が前項に反する行為によって被った損害については、当社は一切の責任を負わないものとします。また、作家がこれらの行為によって利益を得た場合、当社はその利益相当額を請求できるものとします。</li>
				</ol>
			</div>

			<div className="termBox">
				<h3 >[11] 作家への通知・連絡</h3>
				<ol>
					<li>当社が作家に対して、通知、連絡等を行う必要があると判断した場合、電子メール、本サービスのWebサイトへの掲載など、当社が適当と判断する方法により行うものとします。</li>
					<li>前項における電子メールによる通知、連絡等は、作家が本サービスに登録している電子メールアドレスに対して行うものとします。</li>
					<li>当社が通知、連絡等を本サービスのWebサイト上で行う場合は、当該通知、連絡等をWebサイトに掲載した日をもって、作家に当該通知、連絡等が到達したものとみなします。また、電子メールによって通知、連絡等を行う場合には、当社が作家に対して電子メールを発信した時点をもって、作家に当該通知、連絡等が到達したものとみなします。</li>
				</ol>
			</div>

			<div className="termBox">
				<h3 >[12] 禁止事項</h3>
				<ol>
					<li>作家は、本サービスの利用に関して、以下の行為を行ってはならないものとします。<br/>
						<ol type="A">
							<li>当社若しくは第三者の著作権、商標権などの知的財産権その他の権利を侵害する行為、又は侵害するおそれのある行為</li>
							<li>法令若しくは公序良俗に違反し、又は当社若しくは第三者に不利益を与える行為</li>
							<li>他者を差別若しくは誹謗中傷し、又はその名誉若しくは信用を毀損する行為</li>
							<li>詐欺等の犯罪に結びつく又は結びつくおそれがある行為</li>
							<li>わいせつ、児童ポルノ又は児童虐待にあたる画像、文書等を送信又は掲載する行為</li>
							<li>第三者になりすまして本サービスを利用する行為</li>
							<li>ウィルス等の有害なコンピュータプログラム等を送信又は掲載する行為</li>
							<li>その行為が前各号のいずれかに該当することを知りつつ、その行為を助長する態様・目的でリンクをはる行為</li>
							<li>その他、当社が不適当と判断する行為</li>
						</ol>
					</li>
					<li>当社は、本サービスの利用に関して、作家の行為が前項各号のいずれかに該当するものであること、又は作家の提供した情報が前項各号のいずれかの行為に関連する情報であると判断した場合、事前に作家に通知することなく、本サービスの全部又は一部の提供を一時停止し、又は前項各号に該当する行為に関連する情報を削除する等の当社が必要と認める措置を行うことができるものとします。</li>
					<li>当社は、作家の行為又は作家が提供又は伝送する情報を監視する義務を負うものではありません。第三者の行為が作家の行為とみなされる場合についても同様です。</li>
				</ol>
			</div>

			<div className="termBox">
				<h3 >[13] 他サイトへのリンク</h3>
				<ol>
					<li>本サービスからリンクされている外部のサイトおよび本サービスに掲載されている広告からリンクされているサイトは、それぞれの当社・広告主の責任によって管理されており、当社は、これらのサイトについて、違法なものでないこと、内容が正確であること、不快な内容を含まないものであること、作家が意図していない情報を含まないものであること等を一切保証いたしません。</li>
				</ol>
			</div>

			<div className="termBox">
				<h3 >[14] 個人情報</h3>
				<ol>
					<li>当社が本サービスで取得した作家の個人情報は、当社が別途定める<Link to="/privacy" className="link">プライバシーポリシー</Link>に従って取り扱うものとします。</li>
				</ol>
			</div>

			<div className="termBox">
				<h3 >[15] 本サービスの変更・終了等</h3>
				<ol>
					<li>当社は、本サービスの全部又は一部を変更、中断、又は終了する際には、作家へ事前の通知を行うものとします。ただし、緊急を要すると当社が判断した場合には、事後の通知でそれに代えることができるものとします。また、それにより作家や第三者が損害を被った場合でも当社は一切の責任を負わないものとします。本サービスにおいて提供する情報の内容についても同様です。</li>
				</ol>
			</div>

			<div className="termBox">
				<h3 >[16] 本サービスの停止</h3>
				<ol>
					<li>当社は、以下のいずれかに該当する場合には、作家に事前に通知することなく一時的に本サービスを中断することができるものとします。<br/>
						<ol type="A">
							<li>サーバ、通信回線若しくはその他の設備の故障、障害の発生又はその他の事由により本サービスの提供ができなくなった場合</li>
							<li>システム(サーバ、通信回線や電源、それらを収容する建築物などを含む)の保守、点検、修理、変更を定期的に又は緊急に行う場合</li>
							<li>火災、停電などにより本サービスの提供ができなくなった場合</li>
							<li>地震、噴火、洪水、津波などの天災により本サービスの提供ができなくなった場合</li>
							<li>戦争、変乱、暴動、騒乱、労働争議、疫病の蔓延等その他不可抗力により本サービスの提供ができなくなった場合</li>
							<li>法令による規制、司法命令等が適用された場合</li>
							<li>その他、運用上、技術上当社が本サービスの一時的な中断を必要と判断した場合</li>
						</ol>
					</li>
				</ol>
			</div>

			<div className="termBox">
				<h3 >[17] 本規約の改定・修正・変更</h3>
				<ol>
					<li>当社は、本規約の全部又は一部を変更、中断、又は終了する際には、作家へ事前の通知を行うものとします。ただし、緊急を要すると当社が判断した場合には、事後の通知でそれに代えることができるものとします。改定後に本サービスのご利用があった場合、改定後の規約に同意したものとさせていただきます。</li>
				</ol>
			</div>

			<div className="termBox">
				<h3 >[18] その他</h3>
				<ol>
					<li>本規約は、日本法に基づき解釈されるものとし、本規約に関して訴訟の必要が生じた場合には、東京地方裁判所を第一審の専属的合意管轄裁判所とします。</li>
					<li>本規約の解釈を巡って疑義が生じた場合、当社は合理的な範囲でその解釈を決定できるものとします。</li>
				</ol>
			</div>

			<div className="termBox">
				<h3>附則</h3>
				<p>
					本規約は2022年1月10日から施行します。<br/>
					2022年1月10日 施行
				</p>
				403adamski/有限会社レトロインク<br/>
				代表取締役　石村寛之<br/>
			</div>
		</React.Fragment>
	)
}

export default Term;