import React,{useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MoneyIcon from '@mui/icons-material/Money';
import EventIcon from '@mui/icons-material/Event';
import InfoIcon from '@mui/icons-material/Info';
import PaidIcon from '@mui/icons-material/Paid';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import Multilines from './Multilines';
import {Charge,TransferFee} from './Inc';
import Tools from './Tools';

export const ChargeRow = (props: {charge: Charge})=>{
	const charge = props.charge;
	const [open, setOpen] = useState(false);
	let processedAt = '';
	if( charge.processedAt!=='' ){
		const sp = charge.processedAt.split('T');
		processedAt = sp[0] + " " + sp[1].substring(0,8);
	}
	let cl = "status status";
	let status = "";
	if(charge.transferMonth && !charge.canceled){
		cl = cl + "1";
		status = Tools.toMonth(charge.transferMonth) + "\n入金";
	} else if(!charge.transferMonth && !charge.canceled){
		cl = cl + "0";
		status = "売上残高";
	} else if(charge.canceled){
		cl = cl + "9";
		status = "キャンセル";
	}
	return (
	<React.Fragment>
		<TableRow
			sx={{ 'td,th':{borderBottomWidth:'0'},'&:last-child td, &:last-child th': { border: 0 } }}
		>
			<TableCell component="th" scope="row">{processedAt}</TableCell>
			<TableCell>{charge.productName}</TableCell>
			<TableCell style={{textAlign:'right'}}>{Tools.comma(charge.amount)}</TableCell>
			<TableCell style={{textAlign:'center'}} className={cl}><Multilines text={status}/></TableCell>
			<TableCell style={{textAlign:'right',paddingRight:'0.5em'}}>
				<IconButton
					aria-label="expand row"
					size="small"
					onClick={() => setOpen(!open)}
				>
					{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
				</IconButton>
			</TableCell>
		</TableRow>
		<TableRow>
			<TableCell style={{ padding: 0 }} colSpan={5}>
				<Collapse in={open} timeout="auto" unmountOnExit>
					<Table className="collapseTable">
						<TableBody>
							<TableRow>
								<TableCell component="th" scope="row" className="collapseTableTh"><MoneyIcon className="icon"/>販売価格</TableCell>
								<TableCell>{Tools.comma(charge.amount)+'円'}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell component="th" scope="row" className="collapseTableTh"><PaidIcon className="icon"/>決済手数料</TableCell>
								<TableCell>{Tools.comma(charge.paidFee)+'円'}（{Math.ceil(charge.paidFeePer*1000)/10+'％'}）</TableCell>
							</TableRow>
							<TableRow>
								<TableCell component="th" scope="row" className="collapseTableTh"><PaidIcon className="icon"/>利用手数料</TableCell>
								<TableCell>{Tools.comma(charge.usageFee)+'円'}（{Math.ceil(charge.usageFeePer*1000)/10+'％'}）</TableCell>
							</TableRow>
							<TableRow>
								<TableCell component="th" scope="row" className="collapseTableTh"><AccountBalanceIcon className="icon"/>小計</TableCell>
								<TableCell>{Tools.comma(charge.amount-charge.paidFee-charge.usageFee)+'円'}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell component="th" scope="row" className="collapseTableTh"><EventIcon className="icon"/>決済日時</TableCell>
								<TableCell>{processedAt}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell component="th" scope="row" className="collapseTableTh"><EventIcon className="icon"/>支払年月</TableCell>
								{charge.transferMonth ? (
								<TableCell>{Tools.toMonth(charge.transferMonth)}</TableCell>
								):(
								<TableCell>未定</TableCell>
								)}
							</TableRow>
							{(charge.transferMonth && charge.canceled) &&
							<TableRow>
								<TableCell component="th" scope="row" className="collapseTableTh"><EventIcon className="icon"/>返金年月</TableCell>
								{charge.refundMonth ? (
								<TableCell>{Tools.toMonth(charge.refundMonth)}</TableCell>
								):(
								<TableCell>なし</TableCell>
								)}
							</TableRow>
							}
							<TableRow>
								<TableCell component="th" scope="row" className="collapseTableTh"><InfoIcon className="icon"/>状態</TableCell>
								{(charge.transferMonth && !charge.canceled) &&
								<TableCell className="status status1">{charge.transferMonth.substring(0,4)+"年"+charge.transferMonth.substring(4,6)+"月"}にお支払します。支払日の詳細は「入金」メニューでご確認ください。</TableCell>
								}
								{(!charge.transferMonth && charge.canceled) &&
								<TableCell className="status status9">キャンセルになりました</TableCell>
								}
								{(charge.transferMonth && charge.refundMonth && charge.canceled) &&
								<TableCell className="status status9">キャンセルになりました。{charge.refundMonth.substring(0,4)+"年"+charge.refundMonth.substring(4,6)+"月"}のお支払から差し引かれます。</TableCell>
								}
								{(charge.transferMonth && !charge.refundMonth && charge.canceled) &&
								<TableCell className="status status9">キャンセルになりました。後日のお支払から差し引かれます。</TableCell>
								}
								{(!charge.transferMonth && !charge.canceled) &&
								<TableCell className="status status0">月末締めで売上残高が 最低振り込み金額+振込手数料 以上になると翌月の支払対象となります。<br/>振込手数料は{TransferFee}円です。</TableCell>
								}
							</TableRow>
						</TableBody>
					</Table>
				</Collapse>						
			</TableCell>
		</TableRow>
	</React.Fragment>
	);
}
