import React from 'react';
import {useState, useEffect} from 'react';
import {useParams,Link,Navigate} from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import CircularProgress from '@mui/material/CircularProgress';
import WarningIcon from '@mui/icons-material/Warning';
import DoneIcon from '@mui/icons-material/Done';
import {useStyles} from './useStyles';
import Ajax from './Ajax';
import {UserContainer} from './UserContainer';

interface AccountChangedProps {
}

const AccountChanged = (props:AccountChangedProps)=> {
    const userState = UserContainer.useContainer();
	const classes = useStyles();
	const {result} = useParams<{result:string}>();
	const [level,setLevel] = useState(0);

	useEffect(()=>{
		if( userState.ready ){
			if( userState.user && userState.user.uid!=='' ){
				//console.log("changed:"+userState.user.uid);
				//setLogined(true);
				setLevel(level+1);
			}
		} else {
			setLevel(0);
		}
	},[userState.ready,userState.user?.uid]);

	if( level>1 ){
		return (
			<Navigate to='/'/>
		)
	}

	if( !result ){
		return (null);
	}

	if( result === 'success' ){
		return (
			<Box style={{textAlign:'center',paddingTop:'2em',paddingBottom:'1em'}}>
				<DoneIcon style={{fontSize:'200%'}} color="success"/><br/>
				ログイン方法を変更しました
				<FormControl fullWidth={true} style={{marginTop:'2em'}}>
					<Link to='/' className={classes.link}><Button variant="contained" color="primary">トップページ</Button></Link>
				</FormControl>
			</Box>
		)	
	}

	if( result === 'used' ){
        return (
            <div style={{textAlign:'center',marginTop:'4em'}}>
				<p><WarningIcon style={{fontSize:'200%'}} color="warning"/></p>
				<p>
					変更できませんでした<br/>
					変更先のアカウントはすでに利用されています。<br/>
				</p>
				<FormControl fullWidth={true} style={{marginTop:'2em'}}>
					<Link to='/' className={classes.link}><Button variant="contained" color="primary">ログインからやり直す</Button></Link>
				</FormControl>
			</div>
        )
    }

	return (
		<div style={{textAlign:'center',marginTop:'4em'}}>
			<p><WarningIcon style={{fontSize:'200%'}} color="warning"/></p>
			<p>
				変更できませんでした<br/>
			</p>
			<FormControl fullWidth={true} style={{marginTop:'2em'}}>
				<Link to='/' className={classes.link}><Button variant="contained" color="primary">ログインからやり直す</Button></Link>
			</FormControl>
		</div>
	)
}

export default AccountChanged;
