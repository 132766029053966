import React from 'react';
import { Route, Routes } from 'react-router-dom'
//import { memo, VFC } from 'react'
import { useTracking } from './useTracking'
import ProfileDetail from './ProfileDetail';
import ProfileRemove from './ProfileRemove';
import ProductDetail from './ProductDetail';
import ProductCharges from './ProductCharges';
import ProductList from './ProductList';
import TransferList from './TransferList';
import TransferDetail from './TransferDetail';
import PaymentActivate from './PaymentActivate';
import ChargeList from './ChargeList';
import GuideIndex from './GuideIndex';
import GuideDetail from './GuideDetail';
import Term from './Term';
import Privacy from './Privacy';
import ContactInput from './ContactInput';
import MailNew from './MailNew';
import MailUpdate from './MailUpdate';
import AccountChanged from './AccountChanged';
import Logout from './Logout';
import Top from './Top';

const Router: React.VFC = React.memo(() => {
	useTracking();

	return (
		<Routes>
			<Route path='/' element={<Top mode="list"/>}/>
			<Route path='/profile/detail' element={<ProfileDetail/>}/>
			<Route path='/profile/remove' element={<ProfileRemove/>}/>
			<Route path='/product/detail/:productId' element={<ProductDetail/>}/>
			<Route path='/product/charges/:productId' element={<ProductCharges/>}/>
			<Route path='/product/list' element={<ProductList/>}/>
			<Route path='/transfer/list' element={<TransferList/>}/>
			<Route path='/transfer/detail/:uniqueKey' element={<TransferDetail/>}/>
			<Route path='/charge/list' element={<ChargeList/>}/>
			<Route path='/help' element={<GuideIndex/>}/>
			<Route path='/help/:helpGroup/:helpIndex' element={<GuideDetail/>}/>
			<Route path='/term' element={<Term/>}/>
			<Route path='/privacy' element={<Privacy/>}/>
			<Route path='/contact' element={<ContactInput/>}/>
			<Route path='/account/:checkKey' element={<PaymentActivate/>}/>
			<Route path='/account/changed/:result' element={<AccountChanged/>}/>
			<Route path='/mail/new' element={<MailNew/>}/>
			<Route path='/mail/update/:oldUserId' element={<MailUpdate/>}/>
			<Route path='/logout' element={<Logout/>}/>
		</Routes>
	)
})

export default Router;