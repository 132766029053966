import React,{useState,useEffect} from 'react';
import {Link} from 'react-router-dom'//yarn add @types/react-router-dom
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import TwitterIcon from '@mui/icons-material/Twitter';
import GoogleIcon from '@mui/icons-material/Google';
import MailIcon from '@mui/icons-material/Mail';
import CancelIcon from '@mui/icons-material/Cancel';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import KeyIcon from '@mui/icons-material/Key';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
//import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Loading from './Loading';
import Toast from './Toast';
import {useStyles} from './useStyles';
import {UserContainer} from './UserContainer';

interface EntryProps {
	isOpen:boolean,
	close():void,
}

const Entry = (props:EntryProps)=> {
    const [states,setStates] = useState({
		uniqueId:"",
		password:"",
        isLoading:false,
		created:false,
    });
	const [showPassword, setShowPassword] = useState(false);
	const classes = useStyles();
    const [toast,setToast] = useState("");
    const userState = UserContainer.useContainer();
    const createUser = ()=>{
        setStates({...states,isLoading:true});
        const email = states.uniqueId;
		const password = states.password;
        userState.createUserWithEmailAndPassword(email,password,()=>{
            setStates({...states,isLoading:false,created:true});
			//props.close(true);
        },()=>{
			setStates({...states,isLoading:false});
            setToast("登録できませんでした");
        });      
    }

    const validatePassword = ()=>{
        let error = "";
        if( states.uniqueId==="" ){
            error = "メールアドレスを入力してください";
        } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(states.uniqueId)){
            error = "メールアドレスが正しくありません";
        } else if( states.password==="" ){
            error = "パスワードを入力してください";
        } else if( states.password.length>20 ){
			error = "パスワードが長すぎます";
        } else if( states.password.length<6 ){
			error = "パスワードが短すぎます";
		}
		if( error!=="" ){
            setToast(error);
        } else {
            createUser();
        }
    }

    const changeUniqueId = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStates({...states,"uniqueId":event.target.value});
    }
    const changePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStates({...states,"password":event.target.value});
    }

	function handleClickShowPassword() {
		setShowPassword(!(showPassword))
	}
  
	function handleMouseDownPassword(event: React.MouseEvent<HTMLButtonElement>) {
		event.preventDefault();
	};	

	const closeToast = ()=>{
        setToast("");
    }
	const close = ()=>{
		closeToast();
		props.close();
	}
  
	useEffect( ()=>{
		if( props.isOpen ){
			setStates({uniqueId:"",password:"",isLoading:false,created:false});
		}
	},[props.isOpen]);

	if( !userState.ready ){
        return (null);
    }

    return (
        <Dialog
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={props.isOpen}
            onClose={close}
        >
				<React.Fragment>
                <DialogTitle>パスワードの登録</DialogTitle>
                <DialogContent>
					{states.created ? (
					<>
					<DialogContentText style={{marginBottom:'1em'}}>
						パスワードを登録しました。<br/>
						次回からはこのメールアドレスとパスワードでログインしてください。<br/>
					</DialogContentText>
					<DialogActions>
						<Button color="primary" onClick={close}>閉じる</Button>
					</DialogActions>
					</>
					):(
					<>
					<DialogContentText style={{marginBottom:'1em'}}>
						ログインに使用するメールアドレスとパスワードを入力してください
					</DialogContentText>
					<DialogContentText style={{padding:'1em',marginBottom:'1em',fontSize:'90%',backgroundColor:'#F5F5F5'}}>
						・パスワードはあとで変更することができます<br/>
						・他のログイン方法（SNSアカウント・メール）で使用しているメールアドレスは使用できません<br/>
					</DialogContentText>
					<FormControl fullWidth={true}>
						<TextField
							id="uniqueid"
							value={states.uniqueId}
							onChange={changeUniqueId}
							margin="dense"
							size="small"
							placeholder="メールアドレス"
							autoComplete="false"
							//variant="outlined"
							style={{marginTop:'0',marginBottom:'0.25em'}}
						/>
					</FormControl>
					<FormControl fullWidth={true}>
						<TextField
							id="email"
							value={states.password}
							onChange={changePassword}
							margin="dense"
							size="small"
							placeholder="パスワード（6～20文字）"
							style={{marginTop:'0'}}
							type={showPassword ? 'text' : 'password'}
							autoComplete="new-password"
							InputProps={{
							  endAdornment:
								<InputAdornment position="end">
								  <IconButton
									aria-label="toggle password visibility"
									onClick={handleClickShowPassword}
									onMouseDown={handleMouseDownPassword}
									edge="end"
								  >
									{showPassword ? <VisibilityOff /> : <Visibility />}
								  </IconButton>
								</InputAdornment>
							}}
						/>
					</FormControl>
					
					<FormControl fullWidth={true} style={{marginBottom:"0.5em"}}>
						<Button variant="contained" onClick={validatePassword}>
							<KeyIcon className={classes.pageTitleIcon}/> 登録
						</Button>
					</FormControl>
					<DialogActions>
						<Button color="primary" onClick={close}>やめる</Button>
					</DialogActions>
					</>
					)}
					<Box style={{position:"absolute",top:"0",right:"0"}}>
						<Tooltip title="閉じる"><IconButton size="small" color="default" onClick={close}><CancelIcon/></IconButton></Tooltip>
					</Box>
				</DialogContent>
				</React.Fragment>
                
            <Toast mes={toast} close={closeToast}/>
            <Loading isOpen={states.isLoading}/>
        </Dialog>
    );
}

export default Entry;
