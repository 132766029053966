import React,{useState,useEffect} from 'react';
import {Link} from 'react-router-dom'//yarn add @types/react-router-dom
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import TwitterIcon from '@mui/icons-material/Twitter';
import GoogleIcon from '@mui/icons-material/Google';
import MailIcon from '@mui/icons-material/Mail';
import CancelIcon from '@mui/icons-material/Cancel';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import KeyIcon from '@mui/icons-material/Key';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
//import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Loading from './Loading';
import Toast from './Toast';
import {useStyles} from './useStyles';
import {UserContainer} from './UserContainer';

interface LoginProps {
	isOpen:boolean,
	close():void,
	openEntry?():void,
}

const Login = (props:LoginProps)=> {
    const [states,setStates] = useState({
        email:"",
		uniqueId:"",
		password:"",
        isSend:false,
        isLoading:false,
    });
	const classes = useStyles();
	const [showPassword, setShowPassword] = useState(false);
    const [toast,setToast] = useState("");
    const userState = UserContainer.useContainer();
    const signInWithGoogle = ()=>{
        setStates({...states,isLoading:true});
        userState.signIn( "google",()=>{
			setStates({...states,isLoading:false});
			props.close();
        },()=>{
			setStates({...states,isLoading:false});
            setToast("ログインできませんでした");
		});
    }
    const signInWithTwitter = ()=>{
        setStates({...states,isLoading:true});
        userState.signIn( "twitter",()=>{
			setStates({...states,isLoading:false});
			props.close();
        },()=>{
			setStates({...states,isLoading:false});
            setToast("ログインできませんでした");
		});
    }
    const signInWithEmail = ()=>{
        setStates({...states,isLoading:true});
        const email = states.email;
        userState.sendEmail(email,()=>{
            setStates({...states,isLoading:false,isSend:true});
        },()=>{
			setStates({...states,isLoading:false});
            setToast("メールの送信に失敗しました");
        });      
    }
    const signInWithPassword = ()=>{
        setStates({...states,isLoading:true});
        const email = states.uniqueId;
		const password = states.password;
        userState.signInWithPassword(email,password,()=>{
            setStates({...states,isLoading:false});
			props.close();
        },()=>{
			setStates({...states,isLoading:false});
            setToast("ログインできませんでした");
        });      
    }

    const validateEmail = ()=>{
        let error = "";
        if( states.email==="" ){
            error = "メールアドレスを入力してください";
        } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(states.email)){
            error = "メールアドレスが正しくありません";
        }
        if( error!=="" ){
            setToast(error);
        } else {
            signInWithEmail();
        }
    }

    const validatePassword = ()=>{
        let error = "";
        if( states.uniqueId==="" ){
            error = "メールアドレスを入力してください";
        } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(states.uniqueId)){
            error = "メールアドレスが正しくありません";
        } else if( states.password==="" ){
            error = "パスワードを入力してください";
        }
        if( error!=="" ){
            setToast(error);
        } else {
            signInWithPassword();
        }
    }

	const changeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStates({...states,"email":event.target.value});
    }
    const changeUniqueId = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStates({...states,"uniqueId":event.target.value});
    }
    const changePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStates({...states,"password":event.target.value});
    }
	const openEntry = ()=>{
		props.openEntry && props.openEntry();
	}
    const logout = ()=>{
        userState.signOut();
		props.close();
    }
    const closeToast = ()=>{
        setToast("");
    }
	const close = ()=>{
		closeToast();
		props.close();
	}
	function handleClickShowPassword() {
		setShowPassword(!(showPassword))
	}
  	function handleMouseDownPassword(event: React.MouseEvent<HTMLButtonElement>) {
		event.preventDefault();
	};		
  
	let provider = "メール";
	if( userState.user ){
		//console.log(userState.user);
		if( userState.user.providerData[0] ){
			if( userState.user.providerData[0].providerId==="twitter.com" ){
				provider = "twitter";
			}
			if( userState.user.providerData[0].providerId==="google.com" ){
				provider = "google";
			}
			if( userState.user.providerData[0].providerId==="password" ){
				if( userState.user.emailVerified ){
					provider = "メール";
				} else {
					provider = "パスワード";
				}
			}
		}
	}
	
	useEffect( ()=>{
		if( props.isOpen ){
			setStates({email:"",uniqueId:"",password:"",isSend:false,isLoading:false});
		}
	},[props.isOpen]);

	if( !userState.ready ){
        return (null);
    }

    return (
        <Dialog
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={props.isOpen}
            onClose={close}
        >
            {!states.isSend ? (
				<React.Fragment>
                <DialogTitle>403adamskiにログイン</DialogTitle>
                <DialogContent>
                {userState.user &&
                    <div>
						<Box className="auth_data">
							<h3 style={{lineHeight:'1.2em'}}><AccountCircleIcon className={classes.pageTitleIcon}/>ログイン中のアカウント</h3>
							<ul className={classes.accountData}>
							{userState.user?.displayName &&
								<li>{userState.user?.displayName}</li>
							}
								<li>{provider} でログイン</li>
							</ul>
							{userState.user?.email &&
							<React.Fragment>
								<h3 style={{lineHeight:'1.2em'}}><MailIcon className={classes.pageTitleIcon}/>メールアドレス</h3>
								<ul className={classes.accountData}>
									<li>
										{userState.user?.email}
									</li>
								</ul>
							</React.Fragment>
							}													
						</Box>
						<FormControl fullWidth={true}>
            	           <Button variant="contained" color="primary" onClick={logout} style={{width:'280px',maxWidth:'100%'}}>ログアウト</Button>
        	        	</FormControl>
					</div>
                }
                {!userState.user &&
                    <React.Fragment>
					<DialogContentText style={{marginBottom:'1em'}}>
						ログイン方法は3通りです。ご都合に合わせてお選びください。
					</DialogContentText>
					<DialogContentText style={{padding:'1em',fontSize:'90%',backgroundColor:'#F5F5F5'}}>
						・ログインによって利用料等の請求が発生することはありません。<br/>
						・ログイン後にプロフィールや作品の登録を行い販売を開始することで利用規約に同意した会員とみなされます。<br/>
						・<b style={{color:'teal'}}>パスワード</b>をご利用の場合だけ、初回のログインの前に「パスワード登録」を行ってください。（2回目以降およびSNSアカウント・メールアドレスの場合は不要です）<br/>
					</DialogContentText>
					<div className={classes.mailLoginInput}>
						<h3 className={classes.mailLoginInputTitle}>SNSアカウント</h3>
						<Grid container spacing={1} style={{marginBottom:"0.5em"}}>
							<Grid item xs={6}>
								<FormControl fullWidth={true}>
									<Button variant="contained" onClick={signInWithGoogle}>
										<GoogleIcon className={classes.pageTitleIcon}/> Google
									</Button>
								</FormControl>
							</Grid>
							<Grid item xs={6}>
								<FormControl fullWidth={true}>
									<Button variant="contained" onClick={signInWithTwitter}>
										<TwitterIcon className={classes.pageTitleIcon}/> Twitter
									</Button>
								</FormControl>
							</Grid>
						</Grid>
					</div>
                    <div className={classes.mailLoginInput}>
						<h3 className={classes.mailLoginInputTitle}>パスワード</h3>
                        <FormControl fullWidth={true}>
                            <TextField
                                id="uniqueid"
                                value={states.uniqueId}
                                onChange={changeUniqueId}
                                margin="dense"
								size="small"
                                placeholder="メールアドレス"
                                //variant="outlined"
								autoComplete="false"
								style={{marginTop:'0',marginBottom:'0.25em'}}
                            />
                        </FormControl>
                        <FormControl fullWidth={true}>
                            <TextField
                                id="email"
                                value={states.password}
                                onChange={changePassword}
                                margin="dense"
								size="small"
                                placeholder="パスワード"
								style={{marginTop:'0'}}
								type={showPassword ? 'text' : 'password'}
								autoComplete="false"
								InputProps={{
								  endAdornment:
									<InputAdornment position="end">
									  <IconButton
										aria-label="toggle password visibility"
										onClick={handleClickShowPassword}
										onMouseDown={handleMouseDownPassword}
										edge="end"
									  >
										{showPassword ? <VisibilityOff /> : <Visibility />}
									  </IconButton>
									</InputAdornment>
								}}
							/>
                        </FormControl>
                        
                        <FormControl fullWidth={true} style={{marginBottom:"0.5em"}}>
                            <Button variant="contained" onClick={validatePassword}>
                                <KeyIcon className={classes.pageTitleIcon}/> パスワードでログイン
                            </Button>
                        </FormControl>
						<Button variant="text" color="primary" startIcon={<ArrowForwardIcon/>} onClick={openEntry}>
							パスワードの登録
						</Button>
                    </div>
                    <div className={classes.mailLoginInput}>
						<h3 className={classes.mailLoginInputTitle}>メール</h3>
						<Box style={{fontSize:'90%',marginBottom:'1em'}}>届いたメールに記載されているURLにアクセスするとログイン完了します</Box>
                        <FormControl fullWidth={true}>
                            <TextField
                                id="email"
                                value={states.email}
                                onChange={changeEmail}
                                margin="dense"
								size="small"
                                placeholder="メールアドレス"
								style={{marginTop:'0'}}
								autoComplete="false"
                                //variant="outlined"
                            />
                        </FormControl>
                        
                        <FormControl fullWidth={true} style={{marginBottom:"0.5em"}}>
                            <Button variant="contained" onClick={validateEmail}>
                                <MailIcon className={classes.pageTitleIcon}/> メールでログイン
                            </Button>
                        </FormControl>
                    </div>


                    </React.Fragment>
                }
					<DialogActions style={{position:"absolute",top:"0",right:"0"}}>
						<Tooltip title="閉じる"><IconButton size="small" color="default" onClick={props.close}><CancelIcon/></IconButton></Tooltip>
					</DialogActions>
				</DialogContent>
				</React.Fragment>
                
            ):(
				<React.Fragment>
					<DialogContent>
						<DialogContentText>
							{states.email}にメールを送信いたしましたのでご確認ください。<br/>
							メールに書かれたURLは1回限り有効です。<br/>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button variant="outlined" onClick={close}>閉じる</Button>
					</DialogActions>
				</React.Fragment>
            )}

            <Toast mes={toast} close={closeToast}/>
            <Loading isOpen={states.isLoading}/>
        </Dialog>
    );
}

export default Login;
