import {useState,useEffect} from 'react';
import {googleAuth,twitterAuth,auth,getCredential} from './firebase';
import {createContainer} from "unstated-next";
import {Inc,AccountMethodGoogle,AccountMethodTwitter,AccountMethodEmail,AccountMethodPassword} from './Inc';
import Ajax from './Ajax';

const newMailSettings = {
    url: process.env.REACT_APP_FIREBASE_NEW_MAIL_RETURN ? process.env.REACT_APP_FIREBASE_NEW_MAIL_RETURN : "http://localhost",
    handleCodeInApp: true,
};
const updateMailSettings = {
    url: process.env.REACT_APP_FIREBASE_UPDATE_MAIL_RETURN ? process.env.REACT_APP_FIREBASE_UPDATE_MAIL_RETURN : "http://localhost",
    handleCodeInApp: true,
};
const useUserState = ()=> {
	const [user, setUser] = useState<firebase.default.User | null>(null);
	const [ready, setReady] = useState(false);
	const [profile,setProfile] = useState(Inc.defaultProfile());
	const [payment,setPayment] = useState(Inc.defaultPayment());

	const check = async (u:any)=>{
		if( u && u.uid ){
			const ajax = new Ajax();
			let methodId = '';
			if( u.providerData[0].providerId==='twitter.com' ){
				methodId = AccountMethodTwitter;
			} else if( u.providerData[0].providerId==='google.com' ){
				methodId = AccountMethodGoogle;
			} else if( u.emailVerified ){
				methodId = AccountMethodEmail;
			}
			const res = await ajax.getProfile(u.uid,methodId);
			if( !res.err ){
				setProfile(res.profile);
				setPayment(res.payment);
			} else {
				setProfile(Inc.defaultProfile());
				setPayment(Inc.defaultPayment());
			}
		} else {
			setProfile(Inc.defaultProfile());
			setPayment(Inc.defaultPayment());
		}
        setReady(true);
	}

	const hasProfile = ()=>{
		if( profile && profile.id>0 ){
			return true;
		}
		return false;
	}

	const hasPayment = ()=>{
		if( payment && payment.id>0 ){
			return true;
		}
		return false;
	}

	const signIn = async (method:"twitter"|"google"|"yahoo"|"email",funcSuccess?:any,funcFailure?:any) => {
        //console.log("user",user);
		try {
			if( method==="google" ){
				await auth.signInWithPopup(googleAuth) && funcSuccess && funcSuccess();
			}
			if( method==="twitter" ){
				await auth.signInWithPopup(twitterAuth) && funcSuccess && funcSuccess();
			}
			if( method==="email" ){
				await signInWithEmailLink(funcSuccess,funcFailure);
			}
		} catch (error) {
			//console.log(error);
			setUser(null);
			funcFailure && funcFailure();
		}
	}

	const signOut = async () => {
        //console.log("user",user);
		setProfile(Inc.defaultProfile());
		setPayment(Inc.defaultPayment());
		await auth.signOut();
	}

	const sendEmail = async (email:string,funcSuccess?:any,funcFailure?:any) => {
		try {
			await auth.sendSignInLinkToEmail(email, newMailSettings)
			.then(function() {
				//console.log("success");
				window.localStorage.setItem('emailForSignIn', email);
				funcSuccess && funcSuccess();
			})
			.catch(function(error) {
				// Some error occurred, you can inspect the code: error.code
				//console.log("error",error);
				funcFailure && funcFailure();
			});
		} catch (error) {
			funcFailure && funcFailure();
		}
	}

	const sendUpdateEmail = async (email:string,funcSuccess?:any,funcFailure?:any) => {
		let settings = updateMailSettings;
		settings.url = settings.url + "/" + user?.uid;
		try {
			await auth.sendSignInLinkToEmail(email, settings)
			.then(function() {
				//console.log("success");
				window.localStorage.setItem('emailForSignIn', email);
				funcSuccess && funcSuccess();
			})
			.catch(function(error) {
				// Some error occurred, you can inspect the code: error.code
				//console.log("error",error);
				funcFailure && funcFailure();
			});
		} catch (error) {
			funcFailure && funcFailure();
		}
	}

	const createUserWithEmailAndPassword = (email:string,password:string,funcSuccess?:any,funcFailure?:any) => {
		try {
			auth.createUserWithEmailAndPassword(email, password)	  
			.then(function(data){
				const ajax = new Ajax();
				if(data.user && data.user.uid){
					return ajax.updateAccount(data.user.uid,password,AccountMethodPassword);
				}
			})
			.then(function() {
				funcSuccess && funcSuccess();
			})
			.catch(function(error) {
				console.log(error);
				funcFailure && funcFailure();
			});
		} catch (error) {
			console.log(error);
			setUser(null);
			funcFailure && funcFailure();
		}
	}


	const signInWithPassword = async (email:string,password:string,funcSuccess?:any,funcFailure?:any) => {
		try {
			await auth.signInWithEmailAndPassword(email, password) && funcSuccess && funcSuccess();
		} catch (error) {
			//console.log(error);
			setUser(null);
			funcFailure && funcFailure();
		}
	}

	const signInWithEmailLink = async (funcSuccess:any,funcFailure?:any)=>{
		if (auth.isSignInWithEmailLink(window.location.href)) {
			let email = window.localStorage.getItem('emailForSignIn');
			if (!email) {
				funcFailure && funcFailure();
			} else {
				await auth.signInWithEmailLink(email, window.location.href)
				.then(function() {
					window.localStorage.removeItem('emailForSignIn');
					funcSuccess && funcSuccess();
				})
				.catch(function(error) {
					//console.log(error);
					funcFailure && funcFailure();
				});
			}
		}
	}

	const updatePassword = async (email:string,oldPassword:string,newPassword:string,funcSuccess?:any,funcFailure?:any)=>{
		const user = auth.currentUser;
		try {
			if( user && user.email ){
				const credential = getCredential(user.email,oldPassword);
				await user.reauthenticateWithCredential(credential);
				if( user.email!==email && email!=="" ){
					await user.updateEmail(email);
				}
				if( oldPassword!==newPassword && newPassword!=="" ){
					await user.updatePassword(newPassword);
				}
				const ajax = new Ajax();
				await ajax.updateAccount(user.uid,newPassword,AccountMethodPassword);
				funcSuccess && funcSuccess();	
			} else {
				funcFailure && funcFailure();	
			}
		} catch (e) {
			console.log(e);
			funcFailure && funcFailure();			
		}
	}

	//const updateEmail = async (email:string,funcSuccess?:any,funcFailure?:any)=>{
	//	const user = auth.currentUser;
	//	try {
	//		if( user ){
	//			await user.updateEmail(email);
	//			await user.getIdToken(true);
	//			await user.sendEmailVerification(updateMailSettings);
	//			funcSuccess && funcSuccess();
	//		} else {
	//			funcFailure && funcFailure();	
	//		}
	//	} catch (e) {
	//		//console.log("error",e);
	//		funcFailure && funcFailure();			
	//	}
	//}

	useEffect(()=>{
		auth.onAuthStateChanged(u => setUser(u));
		auth.onIdTokenChanged(u=>check(u));
	},[])

	return { user, ready, profile, setProfile, payment, setPayment, hasProfile, hasPayment, signIn, signOut, sendEmail, sendUpdateEmail, signInWithPassword, createUserWithEmailAndPassword, updatePassword }
}
export const UserContainer = createContainer(useUserState);
