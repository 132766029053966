//import * as React from 'react';
import React from 'react';
import Avatar from '@mui/material/Avatar';
import {useStyles} from './useStyles';
import {Inc,ImageData} from './Inc';

interface ImageAvatarProps {
    image?:ImageData,
	onClick?():void,
	clickable?:boolean,
}

const ImageAvatar:React.FC<ImageAvatarProps> = (props)=> {
	const classes = useStyles();
	const cancelImage = ()=>{
		if(props.onClick){
			props.onClick();
		}
	}

	let clickable = false;
	if( props.clickable!==null && props.clickable===true ){
		clickable = true;
	}

	let imageSrc = "";
	if( props.image ){
		if( props.image.source!=="" ){
			imageSrc = props.image.source;
		} else {
			imageSrc = Inc.getUploadImageURL(props.image.name);
		}
	} else {
		imageSrc = "";
	}

	if( imageSrc==="" ){
		return <Avatar className={classes.profileAvatar}/>
	} else if( clickable ) {
		return <Avatar className={classes.profileAvatar} src={imageSrc} style={{cursor:`pointer`}} onClick={cancelImage}/>
	} else {
		return <Avatar className={classes.profileAvatar} src={imageSrc} style={{cursor:`default`}}/>
	}
}

export default ImageAvatar;