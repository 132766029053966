import React,{useState,useEffect} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
//import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
//import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import CircularProgress from '@mui/material/CircularProgress';
//import WebIcon from '@mui/icons-material/Web';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import InputField from './InputField';
import Ajax from './Ajax';
import {useStyles} from './useStyles';
import {Profile,MinProfilePublicKeyLength,MaxProfilePublicKeyLength} from './Inc';
import Tools from './Tools';

interface ProfileReleaseProps {
	userId:string,
	close(isUpdated:boolean,publicKey:string,available:boolean):void,
	isOpen:boolean,
	profile:Profile,
}

const ProfileRelease:React.FC<ProfileReleaseProps> = (props)=> {
	const [states,setStates] = useState({
		publicKey: "",
		error: "",
		available: false,
		status: 0,
		isUpdated: false,
	});
	const classes = useStyles();
	const close = ()=>{
		props.close(states.isUpdated,states.publicKey,states.available);
	}
	const validate = async ()=>{
		let error = "";
		const publicKey = states.publicKey.trim();
		const treated = Tools.toHan(publicKey);
		if(!Tools.validateCode(treated,MinProfilePublicKeyLength,MaxProfilePublicKeyLength)){
			if( treated.length>MaxProfilePublicKeyLength ){
				error = '長すぎます';
			} else if( treated.length<MinProfilePublicKeyLength ){
				error = '短すぎます';
			} else {
				error = '使用できない文字が含まれています';
			}
		}
		if( error!=="" ){
			setStates({...states,publicKey:publicKey,error:error});
			return;
		}

		setStates({...states,publicKey:publicKey,error:"",status:1});
		const ajax = new Ajax();
		const res = await ajax.releaseProfile(props.userId,states.publicKey,states.available);
		if( res.err ){
			setStates({...states,publicKey:publicKey,error:"",status:9});
			return;
		}
		if( res.result!=="OK" ){
			if( res.reason==="used" ){
				error = "このユーザー名はほかの作家さんが使用しています"
			}
			setStates({...states,publicKey:publicKey,error:error,status:9});
			return;
		}
		setStates({...states,publicKey:publicKey,error:"",status:2});
	}
	const onChangePublicKey = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!event) {
			return;
		}
		let value = event.target.value;
		setStates({
			...states,
			isUpdated:true,
			publicKey:value,
		});
	}
	const onChangeAvailable = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!event) {
			return;
		}
		let value = false;
		if( event.target.value==="1" ){
			value = true;
		}
		setStates({ 
			...states,
			isUpdated: true,
			available: value,
		});
	};


	useEffect(()=>{
		if(props.isOpen){
			if( props.profile.locked ){
				setStates({
					publicKey: props.profile.publicKey,
					error: "",
					status: 0,
					available: false,
					isUpdated: false,
				});
			} else {
				setStates({
					publicKey: props.profile.publicKey,
					error: "",
					status: 0,
					available: props.profile.available,
					isUpdated: false,
				});
			}
		}
	},[props.isOpen]);



	return (
		<Dialog
			open={props.isOpen}
			onClose={(event, reason) => {
				if (reason !== 'backdropClick') {
					close();
				}
			}}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			disableEscapeKeyDown={true}
		>
			<DialogTitle id="alert-dialog-title"><SlideshowIcon className={classes.dialogTitleIcon}/>公開設定</DialogTitle>
			{states.status!==2 ? (
			<DialogContent style={{fontSize:'90%'}}>
				ユーザー名は公開ページのＵＲＬ（アドレス）に使われます。<br/>
				最初は自動的にランダムな文字が割り振られますが、好きなユーザー名に変更してＯＫです。
			</DialogContent>
			):(
			<DialogContent style={{fontSize:'90%'}}>
				公開設定を更新しました。<br/>
				現在あなたのプロフィールは {states.available ? "公開中" : "非公開"} です。<br/>
			</DialogContent>
			)}
			{states.status!==2 &&
			<DialogContent>
				<InputField label="ユーザー名" onChange={onChangePublicKey} value={states.publicKey} error={states.error} opt={{
					maxLength:MaxProfilePublicKeyLength,
					required:true,
					disabled:states.status===2,
				}}/>
				<FormHelperText>※半角英数字とアンダーバー（_）で{MinProfilePublicKeyLength}文字以上{MaxProfilePublicKeyLength}文字以内</FormHelperText>
				<RadioGroup row className={classes.formRadioBox} onChange={onChangeAvailable} style={{marginTop:"1em"}}>
					{!props.profile.locked &&
					<FormControlLabel disabled={states.status===2} value="1" control={<Radio checked={states.available===true}/>} label="公開"/>
					}
					<FormControlLabel disabled={states.status===2} value="0" control={<Radio checked={states.available===false}/>} label="非公開"/>
				</RadioGroup>
			</DialogContent>
			}
			{states.status===0 &&
			<DialogActions className={classes.dialogActions}>
				<Button onClick={close} color="primary" variant="text">
				もどる
				</Button>
				<Button onClick={validate} color="primary" disabled={!states.isUpdated} variant="contained">
				更新する
				</Button>
			</DialogActions>
			}
			{states.status===1 &&
			<Box className={classes.dialogActionsCenter}>
				<CircularProgress/>
			</Box>
			}
			{states.status===2 &&
			<DialogActions className={classes.dialogActions}>
				<Button onClick={close} color="success" variant="outlined">閉じる</Button>
			</DialogActions>
			}
			{states.status===9 &&
			<DialogActions className={classes.dialogActions}>
				<div style={{fontSize:"80%",color:"#666666",marginRight:"1em"}}>失敗しました</div>
				<Button onClick={close} color="primary" variant="text">やめる</Button>
				<Button onClick={validate} color="warning" variant="contained">リトライ</Button>
			</DialogActions>
			}
		</Dialog>
	);
}

export default ProfileRelease;