import React,{useState,useEffect} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import TwitterIcon from '@mui/icons-material/Twitter';
import GoogleIcon from '@mui/icons-material/Google';
import MailIcon from '@mui/icons-material/Mail';
import CancelIcon from '@mui/icons-material/Cancel';
//import AccountCircleIcon from '@mui/icons-material/AccountCircle';
//import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import KeyIcon from '@mui/icons-material/Key';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import Loading from './Loading';
import Toast from './Toast';
import {useStyles} from './useStyles';
import {UserContainer} from './UserContainer';

interface LoginUpdateProps {
	isOpen:boolean,
	lock():void,
	close():void,
}

const LoginUpdate = (props:LoginUpdateProps)=> {
    const [states,setStates] = useState({
        email:"",
		uniqueId:"",
		password:"",
        isSend:false,
        isLoading:false,
		created:false,
    });
	const classes = useStyles();
	const [showPassword, setShowPassword] = useState(false);
    const [toast,setToast] = useState("");
    const userState = UserContainer.useContainer();
    const signInWithGoogle = ()=>{
        setStates({...states,isLoading:true});
        userState.signIn( "google",()=>{
			setStates({...states,isLoading:false});
			props.close();
        },()=>{
			setStates({...states,isLoading:false});
            setToast("ログインできませんでした");
		});
    }
    const signInWithTwitter = ()=>{
        setStates({...states,isLoading:true});
        userState.signIn( "twitter",()=>{
			setStates({...states,isLoading:false});
			props.close();
        },()=>{
			setStates({...states,isLoading:false});
            setToast("ログインできませんでした");
		});
    }
    const signInWithEmail = ()=>{
        setStates({...states,isLoading:true});
        const email = states.email;
        userState.sendUpdateEmail(email,()=>{
            setStates({...states,isLoading:false,isSend:true});
			props.lock();
        },()=>{
			setStates({...states,isLoading:false});
            setToast("メールの送信に失敗しました");
        });      
    }
    const createUser = ()=>{
        setStates({...states,isLoading:true});
        const email = states.uniqueId;
		const password = states.password;
        userState.createUserWithEmailAndPassword(email,password,()=>{
            setStates({...states,isLoading:false});
			props.close();
        },()=>{
			setStates({...states,isLoading:false});
            setToast("そのメールアドレスとパスワードでは登録できませんでした");
        });      
    }

    const validateEmail = ()=>{
        let error = "";
        if( states.email==="" ){
            error = "メールアドレスを入力してください";
        } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(states.email)){
            error = "メールアドレスが正しくありません";
        }
        if( error!=="" ){
            setToast(error);
        } else {
            signInWithEmail();
        }
    }
    const validatePassword = ()=>{
        let error = "";
        if( states.uniqueId==="" ){
            error = "メールアドレスを入力してください";
        } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(states.uniqueId)){
            error = "メールアドレスが正しくありません";
        } else if( states.password==="" ){
            error = "パスワードを入力してください";
        } else if( states.password.length>20 ){
			error = "パスワードが長すぎます";
        } else if( states.password.length<6 ){
			error = "パスワードが短すぎます";
		}
        if( error!=="" ){
            setToast(error);
        } else {
            createUser();
        }
    }

    const changeUniqueId = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStates({...states,"uniqueId":event.target.value});
    }
    const changePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStates({...states,"password":event.target.value});
    }

    const changeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStates({...states,"email":event.target.value});
    }
    const logout = ()=>{
        userState.signOut();
		props.close();
    }	
    const closeToast = ()=>{
        setToast("");
    }
	const close = ()=>{
		closeToast();
		props.close();
	}
	function handleClickShowPassword() {
		setShowPassword(!(showPassword))
	}
  	function handleMouseDownPassword(event: React.MouseEvent<HTMLButtonElement>) {
		event.preventDefault();
	};		

	//let provider = "メール";
	//if( userState.user ){
	//	if( userState.user.providerData[0] ){
	//		if( userState.user.providerData[0].providerId==="twitter.com" ){
	//			provider = "twitter";
	//		}
	//		if( userState.user.providerData[0].providerId==="google.com" ){
	//			provider = "google";
	//		}
	//	}
	//}
	
	useEffect( ()=>{
		if(!userState.ready){
			return;
		}
		if( !userState.user || !userState.user.uid ){
			props.close()
			return;
		}
		if( props.isOpen ){
			setStates({email:"",uniqueId:"",password:"",isSend:false,isLoading:false,created:false});
		}
	},[props.isOpen,userState.ready]);

	if( !userState.ready ){
        return (null);
    }

    return (
        <Dialog
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={props.isOpen}
            onClose={close}
        >
            {!states.isSend ? (
				<React.Fragment>
                <DialogTitle>ログイン方法の変更</DialogTitle>
                <DialogContent>
				{states.created ? (
					<>
					<DialogContentText style={{marginBottom:'1em'}}>
						パスワードを登録しました。<br/>
						次回からはこのメールアドレスとパスワードでログインしてください。<br/>
					</DialogContentText>
					<DialogActions>
						<Button color="primary" onClick={close}>閉じる</Button>
					</DialogActions>
					</>
				):(
					<>					
					<DialogContentText style={{marginBottom:'1em'}}>
						新たに利用するログイン方法を選んでください。
					</DialogContentText>
					<DialogContentText style={{padding:'1em',fontSize:'90%',backgroundColor:'#F5F5F5'}}>
						・すでに403adamskiで利用中のアカウントやメールアドレスに変更することはできません。<br/>
						・複数のアカウントを一つにまとめることはできません。<br/>
						・パスワードから他のログイン方法に変更した場合、変更後数日（2～3営業日程度）は、同じメールアドレスでパスワードでのログインに戻すことはできません。<br/>
					</DialogContentText>
					<div className={classes.mailLoginInput}>
						<h3 className={classes.mailLoginInputTitle}>SNSアカウント</h3>
						<Grid container spacing={1} style={{marginBottom:"0.5em"}}>
							<Grid item xs={6}>
								<FormControl fullWidth={true}>
									<Button variant="contained" onClick={signInWithGoogle} startIcon={<GoogleIcon/>}>
										Google
									</Button>
								</FormControl>
							</Grid>
							<Grid item xs={6}>
								<FormControl fullWidth={true}>
									<Button variant="contained" onClick={signInWithTwitter} startIcon={<TwitterIcon/>}>
										Twitter
									</Button>
								</FormControl>
							</Grid>
						</Grid>
					</div>

                    <div className={classes.mailLoginInput}>
						<h3 className={classes.mailLoginInputTitle}>パスワード</h3>
                        <FormControl fullWidth={true}>
                            <TextField
                                id="uniqueid"
                                value={states.uniqueId}
                                onChange={changeUniqueId}
                                margin="dense"
								size="small"
                                placeholder="メールアドレス"
                                //variant="outlined"
								autoComplete="false"
								style={{marginTop:'0',marginBottom:'0.25em'}}
                            />
                        </FormControl>
                        <FormControl fullWidth={true}>
                            <TextField
                                id="email"
                                value={states.password}
                                onChange={changePassword}
                                margin="dense"
								size="small"
                                placeholder="パスワード（6～20文字）"
								style={{marginTop:'0'}}
								type={showPassword ? 'text' : 'password'}
								autoComplete="false"
								InputProps={{
								  endAdornment:
									<InputAdornment position="end">
									  <IconButton
										aria-label="toggle password visibility"
										onClick={handleClickShowPassword}
										onMouseDown={handleMouseDownPassword}
										edge="end"
									  >
										{showPassword ? <VisibilityOff /> : <Visibility />}
									  </IconButton>
									</InputAdornment>
								}}
							/>
                        </FormControl>
                        
                        <FormControl fullWidth={true} style={{marginBottom:"0.5em"}}>
                            <Button variant="contained" onClick={validatePassword} startIcon={<KeyIcon/>}>
                                パスワードでログイン
                            </Button>
                        </FormControl>
                    </div>

                    <div className={classes.mailLoginInput}>
						<h3 className={classes.mailLoginInputTitle}>メール</h3>
						<Box style={{fontSize:'90%',marginBottom:'1em'}}>届いたメールに記載されているURLにアクセスするとログイン完了します</Box>
                        <FormControl fullWidth={true}>
                            <TextField
                                id="email"
                                value={states.email}
                                onChange={changeEmail}
                                margin="dense"
								size="small"
                                placeholder="メールアドレス"
                                //variant="outlined"
                            />
                        </FormControl>
                        
                        <FormControl fullWidth={true} style={{marginBottom:"1em"}}>
                            <Button variant="contained" onClick={validateEmail} startIcon={<MailIcon/>}>
                                メールでログイン
                            </Button>
                        </FormControl>
                    </div>
					</>
					)}
					<DialogActions style={{position:"absolute",top:"0",right:"0"}}>
						<Tooltip title="閉じる"><IconButton size="small" color="default" onClick={props.close}><CancelIcon/></IconButton></Tooltip>
					</DialogActions>
				</DialogContent>
				</React.Fragment>
                
            ):(
				<React.Fragment>
					<DialogContent>
						<DialogContentText>
							{states.email}にメールを送信いたしましたのでご確認ください。<br/>
							メールに書かれたURLは1回限り有効です。<br/>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button variant="outlined" onClick={close}>閉じる</Button>
					</DialogActions>
				</React.Fragment>
            )}

            <Toast mes={toast} close={closeToast}/>
            <Loading isOpen={states.isLoading}/>
        </Dialog>
    );
}

export default LoginUpdate;
