import React,{useState,useEffect} from 'react';
import {Link} from 'react-router-dom'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
//import Divider from '@mui/material/Divider';
import PaidIcon from '@mui/icons-material/Paid';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import RouteIcon from '@mui/icons-material/Route';
import RecommendIcon from '@mui/icons-material/Recommend';
import ForwardIcon from '@mui/icons-material/Forward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import CheckIcon from '@mui/icons-material/Check';
import TwitterIcon from '@mui/icons-material/Twitter';
import CreateIcon from '@mui/icons-material/Create';
import CircularProgress from '@mui/material/CircularProgress';
import {UserContainer} from './UserContainer';
import {Profile,Payment,MaxProducts} from './Inc';
//import PageTitle from './PageTitle';
import Login from './Login';
import Ajax from './Ajax';
//import Loading from './Loading';
import plateImg from './img/plate.png';
import {useStyles} from './useStyles';
//import Twitter from '@mui/icons-material/Twitter';

interface TopProps {
    mode:string,
}

const Top:React.FC<TopProps> = (props)=> {
	const userState = UserContainer.useContainer();
	const classes = useStyles();
	const defaultStates = {
		openLogin: false,
		profile: {} as Profile,
		payment: {} as Payment,
		items: 0,
		isLoading: false,
	};
	const [states,setStates] = useState(defaultStates);	
	const openLogin = ()=>{
		//console.log("open");
		setStates({...states,openLogin:true});
	}
	const closeLogin = ()=>{
		//console.log("close!");
		setStates({...states,openLogin:false});
	}
	const load = async ()=>{
		if(!userState.user){
			return;
		}
		setStates({...defaultStates,isLoading:true});
		const ajax = new Ajax();
		const res = await ajax.getProfile(userState.user.uid,'');
		if( !res.err ){
			setStates({...states,profile:res.profile,payment:res.payment,items:res.items,openLogin:false,isLoading:false});
		} else {
			setStates({...states,isLoading:false});
		}
	}

	useEffect(()=>{
		load();
	},[userState.user]);

	//if( states.isLoading ){
	//	return <Loading isOpen={true}/>
	//}
	
	return (
		<React.Fragment>
			<div style={{textAlign:'center',marginBottom:'1em',position:'relative'}}>
				<img src={plateImg} style={{maxWidth:'100%'}}/>
				<div style={{position:'absolute',top:0,right:0}}>v1.31</div>
			</div>
			<Box className="greeting">
				こちらは403adamski、画像や楽曲などのデータを簡単に販売できるサービスです。<br/>
				U.F.O.からデータをダウンロードするような気分で作りました。<br/>
				「よんまるさんあだむすき」と読みますが、長く感じたら適当に略していただいて構いません。<br/>				
			</Box>
			<Box className="twitterBox">
				<a href="https://twitter.com/403adamski" target="_blank" rel="noopener noreferrer" className="link"><Button variant="contained"><TwitterIcon/>公式ツイッター</Button></a>
				<div>ほかの作家さんの作品を紹介しています</div>
			</Box>

			{states.isLoading ? (
			<div style={{textAlign:'center',padding:'2em 0'}}><CircularProgress/></div>
			):(
			<div className="infoBox">
				<h4><RouteIcon style={{verticalAlign:'bottom'}}/> 販売開始までの流れ</h4>
				<Grid container spacing={1}>
					<Grid item xs={12} md={3}><div className="routeBox">
						<h5>ログインする</h5>
						{userState.user ? (
							<div style={{textAlign:'center',color:'teal',paddingTop:'1em'}}>
								<CheckIcon/><br/>
								<span>ログイン完了！</span>
							</div>
						):(
						<React.Fragment>
							<div>SNSのアカウントまたはメールアドレスでログインできます。</div>
							<div className="actions">
								<Button variant="text" color="primary" onClick={openLogin}>ログイン</Button>
							</div>
						</React.Fragment>
						)}
					</div></Grid>
					<Grid item xs={12} md={1}>
						<Box className="routeArrowBox" sx={{ display: { xs: 'none', md: 'block'} }}><ForwardIcon/></Box>
						<Box className="routeArrowBoxDown" sx={{ display: { md: 'none', sm: 'block'} }}><ArrowDownwardIcon/></Box>
					</Grid>
					<Grid item xs={12} md={3}><div className="routeBox">
						<h5>プロフィールを登録する</h5>
						{!states.profile.id && !states.payment.id &&
						<React.Fragment>
						<div>作家名や売上を振り込む銀行口座などを登録します。</div>
						<div className="actions">
							<Link to="/profile/detail" className={classes.link}><Button variant="text" color="primary" disabled={!userState.user}>プロフィール</Button></Link>
						</div>
						</React.Fragment>
						}
						{states.profile.id>0 && states.payment.id===0 &&
						<React.Fragment>
						<div>販売を開始するには支払情報と連絡先の登録が必要です。</div>
						<div className="actions">
							<Link to="/profile/detail" className={classes.link}><Button variant="text" color="primary" disabled={!userState.user}>支払情報と連絡先</Button></Link>
						</div>
						</React.Fragment>
						}
						{states.profile.id>0 && states.payment.id>0 &&
						<div style={{textAlign:'center',color:'teal',paddingTop:'1em'}}>
							<CheckIcon/><br/>
							<span>登録完了！</span>
						</div>
						}
					</div></Grid>
					<Grid item xs={12} md={1}>
						<Box className="routeArrowBox" sx={{ display: { xs: 'none', md: 'block'} }}><ForwardIcon/></Box>
						<Box className="routeArrowBoxDown" sx={{ display: { md: 'none', sm: 'block'} }}><ArrowDownwardIcon/></Box>
					</Grid>
					<Grid item xs={12} md={4}><div className="routeBox">
						<h5>商品を登録する</h5>
						{states.items===0 ? (
						<React.Fragment>
						<div>ファイルをアップロードして商品名などを設定すればすぐに販売が始められます</div>
						<div className="actions">
							<Link to="/product/list" className={classes.link}><Button variant="text" color="primary" disabled={!userState.user}>商品登録</Button></Link>
						</div>
						</React.Fragment>
						):(
						<div style={{textAlign:'center',color:'teal',paddingTop:'1em'}}>
							<CheckIcon/><br/>
							<span>登録完了！</span>
						</div>
						)}
					</div></Grid>
				</Grid>
			</div>
			)}

			<div className="infoBox">
				<h4><RecommendIcon style={{verticalAlign:'bottom'}}/> 作家の皆様へ</h4>
				<div style={{fontSize:'90%'}}>
					なんとかリリースはしたもののまだまだ全然手探りです。<br/>
					ファイルがアップロードできないなどのおかしな動作が見られましたら、すぐに調査いたしますのでお知らせ下さい。<br/>
					改良を続けて少しでもマシなものにしていければと思っています。<br/>
					ご意見ご要望もお気軽にどうぞ。お待ちしてます。<br/>
					<br/>
					2022.01.13　レトロインク<a href="https://twitter.com/403adamski" target="_blank" rel="noopener noreferrer"><TwitterIcon style={{verticalAlign:'bottom',marginLeft:'0.25em',color:'teal'}}/></a>
				</div>
			</div>

			<Grid container spacing={2} style={{marginTop:'3em'}}>
				<Grid item xs={12} sm={6} md={4}>
					<div className="infoBox">
						<h4><AudioFileIcon style={{verticalAlign:'bottom'}}/> 販売できるファイル種類</h4>
						<div className="impact">
							全21種類
						</div>
						<ul className="fileTypes">
							<li>png</li>
							<li>jpg/jpeg</li>
							<li>gif</li>
							<li>psd</li>
							<li>txt</li>
							<li>docx</li>
							<li>xlsx</li>
							<li>pdf</li>
							<li>epub</li>
							<li>ai</li>
							<li>mpg/mpeg</li>
							<li>mp4</li>
							<li>mp3</li>
							<li>wav</li>
							<li>aiff</li>
							<li>aac</li>
							<li>m4a</li>
							<li>m4r</li>
							<li>flac</li>
							<li>ttf</li>
							<li>otf</li>
						</ul>
						<div style={{clear:'both'}}/>
						<div className="actions">
							<Link to="/help" className={classes.link}><Button variant="text" color="primary"><ForwardIcon/> もっと詳しく</Button></Link>
						</div>

					</div>
				</Grid>
				<Grid item xs={12} sm={6} md={4}>
					<div className="infoBox">
						<h4><NotificationImportantIcon style={{verticalAlign:'bottom'}}/> 販売できるファイルサイズ</h4>
						<div className="impact">
							200MB × 5 × {MaxProducts}
						</div>
						<ul className="fileSize">
							<li>・販売できる商品数は最大{MaxProducts}個です</li>				
							<li>・1商品に最大で5ファイルまで含めることができます</li>
							<li>・1ファイルのサイズは最大200MBです（合計で最大1GBまで）</li>
							<li>・販売するファイル以外に、サンプルファイルと追加ファイルを1つずつ登録できます</li>
						</ul>
						<div style={{clear:'both'}}/>
						<div className="actions">
							<Link to="/help" className={classes.link}><Button variant="text" color="primary"><ForwardIcon/> もっと詳しく</Button></Link>
						</div>
					</div>
				</Grid>
				<Grid item xs={12} sm={6} md={4}>
					<div className="infoBox">
						<h4><PaidIcon style={{verticalAlign:'bottom'}}/> 利用料金・手数料</h4>
						<div className="impact">
							販売手数料 3.0%<br/>
							決済手数料 3.6%<br/>
						</div>
						<ul className="fileSize">
							<li>・商品が売れた時にだけ手数料が発生しますので登録料金や月々の利用料金は不要です。</li>
							<li>・売上は月末締めで翌月末に指定の金融機関口座にお支払します。振込手数料は180円（税込）です。売上残高が最低振り込み金額未満の場合は翌月以降に繰り越されます。</li>
							<li>・最低振り込み金額は1,000円から20,000円で設定可能です。</li>
						</ul>
						<div style={{clear:'both'}}/>
						<div className="actions">
							<Link to="/help" className={classes.link}><Button variant="text" color="primary"><ForwardIcon/> もっと詳しく</Button></Link>
						</div>

					</div>
				</Grid>		
			</Grid>
			<div className="exampleBox">
				<a href="https://403adamski.jp/c/examples" className="link"><Button variant="contained" color="primary"><CreateIcon style={{verticalAlign:'bottom',marginRight:'0.25em'}}/>登録例を見る</Button></a>
				<p>商品の登録例はこちらです</p>
			</div>			
			<Login isOpen={states.openLogin} close={closeLogin}/>
		</React.Fragment>
	)
}

export default Top;