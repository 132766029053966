import React,{useState,useEffect} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import {useStyles} from './useStyles';

interface InputSuggestProps {
	label:string,
	value:string,
	options:string[],
	maxLength:number,
	onChange(value:string):void,
}

const InputSuggest:React.FC<InputSuggestProps> = (props)=> {
	const classes = useStyles();
	const [value,setValue] = useState(props.value);
	const [key,setKey] = useState(0);

	let error = false;
	if(value){
		if( value.length>props.maxLength ){
			error = true;
		}
	}

	useEffect(()=>{
		if(props.value){
			setValue(props.value);		
		}
		setKey(key+1);
	},[props.value]);

	if( props.options.length===0 ){
		return (null);
	}

	return (
		<Autocomplete
			disablePortal
			freeSolo
			id={"select-tags"+props.label}
			options={props.options}
			sx={{ width: '100%' }}
			onInputChange={(event, newValue) => {
				let value = '';
				if( newValue ){
					value = newValue;
				}
				setValue(value);
			}}
			key={"ac"+key}
			defaultValue={value}
			onBlur = {()=>{
				let newValue = '';
				if( value ){
					newValue = value;
					if( value.length>props.maxLength ){
						newValue = value.substring(0,props.maxLength);
						setValue(newValue);
					}
				}
				props.onChange(newValue);
			}}
			renderInput={(params) => 
				<TextField
					{...params}
					variant="filled"
					label={props.label}
					error={error}
					InputProps={{
						...params.InputProps,
						type: 'search',
						className: classes.formInput,
					}}										
				/>
			}
		/>
	);
}

export default InputSuggest;