import React,{useState, useEffect} from 'react';
import {Navigate,useParams} from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import WarningIcon from '@mui/icons-material/Warning';
import {UserContainer} from './UserContainer';
import Ajax from './Ajax';

const MailUpdate = ()=> {
	const [states,setStates] = useState({
		isFailure: false,
		result: '',
	})
    //const [isSuccess,setIsSuccess] = useState(false);
    //const [isFailure,setIsFailure] = useState(false);
    const userState = UserContainer.useContainer();
	const {oldUserId} = useParams<{oldUserId:string}>();

	const updateAccount = async (newUserId:string)=>{
		if(!oldUserId){
			return;
		}
		//console.log("updateAccount:"+newUserId+":"+oldUserId);
		const ajax = new Ajax();
		const res = await ajax.changeUserId(newUserId,oldUserId);
		if( res.result=="OK" ){
			userState.setProfile(res.profile);
			userState.setPayment(res.payment);
			setStates({...states,result:'success'});
		} else {
			userState.signOut();
			setStates({...states,result:res.reason});
		}
	}

    useEffect( ()=>{
		if( !userState.ready ){
			return;
		}
		if( !userState.user || !userState.user.uid || !oldUserId ){
			return;
		}
		if( oldUserId===userState.user.uid ){
			userState.signIn("email",()=>{
				//console.log("success");
			},()=>{
				//console.log("failure");
				setStates({...states,isFailure:true});
			});	
		} else {
			updateAccount(userState.user.uid);
		}
    },[userState.ready,userState.user?.uid]);


	if( states.result!=='' ){
		const to = "/account/changed/"+states.result;
		return <Navigate to={to}/>
	}

	//if( states.isSuccess ){



	//	const to = "/account/change/"+oldUserId;
    //    return (
	//		<Navigate to={to}/>
	//	)
	//}

	if( states.isFailure ){
        return (
            <div style={{textAlign:'center',marginTop:'4em'}}>
				<p><WarningIcon style={{fontSize:'200%'}} color="warning"/></p>
				<p>
					変更できませんでした
				</p>
			</div>
        )
    }

	return (
		<div style={{textAlign:'center',marginTop:'4em'}}>
			<p><CircularProgress/></p>
			<p>
				処理中<br/>
				このままお待ちください
			</p>
		</div>
	)
}

export default MailUpdate;
