import { styled } from '@mui/material/styles';

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	minHeight: 48,
	//...theme.mixins.toolbar,
}));

export default DrawerHeader;