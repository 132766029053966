import React,{useEffect,useState} from 'react';
import {Navigate,Link} from 'react-router-dom'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
//import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import SettingsIcon from '@mui/icons-material/Settings';
//import WebIcon from '@mui/icons-material/Web';
import HomeIcon from '@mui/icons-material/Home';
import PhoneIcon from '@mui/icons-material/Phone';
//import MailIcon from '@mui/icons-material/Mail';
import LaunchIcon from '@mui/icons-material/Launch';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import HelpIcon from '@mui/icons-material/Help';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import WebSiteIcon from '@mui/icons-material/Web';
import FaceIcon from '@mui/icons-material/Face';
import SendIcon from '@mui/icons-material/Send';
import PaymentIcon from '@mui/icons-material/Payment';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import NightsStayIcon from '@mui/icons-material/NightsStay';
import HistoryIcon from '@mui/icons-material/History';
import MoneyIcon from '@mui/icons-material/Money';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import CopyToClipboard from 'react-copy-to-clipboard';
import ProfileInput from './ProfileInput';
import PaymentInput from './PaymentInput';
import PaymentCheck from './PaymentCheck';
import ProfileRelease from './ProfileRelease';
import Multilines from './Multilines';
import Toast from './Toast';
import Ajax from './Ajax';
import PageTitle from './PageTitle';
import {Inc,PaymentDeposits,PaymentMinAmounts} from './Inc';
import {UserContainer} from './UserContainer';
import {useStyles} from './useStyles';
import CircularProgress from '@mui/material/CircularProgress';

interface ProfileDetailProps {
	
}

const ProfileDetail:React.FC<ProfileDetailProps> = (props)=> {
	const userState = UserContainer.useContainer();
	const classes = useStyles();
	const defaultStates = {
		//profile: Inc.defaultProfile(),
		//payment: Inc.defaultPayment(),
		isProfileInput: false,
		isPaymentInput: false,
		isRelease: false,
		isCheck:false,
		isLoading: false,
		getaway: false,
	};
	const [states,setStates] = useState(defaultStates);
	const [anchorMenu, setAnchorMenu] = React.useState<null | HTMLElement>(null);
	const isMenu = Boolean(anchorMenu);
	const [toast,setToast] = useState('');

	const load = async (isPaymentUpdated:boolean)=>{
		if(!userState.user){
			return;
		}
		setStates({...states,isLoading:true});
		//const oldEmail = userState.payment.email;
		//const oldId = userState.payment.id;
		let oldEmail = userState.payment.email;

		const ajax = new Ajax();
		const res = await ajax.getProfile(userState.user.uid,'');
		//console.log(res);
		if( !res.err ){
			let isCheck = false;
			if( isPaymentUpdated && oldEmail!==res.payment.email ){
				isCheck = true;
			}
			//setStates({...states,profile:res.profile,payment:res.payment,isProfileInput:false,isPaymentInput:false,isCheck:isCheck});
			userState.setPayment(res.payment);
			userState.setProfile(res.profile);
			setStates({...states,isProfileInput:false,isPaymentInput:false,isLoading:false,isCheck:isCheck});
		} else {
			//console.log("error");
			setStates({...states,isProfileInput:false,isPaymentInput:false,isLoading:false});
		}
	}
	
	const loadPayment = async ()=>{
		if(!userState.user){
			return;
		}
		setStates({...states,isLoading:true});
		const ajax = new Ajax();
		const res = await ajax.getPayment(userState.user.uid);
		if( !res.err ){
			userState.setPayment(res.payment);
			setStates({...states,isProfileInput:false,isPaymentInput:false,isLoading:false,isCheck:false});
		}
	}

	const openProfileInput = ()=>{
		setAnchorMenu(null);
		setStates({...states,isProfileInput:true});
	}
	const closeProfileInput = (updated:boolean)=>{
		setStates({...states,isProfileInput:false});
		if( updated ){
			load(false);
		}
	}
	const openPaymentInput = ()=>{
		setAnchorMenu(null);
		setStates({...states,isPaymentInput:true});
	}
	const closePaymentInput = (updated:boolean)=>{
		setStates({...states,isPaymentInput:false});
		if( updated ){
			load(true);
		}
	}
	const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorMenu(event.currentTarget);
	};
	const closeMenu =  () => {
		setAnchorMenu(null);
	};
	const openRelease = ()=>{
		setAnchorMenu(null);
		setStates({...states,isRelease:true});
	}
	const closeRelease = (isUpdated:boolean,publicKey:string,available:boolean)=>{
		if( isUpdated ){
			setStates({...states,isRelease:false});
			userState.setProfile({...userState.profile,publicKey:publicKey,available:available});
		} else {
			setStates({...states,isRelease:false});
		}
	}
	const openCheck = ()=>{
		setStates({...states,isCheck:true});
	}
	const closeCheck = ()=>{
		setStates({...states,isCheck:false});
	}

	const closeToast = ()=>{
		setToast('');
	}
	const copy = ()=>{
		setToast("公開ページのURLをクリップボードにコピーしました");
		//closeMenu();
	}
	const nullTo = (str:string | null)=>{
		if( str===null || str==="" ){
			return "なし";
		} else {
			return str;
		}
	}

	useEffect(()=>{
		if( userState.ready ){
			if( userState.user ){
				//if( userState.hasPayment() && !userState.payment.checked ){
				//	loadPayment();
				//}
				load(false);
			} else {
				setStates({...defaultStates,getaway:true});
			}
		}
	},[userState.ready,userState.user]);

	const shopURL = process.env.REACT_APP_PROFILE_URL + userState.profile.publicKey;
	const tweetURL = "https://twitter.com/intent/tweet?url="+encodeURIComponent(userState.profile.name+"の作品リスト\n"+shopURL);

	if( states.getaway ){
		return <Navigate to='/'/>
	}

	if( states.isLoading || !userState.ready ){
		return <Box style={{textAlign:'center',paddingTop:'4em'}}><CircularProgress/></Box>
	}

	return (
		<React.Fragment>
			<PageTitle title="プロフィール" icon="profile"/>
			{userState.hasProfile() ? (
			<React.Fragment>
				<Box style={{marginBottom:"2em",position:"relative"}}>
					<Box style={{position:"absolute",top:"0",left:"0em"}}>
						<Avatar className={classes.profileAvatar} src={Inc.getUploadImageURL(userState.profile.imageKey)}/>
					</Box>
					<Box style={{paddingLeft:"100px",width:"100%",display:`flex`,paddingTop:'1em',fontSize:"140%",marginBottom:'2em'}}>
						<Box>{userState.profile.name}</Box>
						<Box style={{flex:`1`}}/>
						<Button variant="outlined" onClick={openMenu} sx={{ display: { xs: 'none',md: 'flex' } }}><SettingsIcon className={classes.buttonIcon}/> プロフィール設定</Button>
					</Box>
				</Box>
				<Box sx={{ display: { md: 'none',xs: 'flex' } }} style={{marginBottom:'1em'}}>
					<Box style={{flex:`1`}}/>
					<Button variant="outlined" onClick={openMenu}><SettingsIcon className={classes.buttonIcon}/> プロフィール設定</Button>
				</Box>
				{userState.profile.locked && 
					<Box className={classes.lockedReason}><ReportProblemIcon className={classes.pageTitleIcon}/>{userState.profile.reason}</Box>
				}
				<Box style={{marginBottom:'4em',fontSize:'90%'}}>
					<Multilines text={userState.profile.story}/>				
					<List dense style={{marginTop:'2em'}}>
						{userState.profile.available ? (
						<ListItem style={{paddingLeft:'0'}}>
							<ListItemIcon><WbSunnyIcon className={classes.profileAvailableSunny}/></ListItemIcon>
							<ListItemText primary="公開中" secondary={shopURL} className={classes.profileAvailableSunny}/>
							<ListItemSecondaryAction>
								<a href={shopURL} target="_blank" rel="noopener noreferrer" className={classes.link}><LaunchIcon/></a>
							</ListItemSecondaryAction>
						</ListItem>
						):(
						<ListItem style={{paddingLeft:'0'}}>
							<ListItemIcon><NightsStayIcon className={classes.profileAvailableCloudy}/></ListItemIcon>
							<ListItemText primary="非公開" secondary={shopURL} className={classes.profileAvailableCloudy}/>
						</ListItem>
						)}
						<Divider style={{marginTop:'0.5em',marginBottom:'0.5em'}}/>
						<ListItem style={{paddingLeft:'0'}}>
							<ListItemIcon><TwitterIcon/></ListItemIcon>
							<ListItemText primary={nullTo(userState.profile.twitter)}/>
							{userState.profile.twitter!=='' &&
							<ListItemSecondaryAction>
								<a href={'https://twitter.com/'+nullTo(userState.profile.twitter)} target="_blank" rel="noopener noreferrer" className={classes.link}><LaunchIcon/></a>
							</ListItemSecondaryAction>
							}
						</ListItem>
						<ListItem style={{paddingLeft:'0'}}>
							<ListItemIcon><InstagramIcon/></ListItemIcon>
							<ListItemText primary={nullTo(userState.profile.instagram)}/>
							{userState.profile.instagram!=='' &&
							<ListItemSecondaryAction>
								<a href={'https://www.instagram.com/'+nullTo(userState.profile.instagram)} target="_blank" rel="noopener noreferrer" className={classes.link}><LaunchIcon/></a>
							</ListItemSecondaryAction>
							}
						</ListItem>
						<ListItem style={{paddingLeft:'0'}}>
							<ListItemIcon><FacebookIcon/></ListItemIcon>
							<ListItemText primary={nullTo(userState.profile.facebook)}/>
							{userState.profile.facebook!=='' &&
							<ListItemSecondaryAction>
								<a href={'https://www.facebook.com/'+nullTo(userState.profile.facebook)} target="_blank" rel="noopener noreferrer" className={classes.link}><LaunchIcon/></a>
							</ListItemSecondaryAction>
							}
						</ListItem>
						<ListItem style={{paddingLeft:'0'}}>
							<ListItemIcon><YouTubeIcon/></ListItemIcon>
							<ListItemText primary={userState.profile.youtube!=='' ? 'https://www.youtube.com/' + userState.profile.youtube : 'なし'}/>
							{userState.profile.youtube!=='' &&
							<ListItemSecondaryAction>
								<a href={'https://www.youtube.com/'+nullTo(userState.profile.youtube)} target="_blank" rel="noopener noreferrer" className={classes.link}><LaunchIcon/></a>
							</ListItemSecondaryAction>
							}
						</ListItem>
						<Divider style={{marginTop:'0.5em',marginBottom:'0.5em'}}/>
						<ListItem style={{paddingLeft:'0'}}>
							<ListItemIcon><WebSiteIcon/></ListItemIcon>
							<ListItemText primary={nullTo(userState.profile.webSite)}/>
							<ListItemSecondaryAction>
								<a href={nullTo(userState.profile.webSite)} target="_blank" rel="noopener noreferrer" className={classes.link}><LaunchIcon/></a>
							</ListItemSecondaryAction>
						</ListItem>
					</List>
				</Box>
				<Box style={{border:`1px solid silver`,padding:`1em`,borderRadius:'0.5em',marginBottom:'2em'}}>
					<PageTitle title="入金情報と連絡先" icon="payment"/>
					{userState.hasPayment() ? (
					<React.Fragment>
						<Box>{userState.payment.name}</Box>
						<List dense>
							<ListItem style={{paddingLeft:'0'}}>
								<ListItemIcon><HomeIcon /></ListItemIcon>
								<ListItemText primary={'〒'+userState.payment.zip+'　'+userState.payment.addr}/>
							</ListItem>
							<ListItem style={{paddingLeft:'0'}}>
								<ListItemIcon><PhoneIcon /></ListItemIcon>
								<ListItemText primary={userState.payment.tel}/>
							</ListItem>
							{userState.payment.checked ? (
							<ListItem style={{paddingLeft:'0'}}>
								<ListItemIcon><MarkEmailReadIcon/></ListItemIcon>
								<ListItemText primary={userState.payment.email}/>
							</ListItem>
							):(
							<ListItem style={{paddingLeft:'0'}}>
								<ListItemIcon><MarkEmailUnreadIcon style={{color:'#d32f2f'}}/></ListItemIcon>
								<ListItemText primary={userState.payment.email} secondary={<span style={{color:'#d32f2f'}}>※メールが未確認のため売上の入金は行われません<br/>※メールが届かない場合は「再送」を押してください</span>}/>
								<ListItemSecondaryAction><Button variant="outlined" color="error" size="small" onClick={openCheck}><SendIcon style={{marginRight:'0.25em'}}/>再送</Button></ListItemSecondaryAction>
							</ListItem>
							)}
							<ListItem style={{paddingLeft:'0'}}>
								<ListItemIcon><AccountBalanceIcon/></ListItemIcon>
								<ListItemText primary="振込口座" secondary={userState.payment.bank+'　'+userState.payment.branch+'　'+PaymentDeposits[userState.payment.deposit]+'　'+userState.payment.accountNumber+'　'+userState.payment.accountHolder}/>
							</ListItem>
							<ListItem style={{paddingLeft:'0'}}>
								<ListItemIcon><MoneyIcon/></ListItemIcon>
								<ListItemText primary="最低振り込み金額" secondary={PaymentMinAmounts[userState.payment.minAmount]}/>
							</ListItem>
						</List>
					</React.Fragment>
					):(
					<Box style={{marginBottom:'4em'}}>
						<Box style={{textAlign:'center',marginTop:'4em'}}>
							<Button variant="outlined" onClick={openPaymentInput}><PaymentIcon style={{marginRight:"0.25em"}}/> 入金情報を登録する</Button>
						</Box>
						<Box style={{textAlign:'center',marginTop:'2em'}}>
						商品の売り上げは銀行口座への振込で入金されます。<br/>
						商品の販売を始める前に、「入金情報」を登録しましょう。<br/>
						</Box>
					</Box>
					)}
				</Box>
			</React.Fragment>
			):(
			<Box style={{marginBottom:'4em'}}>
				<Box style={{textAlign:'center',marginTop:'4em'}}>
					<Button variant="outlined" onClick={openProfileInput}><SettingsIcon className={classes.buttonIcon}/> プロフィールを登録する</Button>
				</Box>
				<Box style={{textAlign:'center',marginTop:'2em'}}>
				商品の販売を開始するには「プロフィール」と「入金情報」の登録が必要です。<br/>
				まずは「プロフィール」の登録から始めましょう！<br/>
				</Box>
			</Box>
			)}
			<ProfileInput isOpen={states.isProfileInput} close={closeProfileInput} userId={userState.user!==null ? userState.user.uid : ''}/>
			<PaymentInput isOpen={states.isPaymentInput} close={closePaymentInput} userId={userState.user!==null ? userState.user.uid : ''}/>
			<PaymentCheck isOpen={states.isCheck} close={closeCheck}/>
			<ProfileRelease userId={userState.user!==null ? userState.user.uid : ''} profile={userState.profile} close={closeRelease} isOpen={states.isRelease}/>
			<Toast mes={toast} close={closeToast}/>
			<Menu
				id="product-menu"
				anchorEl={anchorMenu}
				open={isMenu}
				onClose={closeMenu}
			>
				<MenuItem onClick={openProfileInput}><ListItemIcon><FaceIcon/></ListItemIcon>編集する</MenuItem>
				<MenuItem onClick={openPaymentInput}><ListItemIcon><PaymentIcon/></ListItemIcon>入金情報を編集する</MenuItem>
				{false &&
				<MenuItem onClick={closeMenu}><ListItemIcon><HistoryIcon/></ListItemIcon>履歴とお知らせ</MenuItem>
				}
				<Divider/>
				<a href={shopURL} rel="noopener noreferrer" target="_blank" className={classes.link}><MenuItem onClick={closeMenu}><ListItemIcon><LaunchIcon/></ListItemIcon>作家ページを開く</MenuItem></a>
				<CopyToClipboard text={shopURL} onCopy={copy}>
				<MenuItem onClick={closeMenu}><ListItemIcon><FileCopyIcon/></ListItemIcon>ＵＲＬをコピー</MenuItem>
				</CopyToClipboard>
				<a href={tweetURL} rel="noopener noreferrer" target="_blank" className={classes.link}><MenuItem onClick={closeMenu}><ListItemIcon><TwitterIcon/></ListItemIcon>ＵＲＬをツイート</MenuItem></a>
				<MenuItem onClick={openRelease}><ListItemIcon><SlideshowIcon/></ListItemIcon>公開設定</MenuItem>
				<Divider/>
				<Link to="/help/profile/1" className={classes.link}><MenuItem onClick={closeMenu}><ListItemIcon><HelpIcon/></ListItemIcon>ヘルプ</MenuItem></Link>
			</Menu>
		</React.Fragment>
	)
}
export default ProfileDetail;