import React,{useEffect,useState} from 'react';
import Box from '@mui/material/Box';
import {Link} from 'react-router-dom'
//import {DataGrid, GridRowsProp, GridColDef, GridLocaleText} from '@mui/x-data-grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
//import {useTheme} from '@mui/material/styles';
//import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
//import useMediaQuery from '@mui/material/useMediaQuery';
import CircularProgress from '@mui/material/CircularProgress';
//import IconButton from '@mui/material/IconButton';
//import ForwardIcon from '@mui/icons-material/Forward';
//import ClearIcon from '@mui/icons-material/Clear';
//import SearchIcon from '@mui/icons-material/Search';
//import {GRID_DATA_TEXT} from './GridDataText';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
//import MoneyIcon from '@mui/icons-material/Money';
//import EventIcon from '@mui/icons-material/Event';
//import InfoIcon from '@mui/icons-material/Info';
//import PaidIcon from '@mui/icons-material/Paid';
//import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PageTitle from './PageTitle';
import Ajax from './Ajax';
import {Transfer} from './Inc';
import Tools from './Tools';
import {UserContainer} from './UserContainer';
import {useStyles} from './useStyles';

interface TransferListProps {
    
}

const TransferList:React.FC<TransferListProps> = (props)=> {
	const userState = UserContainer.useContainer();
	const [states,setStates] = useState({
		list: [] as Transfer[],
		isInput: false,
		isLoading: true,
		all: 0,
	});
	const [search,setSearch] = useState({
		status: "",
		size: 12,
		page: 0,
		order: "id",
		asc: "desc",
	});
	const classes = useStyles();

	const load = async (page:number,size:number)=>{
		if(!userState.user){
			return;
		}
		const ajax = new Ajax();
		const newSearch = {...search,page:page,size:size};
		const res = await ajax.getTransfers(userState.user.uid,newSearch);
		if( !res.err ){
			setStates({...states,list:res.list,all:res.all,isLoading:false});
			setSearch(newSearch);
		}
	}

	const TransferRow = (props: {transfer: Transfer})=>{
		const transfer = props.transfer;
		const [open, setOpen] = useState(false);
		let transferDate = transfer.transferDate.substring(0,4)+"/"+transfer.transferDate.substring(4,6)+"/"+transfer.transferDate.substring(6,8);
		return (	
		<React.Fragment>
			<TableRow
				sx={{ 'td,th':{borderBottomWidth:'0'},'&:last-child td, &:last-child th': { border: 0 } }}
				
			>
				<TableCell align="center">
					<Link to={'/transfer/detail/'+transfer.uniqueKey} className='link'>
						<Button variant="text" size="small">ID: {transfer.uniqueKey}</Button>
					</Link>
				</TableCell>
				<TableCell align="center">{transferDate}</TableCell>
				<TableCell align="right">{Tools.comma(transfer.charges)}件</TableCell>
				<TableCell align="right">{Tools.comma(transfer.depositAmount-transfer.transferFee)}円</TableCell>
				<TableCell align="center">
					{transfer.status==='0' &&
						<span className="transferBefore">暫定</span>
					}
					{transfer.status==='1' &&
					 	<span className="transferAfter">確定</span>
					}
					{transfer.status==='2' &&
					 	<span className="transferDone">支払済</span>
					}
				</TableCell>
				<TableCell style={{textAlign:'right',paddingRight:'0.5em'}}>
				<IconButton
					aria-label="expand row"
					size="small"
					onClick={() => setOpen(!open)}
				>
					{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
				</IconButton>
			</TableCell>				
			</TableRow>
			<TableRow>
				<TableCell style={{ padding:0 }} colSpan={6}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Table className="collapseTable">
							<TableBody>
								<TableRow>
									<TableCell component="th" scope="row" className="collapseTableTh">販売額</TableCell>
									<TableCell>{Tools.comma(transfer.chargeAmount)+'円'}</TableCell>
								</TableRow>
								<TableRow>
									<TableCell component="th" scope="row" className="collapseTableTh">販売件数</TableCell>
									<TableCell>{Tools.comma(transfer.charges)+'件'}</TableCell>
								</TableRow>
								<TableRow>
									<TableCell component="th" scope="row" className="collapseTableTh">返金</TableCell>
									<TableCell>{Tools.comma(transfer.refundAmount)+'円'}</TableCell>
								</TableRow>
								<TableRow>
									<TableCell component="th" scope="row" className="collapseTableTh">返金件数</TableCell>
									<TableCell>{Tools.comma(transfer.refunds)+'件'}</TableCell>
								</TableRow>
								<TableRow>
									<TableCell component="th" scope="row" className="collapseTableTh">手数料合計</TableCell>
									<TableCell>{Tools.comma(transfer.fee)+'円'}（販売手数料3.0% + 決済手数料3.6%）</TableCell>
								</TableRow>
								<TableRow>
									<TableCell component="th" scope="row" className="collapseTableTh">振込手数料</TableCell>
									<TableCell>{Tools.comma(transfer.transferFee)+'円'}</TableCell>
								</TableRow>
							</TableBody>
						</Table>
						<Box style={{display:'flex',paddingBottom:'1em'}}>
							<Box style={{flex:1}}/>
							<Link to={'/transfer/detail/'+transfer.uniqueKey} className='link'>
								<Button variant="outlined">詳細情報を表示</Button>
							</Link>
						</Box>
					</Collapse>						
				</TableCell>
			</TableRow>
		</React.Fragment>
		);
	}
	//const salesListColumns: GridColDef[] = [
	//	{ field: 'id', headerName: 'ID',hide:true},
	//	{ field: 'uniqueKey', headerName: '入金ID', width:210, align:"left",renderCell: (params) => <Link to={'/transfer/detail/'+params.value} className='link'><Button variant="text" size="small">ID:{params.value}</Button></Link>},
	//	{ field: 'transferDate', headerName: '入金日', align:"center"},
	//	{ field: 'chargeAmount', headerName: '売上',align:"right"},
	//	{ field: 'charges', headerName: '売上件数',align:"right"},
	//	{ field: 'fee', headerName: '手数料',align:"right"},
	//	{ field: 'status', headerName: '状態', align:"center", renderCell:(params) => params.value==='1'? <span className={classes.transferDone}>入金済</span> : <span className={classes.transferBefore}>未入金</span>},
	//];	

	const onPageChange = (event: unknown, newPage: number) => {
		load(newPage,search.size);
	}
	const onPageSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		load(search.page,parseInt(event.target.value, 10));
	}
	useEffect(()=>{
		load(0,search.size);
	},[userState.user]);

	return (
		<React.Fragment>
			<PageTitle title="入金一覧" icon="transfer"/>
			<Box style={{width: '100%'}}>
				{states.isLoading ? (
				<CircularProgress/>
				):(
				<Box style={{width: '100%'}}>
					<TableContainer>
						<Table sx={{ minWidth: 640 }} size="small" aria-label="a dense table">
							<TableHead style={{borderTop:'2px solid #DDDDDD'}}>
								<TableRow>
									<TableCell align="center" style={{whiteSpace:'nowrap'}}>入金ID</TableCell>
									<TableCell align="center" style={{whiteSpace:'nowrap'}}>入金日</TableCell>
									<TableCell align="center" style={{whiteSpace:'nowrap'}}>販売件数</TableCell>
									<TableCell align="center" style={{whiteSpace:'nowrap'}}>入金額</TableCell>
									<TableCell align="center" style={{whiteSpace:'nowrap'}}>状態</TableCell>
									<TableCell align="center" style={{whiteSpace:'nowrap'}}>　</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
							{states.list.map((transfer:Transfer,index:number)=>{
								const key = "charge" + index;
								return <TransferRow key={key} transfer={transfer}/>
							})}
							</TableBody>
						</Table>
					</TableContainer>
					{states.all>0 ? (
					<TablePagination
						rowsPerPageOptions={[12, 24, 36, 48]}
						component="div"
						count={states.all}
						rowsPerPage={search.size}
						page={search.page}
						onPageChange={onPageChange}
						onRowsPerPageChange={onPageSizeChange}
						labelRowsPerPage="表示件数"
					/>
					):(
					<Box className="noRecord">入金データはありません</Box>
					)}
				</Box>
				)}
			</Box>
		</React.Fragment>
	)
}

export default TransferList;