import React,{useState,useEffect} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Ajax from './Ajax';
import {Payment} from './Inc';
import {useStyles} from './useStyles';

interface PaymentConfirmProps {
	close(updated:boolean):void,
	payment:Payment,
	isOpen:boolean,
	userId:string,
}

const PaymentConfirm:React.FC<PaymentConfirmProps> = (props)=> {
	const [status,setStatus] = useState(0);
	const classes = useStyles();
	const close = ()=>{
		props.close(false);
	}
	const finish = ()=>{
		props.close(true);
	}
	const release = async ()=>{
		setStatus(1);
		const ajax = new Ajax();
		const res = await ajax.updatePayment(props.userId,props.payment);
		if( res.err ){
			setStatus(9);
			return;
		}
		if( res.result!=="OK" ){
			setStatus(9);
			return;
		}
		setStatus(2);
	}
	useEffect(()=>{
		if(props.isOpen){
			setStatus(0);
		}
	},[props.isOpen]);

	return (
		<Dialog
			open={props.isOpen}
			onClose={(event, reason) => {
				if (reason !== 'backdropClick') {
					close();
				}
			}}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			disableEscapeKeyDown={true}
		>
			<DialogTitle id="alert-dialog-title">入金情報</DialogTitle>
			<DialogContent>
				{status!==2 ? (
				<DialogContentText id="alert-dialog-description">
					入金情報を更新してよろしいですか？
				</DialogContentText>
				):(
				<DialogContentText id="alert-dialog-description">
					入金情報を更新しました
				</DialogContentText>
				)}
			</DialogContent>
			{status===0 &&
			<DialogActions className={classes.dialogActions}>
				<Button onClick={close} color="primary" variant="text">
				しない
				</Button>
				<Button onClick={release} color="primary" variant="contained">
				更新する
				</Button>
			</DialogActions>
			}
			{status===1 &&
			<Box className={classes.dialogActionsCenter}>
				<CircularProgress/>
			</Box>
			}
			{status===2 &&
			<DialogActions className={classes.dialogActions}>
				<Button onClick={finish} color="success" variant="outlined">閉じる</Button>
			</DialogActions>
			}
			{status===9 &&
			<DialogActions className={classes.dialogActions}>
				<div style={{fontSize:"80%",color:"#666666",marginRight:"1em"}}>失敗しました</div>
				<Button onClick={close} color="primary" variant="text">やめる</Button>
				<Button onClick={release} color="warning" variant="contained">リトライ</Button>
			</DialogActions>
			}
		</Dialog>
	);
}

export default PaymentConfirm;