import React,{useState,useEffect} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
//import DialogActions from '@mui/material/DialogActions';
//import DialogContentText from '@mui/material/DialogContentText';
//import DialogTitle from '@mui/material/DialogTitle';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputAdornment from '@mui/material/InputAdornment';
//import TwitterIcon from '@mui/icons-material/Twitter';
//import InstagramIcon from '@mui/icons-material/Instagram';
//import FacebookIcon from '@mui/icons-material/Facebook';
//import YouTubeIcon from '@mui/icons-material/YouTube';
//import WebIcon from '@mui/icons-material/Web';
//import ImageAvatar from './ImageAvatar';
//import ImageSelect from './ImageSelect';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
//import PaymentUploader from './PaymentUploader';
import Toast from './Toast';
import Ajax from './Ajax';
import YesNo from './YesNo';
import PageTitle from './PageTitle';
import PaymentConfirm from './PaymentConfirm';
import InputField from './InputField';
import Loading from './Loading';
import {Inc,Payment,PaymentMinAmounts,PaymentDeposits,MaxPaymentNameLength,MaxPaymentZipLength,MaxPaymentAddrLength,MaxPaymentTelLength,MaxPaymentMailLength,MaxPaymentBankLength,MaxPaymentBranchLength,MaxPaymentAccountNumberLength,MaxPaymentAccountHolderLength} from './Inc';
import {UserContainer} from './UserContainer';
import {useStyles} from './useStyles';
import Tools from './Tools';

interface PaymentInputProps {
    isOpen:boolean,
	close(updated:boolean):void,
	userId:string,
}

const PaymentInput:React.FC<PaymentInputProps> = (props)=> {
	const userState = UserContainer.useContainer();
	const classes = useStyles();
	const defaultStates = {
		payment:Inc.defaultPayment(),
		error:Inc.defaultPaymentError(),
		isLoading: false,
		isConfirm: false,
		isUpdated: false,
	};
	const [states, setStates] = useState(defaultStates);
	const [toast,setToast] = useState('');
	const [isExit,setIsExit] = useState(false);
	//const ref = useRef();
	
	const onChangeValue = (name:keyof Payment) => (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!event) {
			return;
		}
		let value = event.target.value;
		setStates({ 
			...states,
			isUpdated:true,
			payment:{...states.payment,[name]:value}
		});
	};

	const onChangeDeposit = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!event) {
			return;
		}
		let value = event.target.value;
		setStates({ 
			...states,
			isUpdated:true,
			payment:{...states.payment,deposit:value}
		});
	};

	const onChangeMinAmount = (event: SelectChangeEvent) => {
		let value = event.target.value;
		setStates({ 
			...states,
			isUpdated:true,
			payment:{...states.payment,minAmount:parseInt(value)},
		});
	};

	const exitYes = ()=>{
		setIsExit(false);
		props.close(false);
	}

	const exitNo = ()=>{
		setIsExit(false);
	}
	const close = ()=>{
		props.close(false);
	}
	const validate = async ()=>{
		//console.log("validate");
		if( !userState.user ){
			//console.log("no user");
			return;
		}

		let err:boolean = false;
		let error = Inc.defaultPaymentError();

		const name = states.payment.name.trim();
		if( name==="" ){
			error["name"] = "氏名を入力してください";
			err = true;
		} else if( name.length>MaxPaymentNameLength ){
			error["name"] = "氏名を"+MaxPaymentNameLength+"文字以内にしてください";
			err = true;
		}

		const zip = states.payment.zip.trim();
		if( zip==="" ){
			error["zip"] = "郵便番号を入力してください";
			err = true;
		} else if( !Tools.validateZip(zip) ){
			error["zip"] = "郵便番号が正しくありません";
			err = true;
		}

		const addr = states.payment.addr.trim();
		if( addr==="" ){
			error["addr"] = "住所を入力してください";
			err = true;
		} else if( addr.length>MaxPaymentAddrLength ){
			error["addr"] = "住所を"+MaxPaymentAddrLength+"文字以内にしてください";
			err = true;
		}

		const tel = states.payment.tel.trim();
		if( tel==="" ){
			error["tel"] = "電話番号を入力してください";
			err = true;
		} else if( !Tools.validateTel(tel) ){
			error["tel"] = "電話番号が正しくありません";
			err = true;
		}

		const email = states.payment.email.trim();
		if( email==="" ){
			error["email"] = "メールアドレスを入力してください";
			err = true;
		} else if( !Tools.validateMail(email) ){
			error["email"] = "メールアドレスが正しくありません";
			err = true;
		}


		const bank = states.payment.bank.trim();
		if( bank==="" ){
			error["bank"] = "金融機関名を入力してください";
			err = true;
		} else if( bank.length>MaxPaymentBankLength ){
			error["bank"] = "金融機関名を"+MaxPaymentBankLength+"文字以内にしてください";
			err = true;
		}

		const branch = states.payment.branch.trim();
		if( branch==="" ){
			error["branch"] = "支店名を入力してください";
			err = true;
		} else if( branch.length>MaxPaymentBranchLength ){
			error["branch"] = "支店名を"+MaxPaymentBranchLength+"文字以内にしてください";
			err = true;
		}

		const number = states.payment.accountNumber.trim();
		if( number==="" ){
			error["accountNumber"] = "口座番号を入力してください";
			err = true;
		} else if( number.length>MaxPaymentAccountNumberLength ){
			error["accountNumber"] = "口座番号を"+MaxPaymentAccountNumberLength+"文字以内にしてください";
			err = true;
		}

		const holder = states.payment.accountHolder.trim();
		if( holder==="" ){
			error["accountHolder"] = "口座名義を入力してください";
			err = true;
		} else if( holder.length>MaxPaymentAccountHolderLength ){
			error["accountHolder"] = "口座名義を"+MaxPaymentAccountHolderLength+"文字以内にしてください";
			err = true;
		}

		if(err){
			setToast('赤字の項目を修正してください');
			setStates({...states,payment:{...states.payment,
				name:name,
				zip:zip,
				addr:addr,
				tel:tel,
				email:email,
				bank:bank,
				branch:branch,
				accountNumber:number,
				accountHolder:holder,
			},error:error,isLoading:false});
			return;
		}

		setStates({...states,payment:{...states.payment,
			name:name,
			zip:zip,
			addr:addr,
			tel:tel,
			email:email,
			bank:bank,
			branch:branch,
			accountNumber:number,
			accountHolder:holder,
		},error:error,isConfirm:true,isLoading:false});
	}

	const closeConfirm = (updated:boolean)=>{
		if( updated ){
			setStates({...states,isConfirm:false,isUpdated:false});
			props.close(true);			
		} else {
			setStates({...states,isConfirm:false});
		}
	}

	const cancel = ()=>{
		if( states.isUpdated ){
			setIsExit(true);
		} else {
			props.close(false);
		}
	}

	const closeToast = ()=>{
		setToast('');
	}

	const load = async ()=>{
		//console.log("load(PaymentInput)");
		if( !props.userId ){
			setStates({...defaultStates,isLoading:false});
			return;
		}
		setStates({...defaultStates,isLoading:true});
		const ajax = new Ajax();
		const res = await ajax.getPayment(props.userId);
		if( res.result==="OK" ){
			setStates({...defaultStates,payment:res.payment,isLoading:false});
		} else {
			setStates({...defaultStates,isLoading:false});
		}
	}


	useEffect(()=>{
		if(!props.isOpen){
			return;
		}
		load();
		window.scrollTo(0,0);
	},[props.isOpen,userState.user]);

	let disabled = false;
	if( !states.isUpdated || states.payment.name==="" || states.payment.zip==="" || states.payment.addr==="" || states.payment.tel==="" || states.payment.email===""
		|| states.payment.bank==="" || states.payment.branch==="" || states.payment.accountNumber==="" || states.payment.accountHolder===""
	){
		disabled = true;
	}

	return (
		<Dialog
			fullScreen
			open={props.isOpen}
			onClose={(event, reason) => {
				if (reason !== 'backdropClick') {
					close();
				}
			}}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			disableEscapeKeyDown={true}
		>
			<DialogContent style={{padding:"0",margin:"0"}}>

				<AppBar position="fixed" style={{backgroundColor:`#FFFFFF`}}>
					<Toolbar variant="dense" style={{backgroundColor:`#FFFFFF`}}>
						<Box style={{flex:'1'}}/>
						<Button variant="text" color="primary" onClick={cancel} style={{marginRight:`0.5em`}}>もどる</Button>
						<Button variant="contained" color="primary" disabled={disabled} onClick={validate}>登録</Button>
					</Toolbar>
				</AppBar>

				<Container maxWidth="md" style={{paddingTop:"5em",paddingBottom:"6em"}}>
						<PageTitle title="入金情報" icon="payment"/>
						<Grid container spacing={1}>
						<Grid item xs={12}>
							<InputField label="氏名" onChange={onChangeValue("name")} value={states.payment.name} error={states.error.name}
								opt={{
									maxLength:MaxPaymentNameLength,
									placeholder:"間宮林蔵"
								}}
							/>
							<FormHelperText>※{MaxPaymentNameLength}文字以内</FormHelperText>
						</Grid>
						<Grid item xs={12} sm={3}>
							<InputField label="郵便番号" onChange={onChangeValue("zip")} value={states.payment.zip} error={states.error.zip}
								opt={{
									maxLength:MaxPaymentZipLength,
									placeholder:"181-0001",
									startAdornment:<InputAdornment position="start">〒</InputAdornment>,
								}}
							/>
							<FormHelperText>※{MaxPaymentZipLength}文字以内</FormHelperText>
						</Grid>
						<Grid item xs={12} sm={9}>
							<InputField label="住所" onChange={onChangeValue("addr")} value={states.payment.addr} error={states.error.addr}
								opt={{
									maxLength:MaxPaymentAddrLength,
									placeholder:"東京都三鷹市井の頭4-26-7"
								}}
							/>
							<FormHelperText>※{MaxPaymentAddrLength}文字以内</FormHelperText>
						</Grid>
						<Grid item xs={12} sm={4}>
							<InputField label="電話番号" onChange={onChangeValue("tel")} value={states.payment.tel} error={states.error.tel}
								opt={{
									maxLength:MaxPaymentTelLength,
									placeholder:"03-0000-0000"
								}}
							/>
							<FormHelperText>※{MaxPaymentTelLength}文字以内</FormHelperText>
						</Grid>
						<Grid item xs={12} sm={8}>
							<InputField label="メールアドレス" onChange={onChangeValue("email")} value={states.payment.email} error={states.error.email}
								opt={{
									maxLength:MaxPaymentMailLength,
									placeholder:"store@retro-biz.com"
								}}
							/>
							<FormHelperText>※{MaxPaymentMailLength}文字以内</FormHelperText>
						</Grid>
					</Grid>
					<Box style={{border:`1px solid silver`,padding:`1em`,marginTop:`2em`}}>
						<FormLabel className={classes.formLabel}>振込口座</FormLabel>
						<Grid container spacing={1}>
						<Grid item xs={12} sm={6}>
							<InputField label="金融機関名" onChange={onChangeValue("bank")} value={states.payment.bank} error={states.error.bank}
								opt={{
									maxLength:MaxPaymentBankLength,
									placeholder:"〇〇銀行"
								}}
							/>
							<FormHelperText>※{MaxPaymentBankLength}文字以内</FormHelperText>
						</Grid>
						<Grid item xs={12} sm={6}>
							<InputField label="支店名" onChange={onChangeValue("branch")} value={states.payment.branch} error={states.error.branch}
								opt={{
									maxLength:MaxPaymentBranchLength,
									placeholder:"□□支店"
								}}
							/>
							<FormHelperText>※{MaxPaymentBranchLength}文字以内</FormHelperText>
						</Grid>
						<Grid item xs={5}>
							<RadioGroup row className={classes.formRadioBox} onChange={onChangeDeposit}>
								{Object.keys(PaymentDeposits).map((id:string)=>{
									const key = "radio" + id;
									return (
										<FormControlLabel key={key} value={id} control={<Radio checked={states.payment.deposit===id}/>} label={PaymentDeposits[id]}/>
									)
								})}
							</RadioGroup>
						</Grid>
						<Grid item xs={7}>
							<InputField label="口座番号" onChange={onChangeValue("accountNumber")} value={states.payment.accountNumber} error={states.error.accountNumber}
								opt={{
									maxLength:MaxPaymentAccountNumberLength,
									placeholder:"1234567"
								}}
							/>
							<FormHelperText>※{MaxPaymentAccountNumberLength}文字以内</FormHelperText>
						</Grid>
						<Grid item xs={12}>
							<InputField label="口座名義" onChange={onChangeValue("accountHolder")} value={states.payment.accountHolder} error={states.error.accountHolder}
								opt={{
									maxLength:MaxPaymentAccountHolderLength,
									placeholder:"マミヤリンゾウ"
								}}
							/>
							<FormHelperText>※{MaxPaymentAccountHolderLength}文字以内</FormHelperText>
						</Grid>
						</Grid>
					</Box>
					<Box style={{margin:'2em 0'}}>
						<FormControl>
							<InputLabel>最低振り込み金額</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={states.payment.minAmount.toString()}
								onChange={onChangeMinAmount}
								style={{width:'200px'}}
							>
								<MenuItem value={1000}>{PaymentMinAmounts[1000]}</MenuItem>
								<MenuItem value={2000}>{PaymentMinAmounts[2000]}</MenuItem>
								<MenuItem value={3000}>{PaymentMinAmounts[3000]}</MenuItem>
								<MenuItem value={5000}>{PaymentMinAmounts[5000]}</MenuItem>
								<MenuItem value={10000}>{PaymentMinAmounts[10000]}</MenuItem>
								<MenuItem value={20000}>{PaymentMinAmounts[20000]}</MenuItem>
							</Select>
						</FormControl>
					</Box>
					<Toast mes={toast} close={closeToast}/>
					<Loading isOpen={states.isLoading}/>
					<PaymentConfirm isOpen={states.isConfirm} close={closeConfirm} payment={states.payment} userId={props.userId}/>
					<YesNo onYes={exitYes} onNo={exitNo} title="保存せずに終了" text="編集途中の内容は破棄されますがよろしいですか？" isOpen={isExit}/>
				</Container>
			</DialogContent>
		</Dialog>
	)
}

export default PaymentInput;