import React, {useState,useEffect} from 'react';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import Ajax from './Ajax';
import {UserContainer} from './UserContainer';
import PageTitle from './PageTitle';
import Tools from './Tools';
import {Inc} from './Inc';

interface ContactInputProps {
	
}

const ContactInput: React.FC<ContactInputProps> = () => {
	const userState = UserContainer.useContainer();
	const defaultStates = {
		inquiry: Inc.defaultInquiry(),
		error: Inc.defaultInquiryError(),
		isDialog: false,
		isLoading: false,
		isFinished: false,
	}
	const [states,setStates] = useState(defaultStates)

	const load = async ()=>{
		if(!userState.user){
			setStates({...states,inquiry:Inc.defaultInquiry()});
			return;
		}
		//const ajax = new Ajax();
		//const res = await ajax.getPayment(userState.user.uid);
		//if( !res.err ){
			//setStates({...states,inquiry:{...states.inquiry,name:res.payment.name,email:res.payment.email}});
			setStates({...states,inquiry:{...states.inquiry,name:userState.payment.name,email:userState.payment.email}});
		//}
	}

	const onChange = (key:string)=> (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!event) {
			return;
		}
		let value = event.target.value;
		let error = Inc.defaultInquiryError();
		if( key==='name'){
			if( value==="" ){
				error.name = '名前を入力してください';
			}
		}
		if( key==='email'){
			if( value==="" ){
				error.email = 'メールアドレスを入力してください';
			} else if(!Tools.validateMail(value)){
				error.email = 'メールアドレスが正しくありません';
			}
		}
		if( key==='body'){
			if( value==="" ){
				error.body = '問い合わせ内容を入力してください';
			}
		}
		setStates({...states,inquiry:{...states.inquiry,[key]:value},error:error});
	}

	const onChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.checked;
		let email = states.inquiry.email; 
		let error = states.error;
		if( value ){
			email = '';
			error.email = '';
		}
		setStates({...states,inquiry:{...states.inquiry,noReply:value,email:email},error:error});
	};

	const openDialog = async ()=>{
		setStates({...states,isDialog:true,isFinished:false});
	}
	const closeDialog = ()=>{
		setStates({...states,isDialog:false,isLoading:false,isFinished:false});
	}
	const send = async ()=>{
		const ajax = new Ajax();
		setStates({...states,isDialog:true,isLoading:true});
		const res = await ajax.sendInquiry(states.inquiry);
		if( res.result==="OK" ){
			setStates({...states,isDialog:true,isLoading:false,isFinished:true,inquiry:Inc.defaultInquiry()});
		}
	}
	const cancel = ()=>{
		setStates({...states,isDialog:false,isLoading:false});
	}

	useEffect(()=>{
		setStates({...defaultStates});
		if( userState.ready ){
			if( userState.user ){
				load();
			}
		}
	},[userState.ready,userState.user]);

	let isDisabled = false;
	if( states.inquiry.name==='' || (states.inquiry.email==='' && states.inquiry.noReply===false) || states.inquiry.body==='' || states.error.name!=='' || states.error.email!==''  || states.error.body!=='' ){
		isDisabled = true;
	}

	return (
	<React.Fragment>
		<PageTitle title='連絡フォーム' icon='contact'/>
		<div className="pageIntro">
			403adamskiへのご質問・ご要望はこのフォームをご利用下さい。<br/>
			返信は <b>noreply@403adamski.jp</b> から届きますのでメールの受信設定をどうぞお忘れなく。<br/>
		</div>
		<FormControl fullWidth style={{marginTop:'2em'}}>
			<FormLabel className="formLabel">名前</FormLabel>
			<TextField
				size="small"
				variant="outlined"
				fullWidth={true}
				onChange={onChange('name')}
				value={states.inquiry.name}
				error={states.error.name!==''}
				type="text"
				style={{marginBottom:"3px"}}
				placeholder="間宮林蔵"
			/>
			{states.error.name!=="" &&
			<div className="error">{states.error.name}</div>
			}			
		</FormControl>
		<FormControl fullWidth style={{marginTop:'2em'}}>
			<FormLabel className="formLabel">メールアドレス</FormLabel>
			<TextField
				size="small"
				variant="outlined"
				fullWidth={true}
				onChange={onChange('email')}
				value={states.inquiry.email}
				error={states.error.email!==''}
				type="email"
				style={{marginBottom:"3px"}}
				placeholder="account@domain.xxx"
				disabled={states.inquiry.noReply}
			/>
			{states.error.email!=="" &&
			<div className="error">{states.error.email}</div>
			}
		</FormControl>
		<FormControl fullWidth style={{marginTop:'0em'}}>
			<FormControlLabel control={<Checkbox checked={states.inquiry.noReply} onChange={onChangeCheckbox}/>} label={<div style={{fontSize:'90%'}}>返信不要　<span style={{fontSize:'90%'}}>※403adamskiからの返信が不要の場合はチェックしてください</span></div>} />
		</FormControl>
		<FormControl fullWidth style={{marginTop:'2em'}}>
			<FormLabel className="formLabel">連絡したい内容</FormLabel>
			<TextField
				size="small"
				variant="outlined"
				fullWidth={true}
				onChange={onChange('body')}
				value={states.inquiry.body}
				error={states.error.body!==''}
				type="text"
				style={{marginBottom:"3px"}}
				placeholder=""
				multiline
				rows={10}
			/>
			{states.error.body!=="" &&
			<div className="error">{states.error.body}</div>
			}			
		</FormControl>
		<div className="requestExamples">
			<h5><RecordVoiceOverIcon style={{verticalAlign:'middle',marginRight:'0.5em'}}/>ご要望の例</h5>
			<ul>
				<li>二次利用な素材（イラストやパターンなど）の販売をしたい</li>
				<li>商用利用可能な作品の販売をしたい</li>
				<li>二次創作の作品を扱ってほしい</li>
				<li>登録できる商品数をもっと増やしてほしい</li>
				<li>商品一つに販売ファイルが五個では足りない</li>
			</ul>
		</div>
		<div style={{textAlign:'right',marginTop:'1em'}}>
			<Button variant="outlined" color="primary" onClick={openDialog} disabled={isDisabled}>送信</Button>
		</div>
		<Dialog
			open={states.isDialog}
			onClose={cancel}
			aria-labelledby="yesno-dialog-title"
			aria-describedby="yesno-dialog-description"
		>
			<DialogTitle id="yesno-dialog-title">お問い合わせ内容を送信</DialogTitle>
			{!states.isFinished ? (
				<React.Fragment>
				<DialogContent>
					{states.isLoading ? (
					<DialogContentText id="yesno-dialog-description" style={{textAlign:'center'}}><CircularProgress/></DialogContentText>
					):(
					<DialogContentText id="yesno-dialog-description">お問い合わせ内容を送信してよろしいですか？</DialogContentText>
					)}
				</DialogContent>
				{states.isLoading ? (
				<DialogActions>
					<Button onClick={cancel} color="primary" variant="outlined">とじる</Button>
				</DialogActions>
				):(
				<DialogActions>
					<Button onClick={cancel} color="primary" variant="text">いいえ</Button>
					<Button onClick={send} color="primary" variant="outlined">はい</Button>
				</DialogActions>
				)}
				</React.Fragment>
			):(
				<React.Fragment>
				<DialogContent>
					<DialogContentText id="yesno-dialog-description">
						送信しました。返信不要にチェックした場合を除き、一営業日以内に返信いたしますので今しばらくお待ち下さい。
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={cancel} color="primary" variant="outlined">とじる</Button>
				</DialogActions>
				</React.Fragment>	
			)}
		</Dialog>
	</React.Fragment>
	)
}

export default ContactInput;
